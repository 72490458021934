import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {

    state: () => {
        return { otpToken: localStorage.getItem('user') }
    },
    actions: {
        update(user) {
            this.user = user
            localStorage.setItem('user', this.user)
        },
    },
})