<template>
  <nav class="navbar navbar-expand-lg navbar-dark pt-0 pt-lg-4 position-absolute top-0 w-100">
    <div class="container-fluid">
      <div class="row w-100">
        <div class="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2 px-0">

          <button class="navbar-toggler float-end border-0 mt-2" type="button" data-bs-toggle="collapse" data-bs-target="#mainMenu" aria-controls="mainMenu" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <router-link :to="{ name: 'adminHome' }" class="navbar-brand mb-3 d-lg-none"><img src="@/assets/logo-white.png" class="logo img-fluid mt-3"></router-link>

          <div class="collapse navbar-collapse px-4 pt-3 pt-lg-0 pe-0" id="mainMenu">

            <router-link :to="{ name: 'adminHome' }" class="navbar-brand mb-3"><img src="@/assets/logo-white.png" class="logo img-fluid mt-3"></router-link>

            <button class="navbar-toggler float-end mt-2 d-lg-none border-0 mob-closer" type="button" data-bs-toggle="collapse" data-bs-target="#mainMenu" aria-controls="mainMenu" aria-expanded="false" aria-label="Toggle navigation">
              <font-awesome-icon :icon="['fas', 'xmark']" />
            </button>

            <ul class="navbar-nav justify-content-center mt-3 mt-lg-0 ms-2 ms-lg-3">

              <li class="nav-item me-2 rounded ps-3 ps-lg-0" :class="{ active: currentRoute == 'adminHome' }">
                <router-link to="/admin" class="nav-link text-white">
                  <span class="nav-link-icon"><font-awesome-icon :icon="['fas', 'chart-line']" /></span>
                  <span class="nav-link-text text-white fw-bold">Dashboard</span>
                </router-link>
              </li>

              <li class="nav-item me-2 rounded ps-3 ps-lg-0" :class="{ active: currentRoute == 'adminTransactions' || currentRoute == 'adminTransaction' }">
                <router-link to="/admin/transactions" class="nav-link" aria-current="page">
                  <span class="nav-link-icon"><font-awesome-icon :icon="['far', 'fa-compass']" /></span>
                  <span class="nav-link-text text-white fw-bold">Transactions</span>
                </router-link>
              </li>

              <li class="nav-item me-2 rounded ps-3 ps-lg-0" :class="{ active: currentRoute == 'adminUsers' }">
                <router-link to="/admin/users" class="nav-link" aria-current="page">
                  <span class="nav-link-icon"><font-awesome-icon :icon="['far', 'address-book']" /></span>
                  <span class="nav-link-text text-white fw-bold">Users</span>
                </router-link>
              </li>

            </ul>

            <ul class="navbar-nav d-flex flex-fill justify-content-end mt-3 mt-lg-0 ms-2 ms-lg-0">
              <li v-if="isAuthenticated" class="nav-item float-end border-0 ps-3 ps-lg-0">
                <a href="#" @click="logout" class="nav-link text-white" >
                  <span class="nav-link-icon"><font-awesome-icon :icon="['fas', 'arrow-right-from-bracket']" /></span>
                  <span class="nav-link-text text-white fw-bold">Sign out</span>
                </a>
              </li>
              <li v-if="!isAuthenticated" class="nav-item float-end border-0 ps-3 ps-lg-0">
                <a class="btn btn-sm btn-light" @click="login">Sign in</a>
              </li>
            </ul>

          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import { useAuth0 } from '@auth0/auth0-vue';
import {ref} from "vue";
import {router} from "@/router";

export default {

  name: 'NavigationSection',
  methods: {

    login() {
      this.$auth0.loginWithRedirect();
    }

  },
  setup() {
    const { logout, isAuthenticated, user } = useAuth0();
    const currentRoute = ref(0)

    currentRoute.value = router.currentRoute.value.name
    return {
      logout: () => {

        // Clear local storage
        localStorage.clear()
        localStorage.removeItem('auth0token')
        localStorage.removeItem('role')

        // Log out
        logout({ logoutParams: { returnTo: window.location.origin } })
      },
      isAuthenticated,
      user,
      currentRoute
    };
  }
};
</script>