<template>
  <table class="table table-responsive table-borderless bg-white">
    <tr v-for="index in 6" :key="index">
      <td colspan="20%" class="bg-white">
        <p class="placeholder-glow bg-white">
          <span class="placeholder bg-primary" :class="getRandomColSize()"></span>
        </p>
      </td>
      <td colspan="10%" class="bg-white">
        <p class="placeholder-glow bg-white">
          <span class="placeholder bg-primary" :class="getRandomColSize()"></span>
        </p>
      </td>
      <td colspan="30%" class="bg-white">
        <p class="placeholder-glow bg-white">
          <span class="placeholder bg-primary" :class="getRandomColSize()"></span>
        </p>
      </td>
      <td colspan="15%" class="bg-white">
        <p class="placeholder-glow bg-white">
          <span class="placeholder bg-primary" :class="getRandomColSize()"></span>
        </p>
      </td>
      <td colspan="35%" class="bg-white">
        <p class="placeholder-glow bg-white">
          <span class="placeholder bg-primary" :class="getRandomColSize()"></span>
        </p>
      </td>
    </tr>
  </table>
</template>
<script>

export default {

  setup( ) {

    // Generate a bootstrap col-x between a given min and max size
    const getRandomColSize = (  ) => {
      const colMin = 3
      const colMax = 12
      return 'col-' + (Math.floor(Math.random()*(colMax-colMin+1)+colMin))
    }

    // expose to template and other options API hooks
    return {
      getRandomColSize
    }

  },
  name: 'ContentPlaceholder',
};
</script>