import { defineStore } from 'pinia'

export const useCheckoutStore = defineStore('checkout', {

    state: () => {
        return { checkoutDetails: localStorage.getItem('checkoutDetails') }
    },
    getters: {
        // Simply return the parsed JSON object
        returnParsedJson(state) {
            return JSON.parse(state.checkoutDetails)
        },
    },
    actions: {
        update(checkoutDetails) {
            this.checkoutDetails = checkoutDetails
            localStorage.setItem('checkoutDetails', this.checkoutDetails)
        },
    },
})