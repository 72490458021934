import axios from 'axios'
import { ref, shallowRef } from 'vue'
import {transactionStatusList, apiEndpoint, eftyIntegratorId} from "@/utils/config";
import { useOtpStore } from '@/stores/otp'
import { isLoading } from "@/utils/support";

const transactionList = ref(0)
const transactionItem = shallowRef(0)
const activeFilter = ref('all')
const transactionError = ref(0)
const transactionMessageList = ref(0)
const transactionMessageError = ref(0)

const transactionItemContext = ref(0)

// Get list with transactions based on the integratorId, for the proper data environment
const getTransactionList = async ( integratorId, limit ) => {

    const otpStore = useOtpStore()

    // Activate loading spinner
    isLoading.value = true

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${otpStore.otpToken}`
    }

    // Reset
    transactionList.value = false
    transactionError.value = false

    const listRequestObject = { integratorId: integratorId, limit: limit }

    await axios
        .post(apiEndpoint.value + 'transactions/list', { returnSellerData: true, returnBuyerData: true, listRequest: listRequestObject  },{
                headers: axiosHeaders
            }
        ).then(response => {

        isLoading.value = false
        transactionList.value = response.data

    }).catch(error => {

        isLoading.value = false

        transactionError.value = "There is an error"
        //console.log(error.response)

        if (error.response.status == 500 || error.response.status == 403) { // @Todo change to 403 only after API fix

            // Redirect to 403 page (work around vue router bc this function is called from within the router)
            //window.location.href = '/#/public/403/';

        }

    })

}

// Update metadata for a transaction
const updateTransactionMetaData = async ( transactionId, metaData ) => {

    // Activate loading spinner
    isLoading.value = true

    const otpStore = useOtpStore()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${otpStore.otpToken}`
    }

    if (transactionId && metaData) {

        await axios
            .put(apiEndpoint.value + 'transactions/metaData', { transactionId: {'integratorId': eftyIntegratorId, 'id': transactionId}, metaData: metaData  },{
                    headers: axiosHeaders
                }
            ).then( response => {

                transactionItem.value.metaData = response.data.metaData
                isLoading.value = false

            }).catch(error => {

                isLoading.value = false
                if (error) {
                    // Todo
                }

            })

    } else {

        isLoading.value = false
    }

}

// Change the status of a transaction
const changeTransactionStatus = async ( transactionId, statusContext ) => {

    // Activate loading spinner
    isLoading.value = true

    const otpStore = useOtpStore()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${otpStore.otpToken}`
    }

    if (transactionId && statusContext) {

        await axios
            .put(apiEndpoint.value + 'transactions/status', { transactionId: {'integratorId': eftyIntegratorId, 'id': transactionId}, statusContext: statusContext  },{
                    headers: axiosHeaders
                }
            ).then( response => {

            transactionItem.value = response.data
            isLoading.value = false

        }).catch(error => {

            isLoading.value = false

                if (error) {
                    // Todo
                }

        })

    } else {

        isLoading.value = false
    }

}

// Get content for a specific transaction
const getTransactionItem = async ( transactionId, integratorId, otpUserId ) => {

    // Activate loading spinner
    isLoading.value = true

    const otpStore = useOtpStore()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${otpStore.otpToken}`
    }

    transactionItem.value = false

    transactionError.value = false

    await axios.get(apiEndpoint.value + 'transactions/id/' + transactionId + '/?integratorId=' + integratorId, {
                headers: axiosHeaders
            }
        ).then(response => {
            isLoading.value = false
            transactionItem.value = response.data

            if (otpUserId.value) {
                if (transactionItem.value.seller.id == otpUserId.value) {
                    transactionItemContext.value = 'seller'
                } else if (transactionItem.value.buyer.id == otpUserId.value) {
                    transactionItemContext.value = 'buyer'
                }
            }

        }).catch(error => {
            isLoading.value = false
            transactionError.value = "There is an error"

            if (error.response.status == 500 || error.response.status == 403) { // @Todo change to 403 only after API fix

                //console.log(error)
                // Redirect to 403 page (work around vue router bc this function is called from within the router)
                //window.location.href = '/#/public/403/';

            }

        });

}

const getTransactionItemContext = async ( transactionItem, otpUserId ) => {

    transactionItemContext.value = false

    if ( transactionItem && otpUserId ) {

       if ( transactionItem.seller.id == otpUserId)
           transactionItemContext.value = 'seller'

       else if ( transactionItem.buyer.id == otpUserId)
           transactionItemContext.value = 'buyer'

    }

}

// Get content for a specific transaction
const getTransactionItemWithEncryptedData = ( encryptedData, iv, keyId ) => {

    // Activate loading spinner
    isLoading.value = true

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost'
    }

    transactionItem.value = false
    transactionError.value = false

    axios.get(apiEndpoint.value + 'transactions/public/enc?encryptedData=' + encryptedData + '&iv=' + iv + '&keyId=' + keyId, {
            headers: axiosHeaders
        }
    ).then(response => {

        isLoading.value = false
        transactionItem.value = response.data

    }).catch(error => {

        isLoading.value = false
        transactionError.value = "There is an error"

        if (error.response.status == 500 || error.response.status == 403) { // @Todo change to 403 only after API fix

            //console.log(error)
            // Redirect to 403 page (work around vue router bc this function is called from within the router)
            //window.location.href = '/#/public/403/';

        }

    });

}

// Get list with transaction messages based on the integratorId and the TransactionId
const getTransactionMessageList = ( transactionId, integratorId ) => {

    // Activate loading spinner
    isLoading.value = true

    const otpStore = useOtpStore()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${otpStore.otpToken}`
    }

    // Reset
    transactionMessageList.value = false
    transactionMessageError.value = false

    const listRequestObject = { integratorId: integratorId, orderByField: 'Created', order: 'ORDER_DIRECTION_ASC' }

    axios
        .post(apiEndpoint.value + 'messages/list', { transactionId: transactionId, listRequest: listRequestObject  },{
                headers: axiosHeaders
            }
        ).then(response => {
        isLoading.value = false
        transactionMessageList.value = response.data

    }).catch(error => {
        isLoading.value = false

        transactionMessageError.value = "There is an error"

        if (error.response.status == 500 || error.response.status == 403) { // @Todo change to 403 only after API fix

            // Redirect to 403 page (work around vue router bc this function is called from within the router)
            //window.location.href = '/#/public/403/';

        }

    })

}

// Add a message to a transaction
const addTransactionMessageItem = async ( transactionId, authorId, receiverId, message, integratorId ) => {

    // Activate loading spinner
    isLoading.value = true

    const otpStore = useOtpStore()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${otpStore.otpToken}`
    }

    await axios
        .post(apiEndpoint.value + 'messages', { transactionId: transactionId, authorId: authorId, receiverId: receiverId, message: message  },{
                headers: axiosHeaders
            }
        ).then(response => {
        isLoading.value = false

            // Reload transaction and messages to get updated list of messages
            getTransactionMessageList(transactionId, integratorId)

            if (response) {
                // Do something
            }

    }).catch(error => {
        isLoading.value = false

        transactionMessageError.value = "There is an error"

        if (error.response) {
            // Do something
        }

    })

}

export {
    getTransactionItem,
    getTransactionList,
    addTransactionMessageItem,
    getTransactionMessageList,
    getTransactionItemContext,
    getTransactionItemWithEncryptedData,
    transactionList,
    transactionItem,
    activeFilter,
    transactionError,
    transactionMessageList,
    transactionMessageError,
    transactionStatusList,
    transactionItemContext,
    changeTransactionStatus,
    updateTransactionMetaData
}