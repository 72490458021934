<template>
  <NavigationSection />
  <LoaderSection v-show="isLoading" />
  <main ref="root">
    <div class="main-content-wrapper w-100">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2 bg-white rounded" id="mainContent">

            <div class="main-content p-3 pt-4">

              <div class="w-100 mb-4 border-bottom pb-3">
                <h1 class="fw-bold h3 text-dark"><font-awesome-icon :icon="['far', 'lightbulb']" />Get Started with Efty Pay</h1>
              </div>

              <div class="transaction-steps-wrapper d-flex mb-5 mt-5" v-if="availableOnboardingSteps">

                <span v-for="onboardingStepInLoop in availableOnboardingSteps" :key="onboardingStepInLoop" class="step" :class="{ active: onboardingStatusEnum == onboardingStepInLoop, finish: onboardingStatusEnum > onboardingStepInLoop  }">{{ onboardingStatusList[onboardingStepInLoop].title }}</span>

              </div>

              <!-- Notification -->
              <div v-if="otpUserProfile && otpUserProfile.paymentDetails && otpUserProfile.paymentDetails.mangopayDetails.kycLevel == 'REGULAR'" class="mb-4 border-bottom pb-2">
                <div class="alert alert-success" role="alert">
                  <div class="text-dark my-auto">
                    <font-awesome-icon :icon="['fas', 'circle-exclamation']" class="h3 m-0 me-3"/>
                    <span class="text-dark h5">Looks like you've already onboarded succesfully! You can proceed to Efty Pay's <router-link :to="{ name: 'publicHome' }" class="text-dark">dashboard</router-link>.</span>

                  </div>
                </div>
                <div class="mt-4 text-center">
                  <img src="@/assets/success.png" class="img-fluid">
                </div>
              </div>
              <!-- ./Notification -->

              <!-- Notification -->
              <div v-if="otpUserProfile.paymentDetails && otpUserProfile.paymentDetails.mangopayDetails.onboardingStatus == 'DECLINED'" class="mb-4 border-bottom pb-2">
                <div class="alert alert-danger" role="alert">
                  <div class="text-dark my-auto">
                    <font-awesome-icon :icon="['fas', 'circle-exclamation']" class="h3 m-0 me-3"/>
                    <span class="text-dark h5">Your application for Efty Pay has been declined. Please review and correct your application.</span>
                  </div>
                </div>
              </div>
              <!-- ./Notification -->

              <!-- Mangopay error details -->
              <div class="alert alert-danger" role="alert" v-if="mangopayOnboardingError">
                {{ mangopayOnboardingError }}
              </div>

              <!-- ./Mangopay error details -->

              <!-- Form with basic details -->
              <div v-if="onboardingStep=='DETAILS' && formPrefilledValues">

                <h3 class="fw-bold mt-4 h4">Please tell us some basics about <span v-if="!isLegalEntity">yourself</span> <span v-if="isLegalEntity">your company</span></h3>
                <p class="">Please provide us with all necessary details in order to proceed with your onboarding process</p>

                <form action="" method="get" class="h-100" id="onboardingForm">
                  <div class="row">

                    <div class="col-12 col-lg-6">
                      <div class="input-group mt-3">

                        <div class="form-floating">
                          <select class="form-select" name="onboardingType" id="onboardingType" @change="onIsLegalEntityChange($event)" required :disabled="disableUserInput('onboardingType')">

                            <option selected></option>
                            <option value="LEGAL_USER" :selected="formPrefilledValues.onboardingType === 'LEGAL_USER'">Yes</option>
                            <option value="NATURAL_USER" :selected="formPrefilledValues.onboardingType === 'NATURAL_USER'">No</option>

                          </select>
                          <label for="onboardingType" class="form-label">I represent a company</label>
                        </div>
                        <span data-bs-toggle="tooltip" data-bs-placement="top"  data-bs-original-title="Efty Pay serves both consumers and businesses. Therefore, there are two types of users: <ul><li>Natural users, for individuals (i.e., natural persons) over the age of 18.</li><li>Legal users, for entities like companies, organizations, and sole proprietors (i.e., legal persons).</li></ul>" data-bs-html="true" class="input-group-text"><font-awesome-icon :icon="['fas', 'circle-question']" class="text-secondary" /></span>
                      </div>
                    </div>

                    <div class="col-12 col-lg-6" v-if="isLegalEntity">
                     <div class="alert alert-warning mt-3"><font-awesome-icon :icon="['fas', 'circle-exclamation']" class="me-1"/> Please note that onboarding as a business can take up to 1-4 business days (instead of 2 hours for onboarding as an individual). We may require additional documents (Articles of Association and Shareholder Agreement).</div>
                    </div>

                    <div class="col-12 col-lg-6">

                      <div class="input-group mt-3" v-if="isLegalEntity">

                        <div class="form-floating" @change="onCompanyTypeChange($event)">
                          <select class="form-select" id="legalUserType" name="legalUserType" required :disabled="disableUserInput()">
                            <option selected></option>
                            <option value="BUSINESS" :selected="formPrefilledValues.legalUserType === 'BUSINESS'">Business</option>
                            <option value="ORGANIZATION" :selected="formPrefilledValues.legalUserType === 'ORGANIZATION'">Organisation</option>
                            <option value="SOLETRADER" :selected="formPrefilledValues.legalUserType === 'SOLETRADER'">Sole Proprietor</option>
                          </select>
                          <label for="legalUserType" class="form-label">Company type</label>
                        </div>
                        <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true" data-bs-original-title="Efty Pay serves three types of companies:<ul><li>Business, like a company or corporation</li><li>Organization, for non-profits and similar</li><li>Sole Proprietors</li></ul>" class="input-group-text"><font-awesome-icon :icon="['fas', 'circle-question']" class="text-secondary" /></span>
                      </div>
                    </div>
                  </div>

                  <hr class="mt-4">

                  <div class="row">

                   <div class="col-12 col-lg-6">


                      <div class="container-fluid" v-if="!isLegalEntity">
                        <div class="row">

                          <div class="col-6 px-0">
                            <div class="form-floating mt-3">
                              <input type="text" class="form-control" id="firstName" name="firstName" placeholder="" :value="formPrefilledValues.firstName" required :disabled="disableUserInput()">
                              <label for="firstName" class="form-label">First Name</label>
                            </div>
                          </div>

                          <div class="col-6 px-0 ps-3">
                            <div class="form-floating mt-3">
                              <input type="text" class="form-control" id="lastName" name="lastName" placeholder="" :value="formPrefilledValues.lastName" required :disabled="disableUserInput()">
                              <label for="lastName" class="form-label">Last Name</label>
                            </div>
                          </div>

                        </div>
                      </div>

                      <div class="input-group mt-3" v-if="!isLegalEntity">
                        <div class="form-floating">
                          <input type="email" disabled="disabled" class="form-control" id="email" name="email" placeholder="" :value="formPrefilledValues.email">
                          <label for="email" class="form-label">E-mail address</label>
                        </div>
                        <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true" data-bs-original-title="You can not change this e-mail address because this is the e-mail address we use to link your Efty Investor account to Efty Pay" class="input-group-text"><font-awesome-icon :icon="['fas', 'circle-question']" class="text-secondary" /></span>
                      </div>

                      <div class="form-floating mt-3" v-if="isLegalEntity">
                        <input type="text" class="form-control" id="companyNumber" name="companyNumber" placeholder="" required :disabled="disableUserInput()" :value="formPrefilledValues.companyNumber">
                        <label for="companyNumber" class="form-label">Company registration number</label>
                      </div>

                     <div class="form-floating mt-3" v-if="isLegalEntity">
                       <input type="text" class="form-control" id="companyVatNumber" name="companyVatNumber" placeholder="" :disabled="disableUserInput()" :value="formPrefilledValues.companyVatNumber">
                       <label for="companyVatNumber" class="form-label">Company VAT number</label>
                     </div>

                      <div class="form-floating mt-3" v-if="isLegalEntity">
                        <input type="text" class="form-control" id="registeredName" name="registeredName" placeholder="" required :disabled="disableUserInput()" :value="formPrefilledValues.registeredName">
                        <label for="registeredName" class="form-label">Company name</label>
                      </div>

                      <div class="input-group mt-3" v-if="isLegalEntity">
                        <div class="form-floating">
                          <input type="email" disabled="disabled" class="form-control" id="email" name="email" placeholder="" :value="formPrefilledValues.email">
                          <label for="email" class="form-label">Company e-mail address</label>
                        </div>
                        <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true" data-bs-original-title="You can not change this e-mail address because this is the e-mail address we use to link your Efty Investor account to Efty Pay" class="input-group-text"><font-awesome-icon :icon="['fas', 'circle-question']" class="text-secondary" /></span>
                      </div>
                    </div>

                    <div class="col-12 col-lg-6">

                      <!-- Natural person info -->
                      <div v-if="!isLegalEntity">

                        <div class="form-floating mt-3">
                          <input type="date" class="form-control" name="dateOfBirth" id="dateOfBirth" placeholder="" :value="formPrefilledValues.dateOfBirth ? dayjs(formPrefilledValues.dateOfBirth).utc().format('YYYY-MM-DD') : ''" required :disabled="disableUserInput()">
                          <label for="dateOfBirth" class="form-label">Date of Birth</label>
                        </div>

                        <div class="form-floating mt-3">
                          <select class="form-select" id="countryOfResidence" name="countryOfResidence" required :disabled="disableUserInput()">
                            <option selected></option>
                            <option v-for="isoCountry in isoCountriesList" :key="isoCountry.code" :value="isoCountry.code" :selected="isoCountry.code === formPrefilledValues.countryOfResidence" :disabled="isoCountry.blocked==true">{{ isoCountry.name }}</option>
                          </select>
                          <label for="countryOfResidence" class="form-label">Country of residence</label>
                        </div>

                        <div class="form-floating mt-3">
                          <select class="form-select" id="nationality" name="nationality" required :disabled="disableUserInput()">
                            <option selected></option>
                            <option v-for="isoCountry in isoCountriesList" :key="isoCountry.code" :value="isoCountry.code" :selected="isoCountry.code === formPrefilledValues.nationality" :disabled="isoCountry.blocked">{{ isoCountry.name }}</option>
                          </select>
                          <label for="nationality" class="form-label">Nationality</label>
                        </div>

                      </div>
                      <!-- ./Natural person info -->

                      <!-- Company address -->
                      <div v-if="isLegalEntity">

                        <div class="form-floating mt-3">
                          <input type="text" class="form-control" id="addressLine1" name="addressLine1" placeholder="" :value="formPrefilledValues.addressLine1" required :disabled="disableUserInput()">
                          <label for="addressLine1" class="form-label">Company Address</label>
                        </div>

                        <div class="container-fluid">
                          <div class="row">

                            <div class="col-6 px-0">
                              <div class="form-floating mt-3">
                                <input type="text" class="form-control" id="postalCode" name="postalCode" placeholder="" :value="formPrefilledValues.postalCode" required :disabled="disableUserInput()">
                                <label for="postalCode" class="form-label">Postal code</label>
                              </div>
                            </div>

                            <div class="col-6 px-0 ps-3">
                              <div class="form-floating mt-3">
                                <input type="text" class="form-control" id="city" name="city" placeholder="" :value="formPrefilledValues.city" required :disabled="disableUserInput()">
                                <label for="city" class="form-label">City</label>
                              </div>
                            </div>

                          </div>
                        </div>

                        <div class="container-fluid">
                          <div class="row">

                            <div class="col-6 px-0">
                              <div class="form-floating mt-3">
                                <input type="text" class="form-control" id="regionOrCounty" name="regionOrCounty" placeholder="" :value="formPrefilledValues.regionOrCounty" :disabled="disableUserInput()">
                                <label for="regionOrCounty" class="form-label">Region</label>
                              </div>
                            </div>

                            <div class="col-6 px-0 ps-3">
                              <div class="form-floating mt-3">
                                <select class="form-select" id="country" name="country" required :disabled="disableUserInput()">
                                  <option selected></option>
                                  <option v-for="isoCountry in isoCountriesList" :key="isoCountry.code" :value="isoCountry.code" :selected="isoCountry.code === formPrefilledValues.country" :disabled="isoCountry.blocked==true">{{ isoCountry.name }}</option>
                                </select>
                                <label for="country" class="form-label">Country</label>
                              </div>
                            </div>

                          </div>
                        </div>



                      </div>
                      <!-- ./Company address -->

                    </div>

                  </div>

                  <!-- Legal represetative -->
                  <div v-if="isLegalEntity">
                    <hr class="mt-4">
                    <h3 class="fw-bold mt-4 h4">Legal representative of your company</h3>
                    <p class="">Please provide us with details of the legal representative of your company. This is the person that will be actively using the Efty platform and acts as a legal representative of your company.</p>
                    <div class="row">

                      <div class="col-12 col-lg-6">

                        <div class="container-fluid">

                          <div class="row">

                            <div class="col-6 px-0">
                              <div class="form-floating mt-3">
                                <input type="text" class="form-control" id="legalRepresentativeFirstName" name="legalRepresentativeFirstName" placeholder="" :value="formPrefilledValues.legalRepresentativeFirstName" required :disabled="disableUserInput()">
                                <label for="legalRepresentativeFirstName" class="form-label">First Name</label>
                              </div>
                            </div>

                            <div class="col-6 px-0 ps-3">
                              <div class="form-floating mt-3">
                                <input type="text" class="form-control" id="legalRepresentativeLastName" name="legalRepresentativeLastName" placeholder="" :value="formPrefilledValues.legalRepresentativeLastName" required :disabled="disableUserInput()">
                                <label for="legalRepresentativeLastName" class="form-label">Last Name</label>
                              </div>
                            </div>

                          </div>

                        </div>

                        <div class="form-floating mt-3">
                          <input type="legalRepresentativeEmail" class="form-control" id="legalRepresentativeEmail" name="legalRepresentativeEmail" placeholder="" :value="formPrefilledValues.legalRepresentativeEmail" required :disabled="disableUserInput()">
                          <label for="legalRepresentativeEmail" class="form-label">E-mail address</label>
                        </div>

                        <div class="form-floating mt-3">
                          <input type="date" class="form-control" name="legalRepresentativeDateOfBirth" id="legalRepresentativeDateOfBirth" placeholder="" :value="formPrefilledValues.legalRepresentativeDateOfBirth ? dayjs(formPrefilledValues.legalRepresentativeDateOfBirth).utc().format('YYYY-MM-DD') : ''" required :disabled="disableUserInput()">
                          <label for="legalRepresentativeDateOfBirth" class="form-label">Date of Birth</label>
                        </div>

                      </div>
                      <div class="col-12 col-lg-6">

                        <div class="form-floating mt-3">
                          <select class="form-select" id="legalRepresentativeCountryOfResidence" name="legalRepresentativeCountryOfResidence" required :disabled="disableUserInput()">
                            <option selected></option>
                            <option v-for="isoCountry in isoCountriesList" :key="isoCountry.code" :value="isoCountry.code" :selected="isoCountry.code === formPrefilledValues.legalRepresentativeCountryOfResidence" :disabled="isoCountry.blocked==true">{{ isoCountry.name }}</option>
                          </select>
                          <label for="legalRepresentativeCountryOfResidence" class="form-label">Country of residence</label>
                        </div>

                        <div class="form-floating mt-3">
                          <select class="form-select" id="legalRepresentativeNationality" name="legalRepresentativeNationality" required :disabled="disableUserInput()">
                            <option selected></option>
                            <option v-for="isoCountry in isoCountriesList" :key="isoCountry.code" :value="isoCountry.code" :selected="isoCountry.code === formPrefilledValues.legalRepresentativeNationality" :disabled="isoCountry.blocked==true">{{ isoCountry.name }}</option>
                          </select>
                          <label for="legalRepresentativeNationality" class="form-label">Nationality</label>
                        </div>

                      </div>

                    </div>
                  </div>
                  <!-- ./Legal represetative -->

                  <hr class="mt-4">
                  <div class="form-check mt-4">
                    <input class="form-check-input" type="checkbox" value="" id="agreeTerms" name="agreeTerms" required :disabled="disableUserInput()" :checked="mangopayOnboardingItem">
                    <label class="form-check-label" for="agreeTerms" :disabled="disableUserInput()">
                      I agree to Efty Pay B.V.'s <a href="https://efty.com/legal/sellers-mandate/" target="_blank">SELLER'S MANDATE</a>, <a href="https://efty.com/legal/general-terms-and-privacy-policy/" target="_blank">GENERAL TERMS & CONDITIONS AND PRIVACY POLICY</a> and <a href="https://efty.com/legal/sale-and-purchase-agreement/" target="_blank">SALE AND PURCHASE AGREEMENT</a> and have read them carefully. By continuing I confirm I have read and agree to these mandates, terms and conditions.
                    </label>
                  </div>
                  <div class="form-check mt-4">
                    <input class="form-check-input" type="checkbox" value="" id="agreeTermsMangoPay" name="agreeTermsMangoPay" required :disabled="disableUserInput()" :checked="mangopayOnboardingItem">
                    <label class="form-check-label" for="agreeTermsMangoPay" :disabled="disableUserInput()">
                      I agree to Mangopay's <a href="https://mangopay.com/terms-and-conditions/payment-services" target="_blank">Terms and Conditions for Payment Services Users of European Platforms</a>
                    </label>
                  </div>

                  <button class="btn btn-lg btn-success w-100 mt-3" role="button" type="button" @click="submitForm()">Continue <span class="d-none d-lg-inline-block text-white">to next step</span></button>

                </form>

              </div>
              <!-- ./Form with basic details -->

              <!-- Form beneficial owners -->
              <div v-if="onboardingStep=='OWNERS'">

                <h3 class="fw-bold mt-4 h4">Please add the requested info regarding all Beneficial owners</h3>

                <p class="">A beneficial owner is an individual who owns or holds a power of control over a legal entity, either directly or via a holding company, usually by owning more than 25% of the entity's share capital or voting rights.
                  (also known as ultimate beneficial owners, or UBOs).</p>
                <p class="">As part of the verification process, certain legal entities are required to disclose their beneficial owners.</p>

                <div class="alert bg-danger text-white" v-if="mangopayOnboardingItem.legalUser && mangopayOnboardingItem.legalUser.uboDeclaration ? mangopayOnboardingItem.legalUser.uboDeclaration.status == 'REFUSED' : ''">
                  The information you provided regarding the beneficial owners of your company has not been accepted with the following reason: {{ mangopayOnboardingItem.legalUser.uboDeclaration.refusedReason }}
                </div>

                <div class="alert bg-danger text-white" v-if="mangopayOnboardingItem.legalUser && mangopayOnboardingItem.legalUser.uboDeclaration ? mangopayOnboardingItem.legalUser.uboDeclaration.status == 'INCOMPLETE' : ''">
                  The information you provided regarding the beneficial owners of your company has been marked as incomplete with the following reason: {{ mangopayOnboardingItem.legalUser.uboDeclaration.refusedReason }}.
                  <p class="text-white">Please update the information below and complete the Onboarding Wizard. Your information will then be re-submitted our financial partner's Compliance Team for approval.</p>
                </div>

                <div class="alert bg-success text-white" v-if="mangopayOnboardingItem.legalUser && mangopayOnboardingItem.legalUser.uboDeclaration ? mangopayOnboardingItem.legalUser.uboDeclaration.status == 'VALIDATED' : ''">
                  Congratulations! The information regarding your beneficial owners has been succesfully validated.
                </div>

                <div class="alert bg-warning bg-opacity-25" v-if="mangopayOnboardingItem.legalUser && mangopayOnboardingItem.legalUser.uboDeclaration ? mangopayOnboardingItem.legalUser.uboDeclaration.status == 'VALIDATION_ASKED' : ''">
                  Your beneficial owners have been sent into our Payment processor's compliance department for reviewal. You will receive an e-mail once the validation is completed.
                </div>

                <form action="" method="get" class="h-100" id="onboardingForm">

                  <div id="legal-wrapper">

                    <!-- Beneficial owner -->
                    <div v-if="formPrefilledValues.beneficialOwners">
                      <div v-for="(beneficialOwner, index) in beneficialOwners" :key="beneficialOwner.id">
                        <div class="card bg-light shadow-sm mb-3">

                          <input type="hidden" :id="'beneficialOwnerId_'+index" :name="'beneficialOwnerId_'+index" v-model="formPrefilledValues.beneficialOwners[index].id">
                          <div class="card-body">
                            <div class="clearfix">
                              <button type="button" class="float-start me-3 h4 bg-transparent border-0" @click="removeBeneficialOwner(formPrefilledValues.beneficialOwners[index].id)" v-if="index!==0"><font-awesome-icon :icon="['fas', 'xmark']" /></button>
                              <h4 class="card-title fw-bold text-decoration-underline float-start">Beneficial owner</h4>
                            </div>
                            <div class="row">

                              <div class="col-12 col-lg-6">

                                <div class="container-fluid">

                                  <div class="row">
                                    <div class="col-6 px-0">
                                      <div class="form-floating mt-3">
                                        <input type="text" class="form-control" :id="'beneficialOwnerFirstName_'+index" :name="'beneficialOwnerFirstName_'+index" placeholder="" v-model="formPrefilledValues.beneficialOwners[index].firstName" required :disabled="disableUserInput('ubo')">
                                        <label :for="'beneficialOwnerFirstName_'+index" class="form-label">First Name</label>
                                      </div>
                                    </div>

                                    <div class="col-6 px-0 ps-3">
                                      <div class="form-floating mt-3">
                                        <input type="text" class="form-control" :id="'beneficialOwnerLastName_'+index" :name="'beneficialOwnerLastName_'+index" placeholder="" v-model="formPrefilledValues.beneficialOwners[index].lastName" required :disabled="disableUserInput('ubo')">
                                        <label :for="'beneficialOwnerLastName_'+index" class="form-label">Last Name</label>
                                      </div>
                                    </div>

                                  </div>

                                </div>

                                <div class="form-floating mt-3">
                                  <select class="form-select" :id="'beneficialOwnerCountryOfBirth_'+index" :name="'beneficialOwnerCountryOfBirth_'+index" required :disabled="disableUserInput('ubo')" v-model="formPrefilledValues.beneficialOwners[index].birthPlace.country">
                                    <option value="">Please select</option>
                                    <option v-for="isoCountry in isoCountriesList" :key="isoCountry.code" :value="isoCountry.code" :disabled="isoCountry.blocked == true">{{ isoCountry.name }}</option>
                                  </select>
                                  <label for="beneficialOwnerCountryOfBirth[index]" class="form-label">Country of birth</label>
                                </div>

                                <div class="form-floating mt-3">
                                  <input type="text" class="form-control" :id="'beneficialOwnerPlaceOfBirth_'+index" :name="'beneficialOwnerPlaceOfBirth_'+index" placeholder="" v-model="formPrefilledValues.beneficialOwners[index].birthPlace.city" required :disabled="disableUserInput('ubo')">
                                  <label for="beneficialOwnerPlaceOfBirth[index]" class="form-label">Place of birth</label>
                                </div>

                                <div class="form-floating mt-3">
                                  <input type="date" class="form-control" :id="'beneficialOwnerDateOfBirth_'+index" :name="'beneficialOwnerDateOfBirth_'+index" placeholder="" v-model="formPrefilledValues.beneficialOwners[index].dateOfBirth" required :disabled="disableUserInput('ubo')">
                                  <label for="beneficialOwnerDateOfBirth[index]" class="form-label">Date of Birth</label>
                                </div>

                              </div>
                              <div class="col-12 col-lg-6">

                                <div class="form-floating mt-3">
                                  <select class="form-select" :id="'beneficialOwnerNationality_'+index" :name="'beneficialOwnerNationality_'+index" required :disabled="disableUserInput('ubo')" v-model="formPrefilledValues.beneficialOwners[index].nationality">
                                    <option disabled value="">Please select</option>
                                    <option v-for="isoCountry in isoCountriesList" :key="isoCountry.code" :value="isoCountry.code">{{ isoCountry.name }}</option>
                                    <option value="">Please select</option>
                                  </select>
                                  <label :for="'beneficialOwnerNationality_'+index" class="form-label">Nationality</label>
                                </div>

                                <div class="form-floating mt-3">
                                  <input type="text" class="form-control" :id="'beneficialOwnerAddressLine1_'+index" :name="'beneficialOwnerAddressLine1_'+index" placeholder="" v-model="formPrefilledValues.beneficialOwners[index].address.addressLine1" required :disabled="disableUserInput('ubo')">
                                  <label :for="'beneficialOwnerAddressLine1_'+index" class="form-label">Address</label>
                                </div>

                                <div class="container-fluid">
                                  <div class="row">

                                    <div class="col-6 px-0">
                                      <div class="form-floating mt-3">
                                        <input type="text" class="form-control" :id="'beneficialOwnerPostalCode_'+index" :name="'beneficialOwnerPostalCode_'+index" placeholder="" v-model="formPrefilledValues.beneficialOwners[index].address.postalCode" required :disabled="disableUserInput('ubo')">
                                        <label :for="'beneficialOwnerPostalCode_'+index" class="form-label">Postal code</label>
                                      </div>
                                    </div>

                                    <div class="col-6 px-0 ps-3">
                                      <div class="form-floating mt-3">
                                        <input type="text" class="form-control" :id="'beneficialOwnerCity_'+index" :name="'beneficialOwnerCity_'+index" placeholder="" v-model="formPrefilledValues.beneficialOwners[index].address.city" required :disabled="disableUserInput('ubo')">
                                        <label :for="'beneficialOwnerCity_'+index" class="form-label">City</label>
                                      </div>
                                    </div>

                                  </div>
                                </div>

                                <div class="form-floating mt-3">
                                  <select class="form-select" :id="'beneficialOwnerCountry_'+index" :name="'beneficialOwnerCountry_'+index" placeholder="" required :disabled="disableUserInput('ubo')" v-model="formPrefilledValues.beneficialOwners[index].address.country">
                                    <option disabled value="">Please select</option>
                                    <option v-for="isoCountry in isoCountriesList" :key="isoCountry.code" :value="isoCountry.code">{{ isoCountry.name }}</option>
                                    <option value="">Please select</option>
                                  </select>
                                  <label :for="'beneficialOwnerCountry_'+index" class="form-label">Country</label>
                                </div>

                                <div class="form-floating mt-3">
                                  <input type="text" class="form-control" :id="'beneficialOwnerRegionOrCountry_'+index" :name="'beneficialOwnerRegionOrCountry_'+index" placeholder="" v-model="formPrefilledValues.beneficialOwners[index].address.regionOrCounty" :disabled="disableUserInput('ubo')">
                                  <label :for="'beneficialOwnerRegionOrCountry_'+index" class="form-label">Region</label>
                                </div>

                              </div>

                            </div>
                          </div>
                        </div>
                    </div>
                    </div>
                    <!-- ./Beneficial owner -->
                  </div>

                  <button class="btn btn-sm btn-outline-success mt-2" @click="addBeneficialOwner()" type="button" :disabled="disableUserInput('ubo')">Add beneficial owner</button>

                  <div class="w-100 mt-3">
                    <button class="btn btn-lg btn-light w-50 rounded-0 rounded-start" role="button" type="button" @click="gotoPreviousOnboardingStep()" v-if="previousOnboardingStepExists">Go back</button>
                    <button :class="['btn btn-lg btn-success', previousOnboardingStepExists ? 'w-50 rounded-0 rounded-end' : 'w-100']" role="button" type="button" @click="submitForm()" >Continue <span class="d-none d-lg-inline-block text-white">to next step</span></button>
                  </div>
                </form>

              </div>
              <!-- ./Form beneficial owners -->

              <!-- Upload documents -->
              <div v-if="onboardingStep=='DOCUMENTS'">
                <div class="container px-0">
                  <div class="row mt-4">
                    <div class="col-12 col-lg-7">

                      <div v-if="!isLegalEntity && mangopayOnboardingItem.naturalUser" class="bg-warning bg-opacity-25 p-3" id="documentExplainer">
                        <p>Please check out our <a href="https://support.efty.com/article/160-verifying-identity-for-individuals" target="_blank">knowledge base</a> for best practices on providing us with the necessary documents.</p>
                        <p>You will need to upload a <strong>proof of identity</strong>. Below you can find which type of documents are accepted.</p>
                        <p>Sellers <b>within the European Union</b> can use one of the following documents:</p>
                        <ul>
                          <li v-for="(requiredDoc, index) in mangopayRequiredDocuments.naturalUserConfig.allowedIdentityProofDocumentsForEUAA" :key="index">{{ getDocumentType(requiredDoc) }}</li>
                        </ul>
                        <p>Sellers <b>outside the European Union</b> can use one of the following documents:</p>
                        <ul>
                          <li v-for="(requiredDoc, index) in mangopayRequiredDocuments.naturalUserConfig.allowedIdentityProofDocumentsForNonEUAA" :key="index">{{ getDocumentType(requiredDoc) }}</li>
                        </ul>
                      </div>

                      <div v-else-if="isLegalEntity && mangopayOnboardingItem.legalUser" class="bg-warning bg-opacity-25 p-3">

                        <div v-if="mangopayOnboardingItem.legalUser.legalUserType == 'BUSINESS' || mangopayOnboardingItem.legalUser.legalUserType == 'PARTNERSHIP'">

                          <p class="fw-bold">We require the following documents from your end</p>
                          <ol>
                            <li v-for="(requiredDoc, index) in mangopayRequiredDocuments.legalUserConfig.businessOrPartnershipRequirements.requiredDocuments" :key="index" class="mb-3">
                              <div class="fw-bold d-flex align-items-center">
                                <a :href="'#onboardingDocOfType'+requiredDoc" @click.prevent="scrollToDocumentType(requiredDoc)">
                                  {{ getDocumentType(requiredDoc) }}
                                  <span v-if="getDocumentTypeStatus(requiredDoc) == 'VALIDATION_ASKED' || getDocumentTypeStatus(requiredDoc) == 'CREATED'" class="badge bg-warning ms-2"><font-awesome-icon :icon="['fas', 'hourglass']" class="me-2"/>{{ getDocumentStatus(getDocumentTypeStatus(requiredDoc)) }}</span>
                                  <span v-else-if="getDocumentTypeStatus(requiredDoc) == 'REFUSED' || getDocumentTypeStatus(requiredDoc) == 'OUT_OF_DATE'" class="badge bg-danger ms-2"><font-awesome-icon :icon="['fas', 'circle-exclamation']" class="me-2"/>{{ getDocumentStatus(getDocumentTypeStatus(requiredDoc)) }}</span>
                                  <span v-else-if="getDocumentTypeStatus(requiredDoc) == 'VALIDATED'" class="badge bg-success ms-2"><font-awesome-icon :icon="['fas', 'check']" class="me-2"/>{{ getDocumentStatus(getDocumentTypeStatus(requiredDoc)) }}</span>
                                  <span v-else class="badge bg-warning ms-2"><font-awesome-icon :icon="['fas', 'hourglass']" class="me-2"/>Needs to be uploaded</span>
                                </a>
                              </div>
                              <div v-if="getDocumentDescription(formPrefilledValues.country, requiredDoc)">{{ getDocumentDescription(formPrefilledValues.country, requiredDoc) }}</div>
                              <div v-else-if="requiredDoc=='IDENTITY_PROOF'">
                                Your {{ getDocumentType(requiredDoc) }} can be any of the following documents:
                                <ul>
                                  <li v-for="(requiredDoc, index) in getAllowedIdDocumentTypes(formPrefilledValues.legalRepresentativeNationality)" :key="index">{{ getDocumentType(requiredDoc) }}</li>
                                </ul>
                              </div>
                            </li>
                          </ol>
                          <p>Please check out our <a href="https://support.efty.com/article/161-verifying-identity-for-business-sellers" target="_blank">knowledge base</a> for best practices on providing us with the necessary documents.</p>

                        </div>

                        <div v-else-if="mangopayOnboardingItem.legalUser.legalUserType == 'ORGANIZATION'">

                          <p class="fw-bold">We require the following documents from your end:</p>
                          <ol>
                            <li v-for="(requiredDoc, index) in mangopayRequiredDocuments.legalUserConfig.organizationRequirements.requiredDocuments" :key="index" class="mb-2">
                              <div class="fw-bold d-flex align-items-center">
                                {{ getDocumentType(requiredDoc) }}
                                <span v-if="getDocumentTypeStatus(requiredDoc) == 'VALIDATION_ASKED' || getDocumentTypeStatus(requiredDoc) == 'CREATED'" class="badge bg-warning ms-2"><font-awesome-icon :icon="['fas', 'hourglass']" class="me-2"/>{{ getDocumentStatus(getDocumentTypeStatus(requiredDoc)) }}</span>
                                <span v-else-if="getDocumentTypeStatus(requiredDoc) == 'REFUSED' || getDocumentTypeStatus(requiredDoc) == 'OUT_OF_DATE'" class="badge bg-danger ms-2"><font-awesome-icon :icon="['fas', 'circle-exclamation']" class="me-2"/>{{ getDocumentStatus(getDocumentTypeStatus(requiredDoc)) }}</span>
                                <span v-else-if="getDocumentTypeStatus(requiredDoc) == 'VALIDATED'" class="badge bg-success ms-2"><font-awesome-icon :icon="['fas', 'check']" class="me-2"/>{{ getDocumentStatus(getDocumentTypeStatus(requiredDoc)) }}</span>
                                <span v-else class="badge bg-warning ms-2"><font-awesome-icon :icon="['fas', 'hourglass']" class="me-2"/>Needs to be uploaded</span>
                              </div>
                              <div v-if="getDocumentDescription(formPrefilledValues.country, requiredDoc)">{{ getDocumentDescription(formPrefilledValues.country, requiredDoc) }}</div>
                              <div v-else-if="requiredDoc=='IDENTITY_PROOF'">
                                Your {{ getDocumentType(requiredDoc) }} can be any of the following documents:
                                <ul>
                                  <li v-for="(requiredDoc, index) in getAllowedIdDocumentTypes(formPrefilledValues.legalRepresentativeNationality)" :key="index">{{ getDocumentType(requiredDoc) }}</li>
                                </ul>
                              </div>
                            </li>
                          </ol>

                          <p>Please check out our <a href="https://support.efty.com/article/161-verifying-identity-for-business-sellers" target="_blank">knowledge base</a> for best practices on providing us with the necessary documents.</p>
                        </div>

                        <div v-else-if="mangopayOnboardingItem.legalUser.legalUserType == 'SOLETRADER'">

                          <p class="fw-bold">We require the following documents from your end:</p>
                          <ol>
                            <li v-for="(requiredDoc, index) in mangopayRequiredDocuments.legalUserConfig.soleTraderRequirements.requiredDocuments" :key="index" class="mb-2">
                              <div class="fw-bold d-flex align-items-center">
                                {{ getDocumentType(requiredDoc) }}
                                <span v-if="getDocumentTypeStatus(requiredDoc) == 'VALIDATION_ASKED' || getDocumentTypeStatus(requiredDoc) == 'CREATED'" class="badge bg-warning ms-2"><font-awesome-icon :icon="['fas', 'hourglass']" class="me-2"/>{{ getDocumentStatus(getDocumentTypeStatus(requiredDoc)) }}</span>
                                <span v-else-if="getDocumentTypeStatus(requiredDoc) == 'REFUSED' || getDocumentTypeStatus(requiredDoc) == 'OUT_OF_DATE'" class="badge bg-danger ms-2"><font-awesome-icon :icon="['fas', 'circle-exclamation']" class="me-2"/>{{ getDocumentStatus(getDocumentTypeStatus(requiredDoc)) }}</span>
                                <span v-else-if="getDocumentTypeStatus(requiredDoc) == 'VALIDATED'" class="badge bg-success ms-2"><font-awesome-icon :icon="['fas', 'check']" class="me-2"/>{{ getDocumentStatus(getDocumentTypeStatus(requiredDoc)) }}</span>
                                <span v-else class="badge bg-warning ms-2"><font-awesome-icon :icon="['fas', 'hourglass']" class="me-2"/>Needs to be uploaded</span>
                              </div>
                              <div v-if="getDocumentDescription(formPrefilledValues.country, requiredDoc)">{{ getDocumentDescription(formPrefilledValues.country, requiredDoc) }}</div>
                              <div v-else-if="requiredDoc=='IDENTITY_PROOF'">
                                Your {{ getDocumentType(requiredDoc) }} can be any of the following documents:
                                <ul>
                                  <li v-for="(requiredDoc, index) in getAllowedIdDocumentTypes(formPrefilledValues.legalRepresentativeNationality)" :key="index">{{ getDocumentType(requiredDoc) }}</li>
                                </ul>
                              </div>
                            </li>
                          </ol>
                          <p>Please check out our <a href="https://support.efty.com/article/161-verifying-identity-for-business-sellers" target="_blank">knowledge base</a> for best practices on providing us with the necessary documents.</p>

                        </div>

                      </div>

                      <hr class="my-5">

                      <form action="" method="get" class="h-100" id="onboardingForm" enctype="multipart/form-data">

                        <div id="document-wrapper">

                          <div v-if="mangopayOnboardingItem">
                            <h4 class="fw-bold text-align-start mb-3" v-if="!mangopayOnboardingItem.associatedKycDocumentDetails || mangopayOnboardingItem.associatedKycDocumentDetails.length > 0">Uploaded documents</h4>

                            <!-- Existing Document -->
                            <div v-for="(onboardingDocument, index) in getMostRelevantDocumentByType(mangopayOnboardingItem.associatedKycDocumentDetails)" :key="onboardingDocument.mangopayDocumentId">
                              <div :id="'onboardingDocOfType'+onboardingDocument.documentType" class="card bg-light shadow-sm mb-3 p-3" >
                                <input type="hidden" :id="'onboardingDocumentId_'+index" :name="'onboardingDocumentId_'+index">
                                <span ></span>
                                <div
                                    class="card-header fw-bold h6 border-0"
                                    data-bs-toggle="collapse"
                                    :data-bs-target="'#collapseOnboardingDocumentId_'+index"
                                    aria-expanded="false"
                                    :aria-controls="'collapseOnboardingDocumentId_'+index"
                                    style="cursor: pointer;"
                                    @click="onboardingDocument.isCollapsed = !onboardingDocument.isCollapsed">

                                  <div class="d-flex justify-content-between align-items-center">

                                    <span class="mb-0">{{ getDocumentType(onboardingDocument.documentType) }}</span>
                                    <span v-if="onboardingDocument.status == 'VALIDATION_ASKED' || onboardingDocument.status == 'CREATED'" class="badge bg-warning ms-2"><font-awesome-icon :icon="['fas', 'hourglass']" class="me-2"/>{{ getDocumentStatus(onboardingDocument.status) }}</span>
                                    <span v-else-if="onboardingDocument.status == 'REFUSED' || onboardingDocument.status == 'OUT_OF_DATE'" class="badge bg-danger ms-2"><font-awesome-icon :icon="['fas', 'circle-exclamation']" class="me-2"/>{{ getDocumentStatus(onboardingDocument.status) }}</span>
                                    <span v-else-if="onboardingDocument.status == 'VALIDATED'" class="badge bg-success ms-2"><font-awesome-icon :icon="['fas', 'check']" class="me-2"/>{{ getDocumentStatus(onboardingDocument.status) }}</span>

                                    <font-awesome-icon :icon="['fas', 'chevron-up']" class="ms-auto" v-if="onboardingDocument.isCollapsed"/>
                                    <font-awesome-icon :icon="['fas', 'chevron-down']" class="ms-auto" v-else />

                                  </div>
                                </div>
                                <div :id="'collapseOnboardingDocumentId_'+index" class="collapse">
                                  <div class="card-body">
                                    <span class="small">Uploaded: {{ dayjs(onboardingDocument.created).format('YYYY-MM-DD HH:mm')  }}</span>
                                    <div class="alert bg-danger mt-2" v-if="onboardingDocument.status == 'REFUSED'">
                                      <p class="small text-white mb-0">This document has not been accepted with the following reason: '{{ getDocumentRefusalReason(onboardingDocument.refusedReasonType) }}'</p>
                                      <p class="small mb-0 text-white mt-3">Please correct the errors and re-upload the document.</p>
                                    </div>
                                    <div class="mt-2" v-else-if="onboardingDocument.status == 'VALIDATION_ASKED'">
                                      <p class="small mb-0">This document is awaiting validation. We will notify you via e-mail once the document has been processed.</p>
                                    </div>
                                    <div class="mt-2" v-else-if="onboardingDocument.status == 'CREATED'">
                                      <p class="small mb-0">This document is uploaded, but you need to to submit it by going to the next step in the onboarding wizard.</p>
                                    </div>
                                    <div class="mt-2" v-else-if="onboardingDocument.status == 'OUT_OF_DATE'">
                                      <p class="small mb-0">The document has passed its expiry date; it is no longer valid. Submit an in-date accepted identity proof.</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- Existing Document -->
                            <hr class="mt-5">
                          </div>
                        </div>

                        <!-- New Document -->
                        <div class="w-100 p-3 border rounded bg-light mt-5" id="document-wrapper">
                          <div>
                            <h4 class="fw-bold text-align-start mb-3">Upload new document</h4>
                          </div>

                          <div class="input-group mt-3">

                            <div class="form-floating">
                              <select class="form-select" id="documentType" name="documentType" required @change="onDocumentTypeChange">
                                <option selected></option>
                                <option v-for="docType in onboardingDocumentTypeAllowedUploadList" :key="docType.enum" :value="docType.enum">{{ docType.title }}</option>
                              </select>
                              <label for="floatingSelect">Document type</label>
                            </div>
                            <span data-bs-toggle="tooltip" data-bs-placement="top"  data-bs-original-title="First select the type of document you will be uploading. Please make sure that a document that consists of multiple pages, are uploaded as seperate pages in one single document, using the 'Add page to this document' button." data-bs-html="true" class="input-group-text"><font-awesome-icon :icon="['fas', 'circle-question']" class="text-secondary" /></span>

                          </div>

                          <div class="alert alert-warning mt-3" role="alert" v-if="selectedDocumentType == '1'">
                            <div class="text-dark my-auto">
                              <font-awesome-icon :icon="['fas', 'circle-exclamation']" class="m-0 me-2"/>
                              <span class="">For ID cards, driving licenses, residence permits: two files (front and back) need to be uploaded separately. Use the 'Add page to document' button to add a second page to your upload.</span>
                            </div>
                          </div>

                          <div class="alert alert-warning mt-3" role="alert" v-if="selectedDocumentType == '3' || selectedDocumentType == '4'">
                            <div class="text-dark my-auto">
                              <font-awesome-icon :icon="['fas', 'circle-exclamation']" class="m-0 me-2"/>
                              <span class="">If your document has multiple pages, please upload it as 1 PDF file containing all pages.</span>
                            </div>
                          </div>

                          <div class="alert alert-warning mt-3" role="alert" v-if="selectedDocumentType == '2'">
                            <div class="text-dark my-auto">
                              <font-awesome-icon :icon="['fas', 'circle-exclamation']" class="m-0 me-2"/>
                              <span class="">Your document should not be older then 3 months. If your document has multiple pages, please upload it as 1 PDF file containing all pages.</span>
                            </div>
                          </div>

                          <div class="alert alert-warning mt-3" role="alert" v-if="selectedDocumentType == '5'">
                            <div class="text-dark my-auto">
                              <font-awesome-icon :icon="['fas', 'circle-exclamation']" class="m-0 me-2"/>
                              <span class="">Your document should not be older then 3 months. If your document has multiple pages, please upload it as 1 PDF file containing all pages.</span>
                            </div>
                          </div>

                          <div class="mt-3 ps-4">

                            <div class="input-group">
                              <label class="fw-bold my-auto me-3" for="documentToUpload">Page 1</label>
                              <input class="form-control" accept="image/jpg, image/jpeg, image/png, application/pdf" type="file" name="documentToUpload" id="documentToUpload" required @change="uploadFile">
                            </div>
                            <div class="text-grey small w-100 text-end"><font-awesome-icon :icon="['fas', 'circle-exclamation']" class="m-0 me-2"/>JPEG, PNG, PDF, Max filesize: 7MB</div>

                          </div>

                          <div id="extra-pages-wrapper">

                            <div class="mt-3 ps-4 input-group" v-for="(onboardingDocumentExtraPage, index) in onboardingDocumentExtraPages" :key="onboardingDocumentExtraPage.id">

                              <div class="input-group">
                                <label class="fw-bold my-auto me-3" :for="'documentToUpload[' + (index+1) + ']'">Page {{ index + 2 }}</label>
                                <input class="form-control" accept="image/jpg, image/jpeg, image/png, application/pdf" type="file" :name="'documentToUpload[' + (index+1) + ']'" :id="'documentToUpload[' + (index+1) + ']'" required @change="uploadExtraPage">
                                <span class="fw-bold my-auto ms-3" @click="removePageFromDocument(index)">
                                  <font-awesome-icon :icon="['fas', 'xmark']" />
                                </span>
                              </div>
                              <div class="text-grey small w-100 text-end"><font-awesome-icon :icon="['fas', 'circle-exclamation']" class="m-0 me-2"/>JPEG, PNG, PDF, Max filesize: 7MB</div>

                            </div>

                          </div>

                          <div class="w-100 text-end ps-4">

                            <div class="btn btn-outline-secondary mt-3 text-end" @click="addPageToDocument">
                              <font-awesome-icon :icon="['fas', 'file-circle-plus']" class="me-2"/>Add a page to this document
                            </div>

                          </div>

                          <hr class="mt-3">

                          <div class="w-100 text-end">
                            <div class="btn btn-primary mt-3 text-end" @click="addOnboardingDocument">
                              <font-awesome-icon :icon="['fas', 'cloud-upload-alt']" class="me-2"/>Upload<span v-if="onboardingDocumentExtraPages.length > 0" class="text-white"> all {{ onboardingDocumentExtraPages.length+1 }} pages in</span> document
                            </div>
                          </div>
                        </div>
                        <!-- ./New Document -->

                      </form>

                    </div>
                    <div class="col-12 col-lg-5 pt-4 pt-lg-0">
                      <div class="h-100 p-4 pt-0">
                       <h3 class="h4 fw-bold">Let's make sure everything is safe and secure</h3>
                        <p class="small">Let's discuss how Efty Pay handles payouts to you. When it comes to sending money to your bank account, we have some legal stuff to take care of. It's all about making sure everything is safe and secure.</p>
                        <img src="@/assets/security.png" class="img-fluid ">
                        <p class="small mt-2">We follow certain rules and guidelines to prevent any funny business with money. These rules are called anti-money laundering and countering the financing of terrorism (AML/CFT). They help keep our financial system clean and fair for everyone.</p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="w-100 mt-5">
                        <hr class="mb-5">
                        <button class="btn btn-lg btn-light w-50 rounded-0 rounded-start" role="button" type="button" @click="gotoPreviousOnboardingStep()" v-if="previousOnboardingStepExists" >Go back</button>
                        <button :class="['btn btn-lg btn-success', previousOnboardingStepExists ? 'w-50 rounded-0 rounded-end' : 'w-100']" role="button" type="button" @click="submitForm()">Submit <span class="d-none d-lg-inline-block text-white">all documents and continue</span></button>
                      </div>
                    </div>
                  </div>
                </div>



              </div>
              <!-- ./Upload documents -->


              <!-- Form completed message -->
              <div v-if="onboardingStep=='COMPLETE'">

                <form action="" method="get" class="h-100" id="onboardingForm">
                  <div class="row">

                    <div class="col-12 col-lg-10 offset-lg-1 text-center">

                      <h3 class="fw-bold mb-1">Thank you for completing the first step of the Efty Pay Seller Onboarding</h3>
                      <p class="mt-4 mb-5 h5">You are now ready to start selling domains through Efty Pay. Make sure to complete the <router-link :to="{ name: 'publicHome' }" class="text-dark">financial onboarding</router-link> as well, to be able to receive pay-outs in the future.</p>

                      <img src="@/assets/success.png" class="img-fluid ">

                    </div>

                  </div>

                  <div class="w-100 mt-3">
                    <button class="btn btn-lg btn-light w-50 rounded-0 rounded-start" role="button" type="button" @click="gotoPreviousOnboardingStep()" v-if="previousOnboardingStepExists" >Go back</button>
                    <router-link :to="{ name: 'publicHome' }" :class="['btn btn-lg btn-success', previousOnboardingStepExists ? 'w-50 rounded-0 rounded-end' : 'w-100']">Close <span class="d-none d-lg-inline-block text-white">seller onboarding wizard</span></router-link>
                  </div>
                </form>

              </div>
              <!-- ./Form completed message -->


              <!-- Form completed message -->
              <div v-if="onboardingStep=='COMPLETE_FINANCIAL'">

                <form action="" method="get" class="h-100" id="onboardingForm">
                  <div class="row">

                    <div class="col-12 col-lg-10 offset-lg-1 text-center">

                      <h3 class="fw-bold mb-1">Thank you for filling out the Efty Pay Financial Onboarding Wizard</h3>
                      <p class="mt-4 mb-3 h5">The compliance team from Mangopay (our Payment Processor) will review your input. We will let you know as soon as your account has been approved for future pay-outs. </p>
                      <p class="mb-5 h5">In the meanwhile, you can start with creating your first Efty Pay transaction in <a href="https://app.efty.com/" target="_blank">Efty Investor</a>!</p>

                      <img src="@/assets/success.png" class="img-fluid ">

                    </div>

                  </div>

                  <div class="w-100 mt-3">
                    <button class="btn btn-lg btn-light w-50 rounded-0 rounded-start" role="button" type="button" @click="gotoPreviousOnboardingStep()" v-if="previousOnboardingStepExists" >Go back</button>
                    <router-link :to="{ name: 'publicHome' }" :class="['btn btn-lg btn-success', previousOnboardingStepExists ? 'w-50 rounded-0 rounded-end' : 'w-100']">Close <span class="d-none d-lg-inline-block text-white">financial onboarding wizard</span></router-link>
                  </div>
                </form>

              </div>
              <!-- ./Form completed message -->
            <div>


          </div>

        </div>

      </div>
      <FooterSection />
    </div>

  </div>
</div>

</main>

</template>

<script>
// Component Imports
import LoaderSection from '@/components/public/LoadingSpinner.vue';
import FooterSection from '@/components/public/Footer.vue';
import NavigationSection from '@/components/public/Navigation.vue';

// Utility Imports
import { isLoading } from "@/utils/support";

import {
  getCurrentOnboardingStep,
  getOnboardingStatus,
  onboardingStep,
  isLegalEntity,
  isEntityTypeBusiness,
  isEntityTypeOrganisation,
  isEntityTypeSoleProprietorship,
  onboardingStatus
} from "@/utils/onboarding.js";

// Configuration Imports
import {
  onboardingDocumentTypeList,
  onboardingStatusList,
  onboardingDocumentStatusList,
  onboardingDocumenRefusedTypeList,
  eftyIntegratorId,
  isoCountriesList,
  allowedIdDocumentTypes,
  descriptionForDocumentTypes
} from "@/utils/config.js";

// Vue-specific Imports
import { ref, watch, computed, onMounted } from 'vue';

// External Library Imports
import dayjs from 'dayjs';
import Swal from "sweetalert2";
import { Tooltip } from 'bootstrap';

// Magiclink Utility Imports
import {
  getOtpUserProfile,
  getOtpUserId,
  otpUserProfile,
  otpUserId
} from "@/utils/magiclink";

// Mangopay Onboarding Imports
import {
  getMangopayOnboardingBankAccount,
  createMangopayOnboardingNaturalPerson,
  createMangopayOnboardingLegalPerson,
  getMangopayOnboardingStatus,
  addMangopayOnboardingDocument,
  getMangopayRequiredDocuments,
  updateMangopayOnboardingNaturalPerson,
  updateMangopayOnboardingLegalPerson,
  createMangoPayUbo,
  updateMangoPayUbo,
  submitMangoPayUbo,
  mangopayOnboardingError,
  mangopayOnboardingItem,
  mangopayOnboardingBankAccountItem,
  mangopayRequiredDocuments,
  submitKycDocument,
  mangopayDocumentId
} from "@/utils/mangopay.onboarding.public";

// Need utc to convert timestamps to utc timezone
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export default {

  name: 'GetStartedView',

  components: {
    FooterSection,
    NavigationSection,
    LoaderSection
  },

  setup() {

    // Reactive references
    const availableOnboardingSteps = ref(0)
    const root = ref(null)
    const nextOnboardingStep = ref(0)
    const legalRepresentatives = ref(0)
    const beneficialOwners = ref([{birthPlace: {}, address: {}, 'newUbo': 'newUbo'+Math.random().toString(36).slice(2)}])
    const formPrefilledValues = ref(0)
    const onboardingDocuments = ref([])
    const onboardingStatusEnum = ref(0)
    const selectedDocumentType = ref(0)
    const onboardingDocumentTypeAllowedUploadList = ref([])
    const fileToUpload = ref(0)
    const extrapageToUpload = ref(0)
    const onboardingDocumentExtraPages = ref([]);

    // Computed references
    const fileToUploadName = computed(() => fileToUpload.value?.name);
    const fileToUploadExtension = computed(() => fileToUploadName.value?.substr(fileToUploadName.value?.lastIndexOf(".") + 1));
    const fileToUploadMimeType = computed(() => fileToUpload.value?.type);
    const extrapageToUploadName = computed(() => extrapageToUpload.value?.name);
    const extrapageToUploadExtension = computed(() => extrapageToUploadName.value?.substr(extrapageToUploadName.value?.lastIndexOf(".") + 1));
    const extrapageToUploadMimeType = computed(() => extrapageToUpload.value?.type);

    // Initialize Tooltip
    onMounted(() => {
      new Tooltip(document.body, {
        selector: "[data-bs-toggle='tooltip']",
      });
    });

    // Watchers
    watch(otpUserId, async context => {

      if (context) {
        // Prefill form with data
        await getOtpUserProfile()
        await getMangopayOnboardingStatus(context, eftyIntegratorId )
        await prefillForm();
        await getAvailableOnboardingSteps();
        await getOnboardingStatus(context);
        await getCurrentOnboardingStatusEnum();
        await getCurrentOnboardingStep(onboardingStatusEnum.value);
      }

    });

    watch(otpUserProfile, async (context) => {
      if (context) {
        // Prefill form with data
        await prefillForm();
        await getOnboardingStatus(context);
        await getCurrentOnboardingStatusEnum();
        await getCurrentOnboardingStep(onboardingStatusEnum.value);
        await getAvailableOnboardingSteps();
      }
    });

    watch(mangopayOnboardingItem, context => {
      if (context){
        // UBO's? Add for further useage!
        if (mangopayOnboardingItem.value.legalUser) {
          if (mangopayOnboardingItem.value.legalUser.uboDeclaration) {
            if (mangopayOnboardingItem.value.legalUser.uboDeclaration.beneficialOwners) {
              console.log('ubo', mangopayOnboardingItem.value.legalUser.uboDeclaration.beneficialOwners)
              beneficialOwners.value = mangopayOnboardingItem.value.legalUser.uboDeclaration.beneficialOwners
            }
          }
        }

        // Get bank account details
        getMangopayOnboardingBankAccount(mangopayOnboardingItem.value.sellerUserId.id, eftyIntegratorId)
      }

      // Get required docs for MangoPay
      getMangopayRequiredDocuments().then( () => {
        prefillForm()
      })
    });

    watch(mangopayOnboardingBankAccountItem, context => {
      if (context){
        prefillForm()
      }
    });

    watch(mangopayOnboardingError, context => {
      if (context){
        Swal.fire({
          customClass: {
            closeButton: 'btn btn-lg btn-secondary',
            confirmButton: 'btn btn-lg btn-success order-last',
            denyButton: 'btn btn-lg btn-secondary',
            cancelButton: 'btn btn-lg btn-secondary'
          },
          toast: true,
          position: 'bottom-right',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          icon: 'error',
          title: 'Please fix the errors in your input. If you need any help, don\'t hesitate to reach out via ask@efty.com'
        })
      }
    });

    // Get ID for OTP logged in user
    getOtpUserId()

    // Get required docs for MangoPay
    getMangopayRequiredDocuments()

    onboardingDocumentTypeList.value.forEach(function (docTypeUpload) {
      if (docTypeUpload.allow_upload == true) {
        onboardingDocumentTypeAllowedUploadList.value.push({
          name: docTypeUpload.name,
          title: docTypeUpload.title,
          enum: docTypeUpload.enum
        })
      }
    })

    // Helper methods
    const previousOnboardingStepExists = computed(() => {
      if (availableOnboardingSteps.value) {
        if (onboardingStatusEnum.value === null) {
          return false;
        }
        return availableOnboardingSteps.value.some(step => step < onboardingStatusEnum.value);
      }
      return false
    })

    const scrollToDocumentType = (requiredDoc) => {
      const element = document.getElementById('onboardingDocOfType' + requiredDoc);
      if (element) {
        const offset = 100; // Offset value in pixels
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });

        // Briefly highlight the element
        element.classList.add('highlight');
        setTimeout(() => {
          element.classList.remove('highlight');
        }, 2000); // Remove highlight after 2 seconds
      }
    };

    const getAllowedIdDocumentTypes = (countryIsoCode) => {
      return allowedIdDocumentTypes.value[countryIsoCode] || allowedIdDocumentTypes.value['default'];
    }

    const getDocumentDescription = (countryIsoCode, documentType) => {
      const countryInfo = descriptionForDocumentTypes.value.find(item => item.code === countryIsoCode);
      return countryInfo ? countryInfo[documentType] : null;
    }

    // Get list of all available onboarding steps based on legal situation of onboarding user
    const getAvailableOnboardingSteps = (  ) => {
      // Reset
      availableOnboardingSteps.value = false
      // FIRST STEP OF SELLER ONBOARDING
      if (onboardingStatus.value == 0) {
        if (isLegalEntity.value == true) {
          if (isEntityTypeBusiness.value == true) {
            availableOnboardingSteps.value = [onboardingStatusList.value[1].enum, onboardingStatusList.value[4].enum]
          } else if (isEntityTypeOrganisation.value == true) {
            availableOnboardingSteps.value = [onboardingStatusList.value[1].enum, onboardingStatusList.value[4].enum]
          } else {
            availableOnboardingSteps.value = [onboardingStatusList.value[1].enum, onboardingStatusList.value[4].enum]
          }
        } else {
          // Return current onboarding step
          availableOnboardingSteps.value = [onboardingStatusList.value[1].enum, onboardingStatusList.value[4].enum]
        }
      }
      // SECOND STEP, FINANCIAL ONBOARDING
      else if (onboardingStatus.value == 1) {
        if (isLegalEntity.value == true) {
          if (isEntityTypeBusiness.value == true) {
            availableOnboardingSteps.value = [onboardingStatusList.value[2].enum, onboardingStatusList.value[3].enum, onboardingStatusList.value[5].enum]
          } else if (isEntityTypeOrganisation.value == true) {
            availableOnboardingSteps.value = [onboardingStatusList.value[2].enum, onboardingStatusList.value[5].enum]
          } else {
            availableOnboardingSteps.value = [onboardingStatusList.value[2].enum, onboardingStatusList.value[5].enum]
          }
        } else {
          // Return current onboarding step
          availableOnboardingSteps.value = [onboardingStatusList.value[2].enum, onboardingStatusList.value[5].enum]
        }
      }
    }

    const getCurrentOnboardingStatusEnum = async () => {
      try {
        // Check if availableOnboardingSteps.value is an array
        if (Array.isArray(availableOnboardingSteps.value)) {
          // Check if the enum value 2 (DOCUMENTS) is in availableOnboardingSteps
          if (availableOnboardingSteps.value.includes(2)) {
            // Check all values in mangopayOnboardingItem.associatedKycDocumentDetails for REFUSED status
            const anyRefused = mangopayOnboardingItem.value.associatedKycDocumentDetails?.some(doc => doc.status === 'REFUSED');
            if (anyRefused) {
              onboardingStatusEnum.value = 2;
              return;
            }
          }

          // Check if the enum value 3 (OWNERS) is in availableOnboardingSteps
          if (availableOnboardingSteps.value.includes(3)) {
            const uboStatus = mangopayOnboardingItem.value.legalUser?.uboDeclaration?.status;
            if (uboStatus === 'REFUSED' || uboStatus === 'INCOMPLETE') {
              onboardingStatusEnum.value = 3;
              return;
            }
          }

          // Default value or other logic can go here
          onboardingStatusEnum.value = availableOnboardingSteps.value[0];
        } else {
          console.error('availableOnboardingSteps is not an array');
          onboardingStatusEnum.value = null;
        }
      } catch (error) {
        console.error('Error getting onboarding status:', error);
        onboardingStatusEnum.value = null; // or some default error handling
      }
    }

    // Change legal status of onboarding user based on event change on select item
    const onIsLegalEntityChange = ( event ) => {
      if ( event.target.options[event.target.options.selectedIndex].value == 'LEGAL_USER') {
        isLegalEntity.value = true // Company
      } else {
        isLegalEntity.value = false // Natural person
      }
      getAvailableOnboardingSteps()
    }

    const onDocumentTypeChange = ( event ) => {
      selectedDocumentType.value = event.target.options[event.target.options.selectedIndex].value
    }

    // Change legal status of onboarding user based on event change on select item
    const onCompanyTypeChange = ( event ) => {
      if ( event.target.options[event.target.options.selectedIndex].value == 'BUSINESS') {
        isEntityTypeBusiness.value = true // Business
        isEntityTypeOrganisation.value = false // Organisation
        isEntityTypeSoleProprietorship.value = false // Sole proprietorship
      } else if ( event.target.options[event.target.options.selectedIndex].value == 'ORGANIZATION') {
        isEntityTypeBusiness.value = false // Business
        isEntityTypeOrganisation.value = true // Organisation
        isEntityTypeSoleProprietorship.value = false // Sole proprietorship
      } else if ( event.target.options[event.target.options.selectedIndex].value == 'SOLETRADER') {
        isEntityTypeBusiness.value = false // Business
        isEntityTypeOrganisation.value = false // Organisation
        isEntityTypeSoleProprietorship.value = true // Sole proprietorship
      }
      getAvailableOnboardingSteps()
    }

    const changeOnboardingStep = ( newStepEnum ) => {
      // Reset onboarding error messages
      mangopayOnboardingError.value = false
      // Scroll to top of window
      window.scrollTo(0,0)
      // Reset
      onboardingStatusEnum.value = newStepEnum
      // Return current onboarding step
      onboardingStep.value = onboardingStatusList.value[newStepEnum].name
    }

    const addBeneficialOwner = (  ) => {
      beneficialOwners.value.push({'id': 'newUbo'+Math.random().toString(36).slice(2), birthPlace: {}, address: {}})
      prefillForm()
    }

    const removeBeneficialOwner = ( beneficialOwnerId ) => {
      if (beneficialOwnerId) {
        let i = beneficialOwners.value.map(item => item.id).indexOf(beneficialOwnerId) // find index of your object
        beneficialOwners.value.splice(i, 1)
      }
    }

    // Trigger computed consts for the main file
    const uploadFile = (event) => {
      fileToUpload.value = event.target.files[0];
    };

    // Add a page to the UX
    const addPageToDocument = async () => {
      if (onboardingDocumentExtraPages.value.length < 1) {
        onboardingDocumentExtraPages.value.push({'id': 'newPage' + Math.random().toString(36).slice(2)})
      }
    }

    // Remove a page from the UX
    const removePageFromDocument = ( pageId ) => {
      onboardingDocumentExtraPages.value.splice(pageId, 1)
    }

    // Trigger computed consts for the additional page
    const uploadExtraPage = (event) => {
      extrapageToUpload.value = event.target.files[0];
    };

    const addOnboardingDocument = async () => {
      if (! root.value.querySelector('form#onboardingForm').checkValidity()) {
        root.value.querySelector('form#onboardingForm').reportValidity()
      }
      else {
        Swal.fire({
          customClass: {
            closeButton: 'btn btn-lg btn-secondary',
            confirmButton: 'btn btn-lg btn-success order-last',
            denyButton: 'btn btn-lg btn-secondary',
            cancelButton: 'btn btn-lg btn-secondary'
          },
          toast: false,
          position: 'center',
          showConfirmButton: true,
          showCancelButton: true,
          timerProgressBar: false,
          icon: 'warning',
          title: 'Did you upload all pages?',
          text: 'Please make sure you upload all pages for this document. For ID cards, driving licenses and residence permits: two files (front and back) need to be uploaded separately. Each page needs to be uploaded separately using the "Add page to this document" button'
        }).then((result) => {
          if (result.value) {
            /* Start with the first page of the document */
            const reader = new FileReader();
            const documentType = root.value.querySelector('#documentType').value
            reader.readAsDataURL(fileToUpload.value);

            reader.onload = async () => {
              const encodedFile = reader.result.split(",")[1];
              const data = {
                file: encodedFile,
                fileName: fileToUploadName.value,
                fileExtension: fileToUploadExtension.value,
                fileMimeType: fileToUploadMimeType.value,
                fileType: fileToUploadExtension.value == 'pdf' ? 'PDF' : 'IMAGE',
                fileSize: fileToUpload.value.size,
                fileSizeMb: fileToUpload.value.size / 1000000,
                base64string: reader.result.replace('data:', '').replace(/^.+,/, '')
              };
              if (data.fileSizeMb > 7) {
                Swal.fire({
                  customClass: {
                    closeButton: 'btn btn-lg btn-secondary',
                    confirmButton: 'btn btn-lg btn-success order-last',
                    denyButton: 'btn btn-lg btn-secondary',
                    cancelButton: 'btn btn-lg btn-secondary'
                  },
                  toast: true,
                  position: 'bottom-right',
                  showConfirmButton: false,
                  timer: 5000,
                  timerProgressBar: true,
                  icon: 'error',
                  title: 'The max file size is 7MB per uploaded document. Please retry with a smaller document.'
                })
              } else {
                addMangopayOnboardingDocument(otpUserId.value, eftyIntegratorId, data.fileType, data.base64string, documentType).then( () => {

                  // Reset arrays/consts with current file uploads
                  fileToUpload.value = ''
                  root.value.querySelector('#documentToUpload').value = ''

                  // Check for additional pages
                  if (extrapageToUpload.value != '') {
                    const extraPageReader = new FileReader();
                    extraPageReader.readAsDataURL(extrapageToUpload.value);
                    extraPageReader.onload = async () => {
                      const encodedSecondFile = extraPageReader.result.split(",")[1];
                      const dataSecondFile = {
                        file: encodedSecondFile,
                        fileName: extrapageToUploadName.value,
                        fileExtension: extrapageToUploadExtension.value,
                        fileMimeType: extrapageToUploadMimeType.value,
                        fileType: extrapageToUploadExtension.value == 'pdf' ? 'PDF' : 'IMAGE',
                        fileSize: extrapageToUpload.value.size,
                        fileSizeMb: extrapageToUpload.value.size / 1000000,
                        base64string: extraPageReader.result.replace('data:', '').replace(/^.+,/, '')
                      };
                      if (dataSecondFile.fileSizeMb > 7) {
                        Swal.fire({
                          customClass: {
                            closeButton: 'btn btn-lg btn-secondary',
                            confirmButton: 'btn btn-lg btn-success order-last',
                            denyButton: 'btn btn-lg btn-secondary',
                            cancelButton: 'btn btn-lg btn-secondary'
                          },
                          toast: true,
                          position: 'bottom-right',
                          showConfirmButton: false,
                          timer: 5000,
                          timerProgressBar: true,
                          icon: 'error',
                          title: 'The max file size is 7MB per uploaded document. Your extra page exceeds this maximum. Please retry with a smaller document.'
                        })
                      } else {
                        addMangopayOnboardingDocument(otpUserId.value, eftyIntegratorId, dataSecondFile.fileType, dataSecondFile.base64string, documentType, mangopayDocumentId.value).then( () => {
                          // Reset arrays/consts with current file uploads
                          extrapageToUpload.value = ''
                          onboardingDocumentExtraPages.value = ''
                        })
                      }
                    }
                  }

                  if (mangopayOnboardingError.value == '') {
                    Swal.fire({
                      customClass: {
                        closeButton: 'btn btn-lg btn-secondary',
                        confirmButton: 'btn btn-lg btn-success order-last',
                        denyButton: 'btn btn-lg btn-secondary',
                        cancelButton: 'btn btn-lg btn-secondary'
                      },
                      toast: false,
                      position: 'center',
                      showConfirmButton: true,
                      icon: 'success',
                      title: 'File uploaded successfully',
                      text: 'Make sure to continue to the next step to submit your document for review, once you have uploaded all required documents.'
                    })
                  } else {
                    Swal.fire({
                      customClass: {
                        closeButton: 'btn btn-lg btn-secondary',
                        confirmButton: 'btn btn-lg btn-success order-last',
                        denyButton: 'btn btn-lg btn-secondary',
                        cancelButton: 'btn btn-lg btn-secondary'
                      },
                      toast: true,
                      position: 'bottom-right',
                      showConfirmButton: false,
                      timer: 5000,
                      timerProgressBar: true,
                      icon: 'error',
                      title: 'There was en error processing your file, please check the top of the page for details.'
                    })
                  }
                })
              }
            }
          }
        });
      }
    }

    // Process UBOs and determine if this should be a create or an update call to the API
    const processUbos = async ( ) => {
      console.log('Processing UBOs')
      submitMangoPayUbo(otpUserId.value, eftyIntegratorId)

    }

    const submitForm = async (  ) => {
      // Do HTML validation (but skip for file uploads because of nested uploads)
      if (!root.value.querySelector('form#onboardingForm').checkValidity() && onboardingStep.value != 'DOCUMENTS') {
        root.value.querySelector('form#onboardingForm').reportValidity()
      }
      //Go to next step
      else {
        // Process from data
        if (onboardingStep.value == 'DETAILS') {
          // Get onboarding type from FORM
          const onboardingType = ref(0)
          onboardingType.value = root.value.querySelector('#onboardingType').value
          // Onboarding natural person
          if (onboardingType.value == 'NATURAL_USER') {

            // Fetch all data from FORM
            const firstName = ref(0)
            const lastName = ref(0)
            const email = ref(0)
            const dateOfBirth = ref(0)
            const nationality = ref(0)
            const countryOfResidence = ref(0)

            firstName.value = root.value.querySelector('#firstName').value
            lastName.value = root.value.querySelector('#lastName').value
            email.value = root.value.querySelector('#email').value
            dateOfBirth.value = root.value.querySelector('#dateOfBirth').value
            nationality.value = root.value.querySelector('#nationality').value
            countryOfResidence.value = root.value.querySelector('#countryOfResidence').value

            // Mangopay user does not exist yet, so create it
            if (mangopayOnboardingItem.value == false) {
              createMangopayOnboardingNaturalPerson(otpUserId.value, eftyIntegratorId, firstName.value, lastName.value, email.value, dateOfBirth.value, nationality.value, countryOfResidence.value)
            }

            // Existing Mangopay user, so proceed to update
            else {
              updateMangopayOnboardingNaturalPerson(otpUserId.value, eftyIntegratorId, mangopayOnboardingItem, firstName.value, lastName.value, email.value, dateOfBirth.value, nationality.value, countryOfResidence.value)
            }
          }

          // Onboarding legal person
          else if (onboardingType.value == 'LEGAL_USER') {
            // Fetch all data from FORM
            const legalUserType = ref(0)
            const registeredName = ref(0)
            const email = ref(0)
            const companyNumber = ref(0)
            const companyVatNumber = ref(0)
            const addressLine1 = ref(0)
            const postalCode = ref(0)
            const city = ref(0)
            const regionOrCounty = ref(0)
            const country = ref(0)
            const legalRepresentativeFirstName = ref(0)
            const legalRepresentativeLastName = ref(0)
            const legalRepresentativeEmail = ref(0)
            const legalRepresentativeDateOfBirth = ref(0)
            const legalRepresentativeNationality = ref(0)
            const legalRepresentativeCountryOfResidence = ref(0)

            // Company form fields
            legalUserType.value = root.value.querySelector('#legalUserType').value
            registeredName.value = root.value.querySelector('#registeredName').value
            email.value = root.value.querySelector('#email').value
            companyNumber.value = root.value.querySelector('#companyNumber').value
            companyVatNumber.value = root.value.querySelector('#companyVatNumber').value
            addressLine1.value = root.value.querySelector('#addressLine1').value
            postalCode.value = root.value.querySelector('#postalCode').value
            city.value = root.value.querySelector('#city').value
            regionOrCounty.value = root.value.querySelector('#regionOrCounty').value
            country.value = root.value.querySelector('#country').value

            // Legal rep form fields
            legalRepresentativeFirstName.value = root.value.querySelector('#legalRepresentativeFirstName').value
            legalRepresentativeLastName.value = root.value.querySelector('#legalRepresentativeLastName').value
            legalRepresentativeEmail.value = root.value.querySelector('#legalRepresentativeEmail').value
            legalRepresentativeDateOfBirth.value = root.value.querySelector('#legalRepresentativeDateOfBirth').value
            legalRepresentativeNationality.value = root.value.querySelector('#legalRepresentativeNationality').value
            legalRepresentativeCountryOfResidence.value = root.value.querySelector('#legalRepresentativeCountryOfResidence').value

            // Mangopay user does not exist yet, so create it
            if (mangopayOnboardingItem.value == false) {
              createMangopayOnboardingLegalPerson(otpUserId.value, eftyIntegratorId, legalUserType.value, registeredName.value, email.value, companyNumber.value, companyVatNumber.value, addressLine1.value, postalCode.value, city.value, regionOrCounty.value, country.value, legalRepresentativeFirstName.value, legalRepresentativeLastName.value, legalRepresentativeEmail.value, legalRepresentativeDateOfBirth.value, legalRepresentativeNationality.value, legalRepresentativeCountryOfResidence.value)
            }
            // Existing Mangopay user, so proceed to update
            else {
              updateMangopayOnboardingLegalPerson(otpUserId.value, eftyIntegratorId, mangopayOnboardingItem, beneficialOwners.value, legalUserType.value, registeredName.value, email.value, companyNumber.value, companyVatNumber.value, addressLine1.value, postalCode.value, city.value, regionOrCounty.value, country.value, legalRepresentativeFirstName.value, legalRepresentativeLastName.value, legalRepresentativeEmail.value, legalRepresentativeDateOfBirth.value, legalRepresentativeNationality.value, legalRepresentativeCountryOfResidence.value)
              getMangopayOnboardingStatus(otpUserId.value, eftyIntegratorId)
            }
          }
        }

        // Onboarding legal person
        else if (onboardingStep.value == 'OWNERS') {

          // Fetch all data from FORM
          const beneficialOwnerFirstName = ref(0)
          const beneficialOwnerLastName = ref(0)
          const beneficialOwnerAddressLine1 = ref(0)
          const beneficialOwnerPostalCode = ref(0)
          const beneficialOwnerCity = ref(0)
          const beneficialOwnerCountry = ref(0)
          const beneficialOwnerDateOfBirth = ref(0)
          const beneficialOwnerCountryOfBirth = ref(0)
          const beneficialOwnerPlaceOfBirth = ref(0)
          const beneficialOwnerNationality = ref(0)
          const beneficialOwnerAddress = ref(0)
          const beneficialOwnerBirthPlace = ref(0)
          const beneficialOwnerRegionOrCountry = ref(0)
          const beneficialOwner = ref(0)
          const uboId = ref(0)

          for (const key of Object.keys(beneficialOwners.value)) {

            // Reset object
            beneficialOwner.value = {}
            beneficialOwnerAddress.value = {}
            beneficialOwnerBirthPlace.value = {}

            // UBO form fields (use querySelector on name attribute, to make it work with an array of objects)
            beneficialOwnerFirstName.value = root.value.querySelector('[name="beneficialOwnerFirstName_' + key).value
            beneficialOwnerLastName.value = root.value.querySelector('[name="beneficialOwnerLastName_' + key).value
            beneficialOwnerAddressLine1.value = root.value.querySelector('[name="beneficialOwnerAddressLine1_' + key).value
            beneficialOwnerPostalCode.value = root.value.querySelector('[name="beneficialOwnerPostalCode_' + key).value
            beneficialOwnerCity.value = root.value.querySelector('[name="beneficialOwnerCity_' + key).value
            beneficialOwnerCountry.value = root.value.querySelector('[name="beneficialOwnerCountry_' + key).value
            beneficialOwnerDateOfBirth.value = dayjs(root.value.querySelector('[name="beneficialOwnerDateOfBirth_' + key).value).format('YYYY-MM-DD')
            beneficialOwnerCountryOfBirth.value = root.value.querySelector('[name="beneficialOwnerCountryOfBirth_' + key).value
            beneficialOwnerPlaceOfBirth.value = root.value.querySelector('[name="beneficialOwnerPlaceOfBirth_' + key).value
            beneficialOwnerNationality.value = root.value.querySelector('[name="beneficialOwnerNationality_' + key).value
            beneficialOwnerRegionOrCountry.value = root.value.querySelector('[name="beneficialOwnerRegionOrCountry_' + key).value

            beneficialOwnerAddress.value = {
              addressLine1: beneficialOwnerAddressLine1.value,
              postalCode: beneficialOwnerPostalCode.value,
              city: beneficialOwnerCity.value,
              country: beneficialOwnerCountry.value,
              regionOrCounty: beneficialOwnerRegionOrCountry.value
            }

            beneficialOwnerBirthPlace.value = {
              city: beneficialOwnerPlaceOfBirth.value,
              country: beneficialOwnerCountryOfBirth.value
            }

            uboId.value = ''
            uboId.value = root.value.querySelector('[name="beneficialOwnerId_' + key).value

            beneficialOwner.value = ''
            beneficialOwner.value = {
              id: uboId.value,
              firstName: beneficialOwnerFirstName.value,
              lastName: beneficialOwnerLastName.value,
              address: beneficialOwnerAddress.value,
              dateOfBirth: beneficialOwnerDateOfBirth.value + 'T00:00:00Z',
              nationality: beneficialOwnerNationality.value,
              birthPlace: beneficialOwnerBirthPlace.value,
              isActive: true
            }

            // New ubo
            if (uboId.value.substring(0, 6) == 'newUbo' || uboId.value == '') {

              // Clear ID for creation
              beneficialOwner.value.id = ''
              console.log('Create UBO', beneficialOwner.value)
              await createMangoPayUbo(otpUserId.value, eftyIntegratorId, beneficialOwner.value).then(() => {
                getMangopayOnboardingStatus(otpUserId.value, eftyIntegratorId)
              })

            }
            // Existing UBO
            else if (uboId.value !== false) {
              console.log('Update UBO', beneficialOwner.value)

              await updateMangoPayUbo(otpUserId.value, eftyIntegratorId, beneficialOwner.value).then(() => {
                getMangopayOnboardingStatus(otpUserId.value, eftyIntegratorId)
              })

            }

          }

          // After adding/submitting the UBOs, process them
          processUbos()

        }

        // Onboarding documents
        else if (onboardingStep.value == 'DOCUMENTS') {
          // Show message if there are no docs uploaded
          if (!mangopayOnboardingItem.value.associatedKycDocumentDetails || mangopayOnboardingItem.value.associatedKycDocumentDetails.length == 0) {
            Swal.fire({
              customClass: {
                closeButton: 'btn btn-lg btn-secondary',
                confirmButton: 'btn btn-lg btn-success order-last',
                denyButton: 'btn btn-lg btn-secondary',
                cancelButton: 'btn btn-lg btn-secondary'
              },
              toast: false,
              position: 'center',
              showConfirmButton: true,
              icon: 'warning',
              title: 'Please upload all required documents before proceeding to the next step'
            })
            return false
          } else {
            mangopayOnboardingItem.value.associatedKycDocumentDetails.forEach(function (mangopayKycDocument) {
              if (mangopayKycDocument.status == 'CREATED') {
                submitKycDocument(otpUserId.value, eftyIntegratorId, mangopayKycDocument.mangopayDocumentId)
              }
            });
          }
        }
        gotoNextOnboardingStep()
      }
    }

    // Define a function that gets the most relevant document by type
    const getMostRelevantDocumentByType = ( documentList ) => {

      if (!documentList) {
        // Check if the document list is falsy (null, undefined, etc.).

        return [];
        // If the list is falsy, return an empty array.
      }

      const highestPriorityDocuments = {};
      // Initialize an empty object to store the highest priority document by type.

      documentList.forEach(doc => {
        // Iterate over each document in the document list.

        const { documentType, status, created } = doc;
        // Destructure documentType, status, and created from the current document.

        if (!highestPriorityDocuments[documentType]) {
          // If no document is stored yet for this type, store the current one.
          highestPriorityDocuments[documentType] = doc;
        } else {
          const currentStoredDoc = highestPriorityDocuments[documentType];
          // Get the currently stored document for this type.

          if (status === 'VALIDATED') {
            // If the current document has the status 'VALIDATED', it has the highest priority.
            highestPriorityDocuments[documentType] = doc;
          } else if (currentStoredDoc.status !== 'VALIDATED' && new Date(created) > new Date(currentStoredDoc.created)) {
            // If the stored document doesn't have 'VALIDATED' status and the current one is newer, replace it.
            highestPriorityDocuments[documentType] = doc;
          }
        }
      });

      return Object.values(highestPriorityDocuments);
      // Return an array of the highest priority documents by type.
      
    }

    const getDocumentTypeStatus = (documentType) => {
      // Define a function that takes a document type as an argument.

      if (!documentType || !mangopayOnboardingItem.value.associatedKycDocumentDetails) {
        // Check if the document type or the associated KYC document details are falsy.

        return null;
        // If either is falsy, return null.
      }

      // Filter the documents to find those with the matching document type.
      const documents = mangopayOnboardingItem.value.associatedKycDocumentDetails.filter(
          (doc) => doc.documentType === documentType
      );

      if (documents.length > 0) {
        // If there are any documents with the matching document type.

        // Check if any document has the status 'VALIDATED'.
        const validatedDocument = documents.find(doc => doc.status === 'VALIDATED');
        if (validatedDocument) {
          // If a 'VALIDATED' document is found, return its status.
          return validatedDocument.status;
        }

        // If no 'VALIDATED' document is found, sort the documents by the 'created' date to find the most recent one.
        documents.sort((a, b) => new Date(b.created) - new Date(a.created));

        // Return the status of the most recent document.
        return documents[0].status;
      }

      return null;
      // If no documents match the document type, return null.
    };

    const getDocumentType = (documentType) => {
      if (!documentType) {
        return null;
      }

      // Initialize the result variable
      let result = '';

      // Iterate over the onboardingDocumentTypeList to find the matching document type
      onboardingDocumentTypeList.value.forEach((docType) => {
        if (docType.name === documentType) {
          result = docType.title;
        }
      });

      // Return the found title or null if no match is found
      return result || null;
    };

    const getDocumentRefusalReason = (refusalType) => {
      if (!refusalType) {
        return null;
      }

      // Initialize the result variable
      let result = '';

      // Iterate over the onboardingDocumenRefusedTypeList to find the matching refusal type
      onboardingDocumenRefusedTypeList.value.forEach((refType) => {
        if (refType.name === refusalType) {
          result = refType.title;
        }
      });

      // Return the found title or null if no match is found
      return result || null;
    };

    const getDocumentStatus = (documentStatus) => {
      if (!documentStatus) {
        return null;
      }

      // Initialize the result variable
      let result = '';

      // Iterate over the onboardingDocumentStatusList to find the matching document status
      onboardingDocumentStatusList.value.forEach((docStatus) => {
        if (docStatus.name === documentStatus) {
          result = docStatus.title;
        }
      });

      // Return the found title or null if no match is found
      return result || null;
    };

    const disableUserInput = (fieldName = '') => {
      // Check if the mangopayOnboardingItem is available
      if (mangopayOnboardingItem.value) {
        // Disable input based on the fieldName
        if (fieldName === 'onboardingType') {
          return true;
        } else if (fieldName === 'ubo') {
          const legalUser = mangopayOnboardingItem.value.legalUser;
          if (legalUser && legalUser.uboDeclaration) {
            const uboStatus = legalUser.uboDeclaration.status;
            if (uboStatus === 'VALIDATED' || uboStatus === 'VALIDATION_ASKED') {
              return true;
            }
          }
        } else if (!otpUserProfile.value.paymentDetails.mangopayDetails.kycLevel) {
          return false;
        } else if (otpUserProfile.value.paymentDetails.mangopayDetails.kycLevel === 'REGULAR') {
          return true;
        } else {
          return false;
        }
      }
    };

    const gotoPreviousOnboardingStep = () => {
      // Find the previous valid onboarding step
      for (let i = 1; i < 10; i++) {
        if (availableOnboardingSteps.value.includes(onboardingStatusEnum.value - i)) {
          nextOnboardingStep.value = onboardingStatusEnum.value - i;
          break;
        }
      }
      // Change to the previous onboarding step
      changeOnboardingStep(nextOnboardingStep.value);
    };

    const gotoNextOnboardingStep = () => {
      // Find the next valid onboarding step
      for (let i = 1; i < 10; i++) {
        if (availableOnboardingSteps.value.includes(onboardingStatusEnum.value + i)) {
          nextOnboardingStep.value = onboardingStatusEnum.value + i;
          break;
        }
      }
      // Change to the next onboarding step
      changeOnboardingStep(nextOnboardingStep.value);
    };


    // Make sure the form is prefilled with the proper info
    const prefillForm = async (  ) => {
      // Only continue with a valid user profile
      if (otpUserProfile.value) {
        // Already in process of onboarding? Use info added to Mangopay to populate the form fields
        if (mangopayOnboardingItem.value) {
          // Natural person (non company)
          if (mangopayOnboardingItem.value.onboardingType == 'NATURAL_USER') {
            formPrefilledValues.value = {
              'email': mangopayOnboardingItem.value.naturalUser.email,
              'firstName': mangopayOnboardingItem.value.naturalUser.firstName,
              'lastName': mangopayOnboardingItem.value.naturalUser.lastName,
              'countryOfResidence': mangopayOnboardingItem.value.naturalUser.countryOfResidence,
              'nationality': mangopayOnboardingItem.value.naturalUser.nationality,
              'onboardingType': mangopayOnboardingItem.value.onboardingType,
              'dateOfBirth': mangopayOnboardingItem.value.naturalUser.dateOfBirth
            }
          }
          else if (mangopayOnboardingItem.value.onboardingType == 'LEGAL_USER') {

            formPrefilledValues.value = {
              'onboardingType': mangopayOnboardingItem.value.onboardingType,
              'registeredName': mangopayOnboardingItem.value.legalUser.registeredName,
              'companyNumber': mangopayOnboardingItem.value.legalUser.companyNumber,
              'companyVatNumber': mangopayOnboardingItem.value.legalUser.vatNumber,
              'email': mangopayOnboardingItem.value.legalUser.email,
              'addressLine1': mangopayOnboardingItem.value.legalUser.registeredAddress ? mangopayOnboardingItem.value.legalUser.registeredAddress.addressLine1 : '',
              'city': mangopayOnboardingItem.value.legalUser.registeredAddress ? mangopayOnboardingItem.value.legalUser.registeredAddress.city : '',
              'regionOrCounty': mangopayOnboardingItem.value.legalUser.registeredAddress ? mangopayOnboardingItem.value.legalUser.registeredAddress.regionOrCounty : '',
              'postalCode': mangopayOnboardingItem.value.legalUser.registeredAddress ? mangopayOnboardingItem.value.legalUser.registeredAddress.postalCode : '',
              'country': mangopayOnboardingItem.value.legalUser.registeredAddress ? mangopayOnboardingItem.value.legalUser.registeredAddress.country : '',
              'legalUserType': mangopayOnboardingItem.value.legalUser.legalUserType,
              'legalRepresentativeFirstName': mangopayOnboardingItem.value.legalUser.legalRepresentative ? mangopayOnboardingItem.value.legalUser.legalRepresentative.firstName : '',
              'legalRepresentativeLastName': mangopayOnboardingItem.value.legalUser.legalRepresentative ? mangopayOnboardingItem.value.legalUser.legalRepresentative.lastName : '',
              'legalRepresentativeEmail': mangopayOnboardingItem.value.legalUser.legalRepresentative ? mangopayOnboardingItem.value.legalUser.legalRepresentative.email : '',
              'legalRepresentativeDateOfBirth': mangopayOnboardingItem.value.legalUser.legalRepresentative ? mangopayOnboardingItem.value.legalUser.legalRepresentative.dateOfBirth : '',
              'legalRepresentativeNationality': mangopayOnboardingItem.value.legalUser.legalRepresentative ? mangopayOnboardingItem.value.legalUser.legalRepresentative.nationality : '',
              'legalRepresentativeCountryOrRegion': mangopayOnboardingItem.value.legalUser.legalRepresentative ? mangopayOnboardingItem.value.legalUser.legalRepresentative.regionOrCounty : '',
              'legalRepresentativeCountryOfResidence': mangopayOnboardingItem.value.legalUser.legalRepresentative ? mangopayOnboardingItem.value.legalUser.legalRepresentative.countryOfResidence : '',
              'beneficialOwners': beneficialOwners.value ? beneficialOwners.value : [{ birthPlace: {}, address: {}, 'newUbo': 'newUbo'+Math.random().toString(36).slice(2)}]
            }

            // Properly format the DOB using dayjs
            const formattedBeneficialOwners = ref([]);

            if (beneficialOwners.value) {
              formattedBeneficialOwners.value = []

              for (let owner of beneficialOwners.value) {
                owner.dateOfBirth = new Date(owner.dateOfBirth).toISOString().slice(0, 10);
                //owner.dateOfBirth = dayjs(owner.dateOfBirth).utc().local().format('YYYY-MM-DD')
                formattedBeneficialOwners.value.push(owner);
              }
            } else {
              formattedBeneficialOwners.value = [{ birthPlace: {}, address: {}, 'newUbo': 'newUbo'+Math.random().toString(36).slice(2)}]
            }

            formPrefilledValues.value.beneficialOwners = formattedBeneficialOwners.value;

            // Push form into legal user mode
            isLegalEntity.value = true
            // Get available onboarding steps
            if ( mangopayOnboardingItem.value.legalUser.legalUserType == 'BUSINESS') {
              isEntityTypeBusiness.value = true // Business
              isEntityTypeOrganisation.value = false // Organisation
              isEntityTypeSoleProprietorship.value = false // Sole proprietorship
            } else if ( mangopayOnboardingItem.value.legalUser.legalUserType == 'ORGANIZATION') {
              isEntityTypeBusiness.value = false // Business
              isEntityTypeOrganisation.value = true // Organisation
              isEntityTypeSoleProprietorship.value = false // Sole proprietorship
            } else if ( mangopayOnboardingItem.value.legalUser.legalUserType == 'SOLETRADER') {
              isEntityTypeBusiness.value = false // Business
              isEntityTypeOrganisation.value = false // Organisation
              isEntityTypeSoleProprietorship.value = true // Sole proprietorship
            }
          }
        }
        // Else just use the info from the Efty Pay accountonboardingType to populate the form fields
        else if (otpUserProfile.value) {
          formPrefilledValues.value = {
            'email': otpUserProfile.value.email,
            'firstName': otpUserProfile.value.firstName,
            'lastName': otpUserProfile.value.lastName,
            'onboardingType': 'NATURAL_USER',
            'countryOfResidence': otpUserProfile.value.companyAddress ? otpUserProfile.value.companyAddress.country : ''
          }
        }
      }
      return true
    }

    // expose to template and other options API hooks
    return {
      onboardingStep,
      onboardingStatusEnum,
      onboardingStatusList,
      gotoPreviousOnboardingStep,
      gotoNextOnboardingStep,
      addBeneficialOwner,
      addOnboardingDocument,
      getDocumentType,
      getDocumentRefusalReason,
      removePageFromDocument,
      root,
      submitForm,
      addPageToDocument,
      availableOnboardingSteps,
      onIsLegalEntityChange,
      onCompanyTypeChange,
      uploadFile,
      uploadExtraPage,
      legalRepresentatives,
      beneficialOwners,
      onDocumentTypeChange,
      selectedDocumentType,
      getDocumentStatus,
      isLegalEntity,
      isEntityTypeBusiness,
      isEntityTypeOrganisation,
      isEntityTypeSoleProprietorship,
      onboardingDocumentTypeList,
      otpUserProfile,
      isoCountriesList,
      mangopayOnboardingError,
      mangopayOnboardingItem,
      mangopayRequiredDocuments,
      onboardingDocuments,
      dayjs,
      disableUserInput,
      formPrefilledValues,
      removeBeneficialOwner,
      onboardingDocumentExtraPages,
      isLoading,
      onboardingDocumentTypeAllowedUploadList,
      getAllowedIdDocumentTypes,
      getDocumentDescription,
      getDocumentTypeStatus,
      getMostRelevantDocumentByType,
      scrollToDocumentType,
      previousOnboardingStepExists
    }

  }

}
</script>
