import { ref } from 'vue'
import {onboardingStatusList} from "@/utils/config.js";

const onboardingStep = ref(0)
const isLegalEntity = ref(false)
const isEntityTypeBusiness = ref(false)
const isEntityTypeOrganisation = ref(false)
const isEntityTypeSoleProprietorship = ref(false)

const onboardingStatus = ref(0)
const onboardingStatusError = ref(false)

// Get current onboarding step
const getCurrentOnboardingStep = async ( onboardingStatusEnum ) => {

    // Reset
    onboardingStep.value = false

    if ( onboardingStatusEnum ) {

        onboardingStatusList.value.forEach( function( onboardingStatus ) {

            if (onboardingStatus.enum == onboardingStatusEnum) {
                onboardingStep.value = onboardingStatus.name
            }

        });

    }

}

// Get onboarding status
const getOnboardingStatus = async ( epUserProfile ) => {

    onboardingStatus.value = 0 // Needs to start onboarding

    if (epUserProfile.paymentDetails) {

        if (epUserProfile.paymentDetails.mangopayDetails) {

            if (epUserProfile.paymentDetails.mangopayDetails.onboardAsSellerWithMangopay == true && !epUserProfile.paymentDetails.mangopayDetails.kycLevel) {

                onboardingStatus.value = 0 // Needs to start onboarding

            } else if (epUserProfile.paymentDetails.mangopayDetails.kycLevel == 'LIGHT') {

                onboardingStatus.value = 1 // Onboarding started, needs to complete KYC/KYB

            } else if (epUserProfile.paymentDetails.mangopayDetails.kycLevel == 'REGULAR') {

                onboardingStatus.value = 2 // KYC/KYB completed

            } else if (epUserProfile.paymentDetails.mangopayDetails.onboardingStatus == 'DECLINED') {

                onboardingStatusError.value = true // Errors in KYC/KYB

            } else {

                onboardingStatus.value = 0 // Fallback, (probably) needs to start onboarding

            }
        }

    } else {

        onboardingStatus.value = 0 // Needs to start onboarding

    }

    return true

}

export {

    getCurrentOnboardingStep,
    getOnboardingStatus,
    onboardingStatus,
    onboardingStatusError,
    onboardingStep,
    isLegalEntity,
    isEntityTypeBusiness,
    isEntityTypeOrganisation,
    isEntityTypeSoleProprietorship

}