<template>
  <NavigationSection />
  <LoaderSection v-show="isLoading" />
  <main>
    <div class="main-content-wrapper w-100">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2 bg-white rounded" id="mainContent">

            <div class="main-content pt-4 px-0 px-lg-3">

              <div class="w-100 mb-4 border-bottom pb-3">
                <h1 class="fw-bold h3 text-dark"><font-awesome-icon :icon="['fas', 'chart-line']" />Dashboard</h1>
              </div>

              <div>

                <div class="container">

                  <div class="row">

                    <div class="col-12 col-lg-6">

                      <h4 class="fw-bold h4 text-dark mb-4 mt-2"><font-awesome-icon :icon="['far', 'fa-compass']" class="text-dark me-2"/>Recent transactions</h4>

                      <!-- Error with transactions -->
                      <div v-if="transactionError" class="pb-3">
                        <ErrorMessage />
                      </div>

                      <!-- No Transactions found -->
                      <div v-else-if="!transactionList.length" class="pb-3 text-center">
                        <img src="@/assets/204.png" class="img-fluid">
                        <h4 class="fw-bold h5">No transactions found..</h4>
                      </div>

                      <!-- Transactions found -->
                      <div class="row" v-else-if="transactionList.length">
                        <div class="col-12 col-xl-12 col-xxl-12" v-for="transactionItem in transactionList" :key="transactionItem.id">
                          <div class="card bg-light border-0 mb-2">
                            <div class="card-body">
                              <div class="clearfix my-auto mb-2">

                                <!-- Display icon if user needs to perform an action -->
                                <component :is="componentInteractionMap[transactionItem.id]" v-if="componentInteractionMap[transactionItem.id]" class=" float-start me-2"><font-awesome-icon :icon="['fas', 'circle-exclamation']" class="text-danger h5 m-0 ms-1"/></component>

                                <h5 class="card-title text-dark fw-bold float-start m-0">{{transactionItem.digitalAsset.domain.domainName}}</h5>

                                <span class="mt-1 float-start ms-2 badge display-4 bg-success">{{ getTransactionDeepStatusMapping(transactionItem.status, transactionItem.seller.id == otpUserId ? 'seller' : 'buyer').title }}</span>

                              </div>
                              <p class="card-subtitle mt-0 mb-2 text-muted">{{ parseCurrency( transactionItem.currency , transactionItem.assetAmountExcVat/100) }}</p>
                              <router-link :to="{ name: 'publicTransaction', params: { transactionId: transactionItem.id } }" class="btn btn-outline-dark">
                                View transaction
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="col-12 col-lg-6">

                      <!-- Onboarding status (only if necc) -->
                      <div class="card bg-light" v-if="otpUserProfile && otpUserProfile.paymentDetails && otpUserProfile.paymentDetails.mangopayDetails && otpUserProfile.paymentDetails.mangopayDetails.onboardAsSellerWithMangopay">
                        <div class="card-body">
                          <h4 class="fw-bold h4 mb-4"><font-awesome-icon :icon="['far', 'fa-lightbulb']" class="text-dark me-2"/>Onboarding status</h4>

                          <div class="container">

                            <div class="row">
                              <div class="col-2 col-lg-1 my-auto">
                                <font-awesome-icon :icon="['fas', 'fa-circle-check']" class="fw-bold text-success me-2 lead" />
                              </div>
                              <div class="col-10 col-lg-11">
                                <span class="text-dark lead">Efty Investor account linked</span>
                              </div>
                            </div>

                            <div class="row mt-3">
                              <div class="col-2 col-lg-1 my-auto">
                                <font-awesome-icon :icon="['fas', 'fa-circle-check']" class="lead me-2 text-grey" v-if="onboardingStatus == 0"/>
                                <font-awesome-icon :icon="['fas', 'fa-circle-check']" class="fw-bold lead text-success me-2" v-else/>
                              </div>
                              <div class="col-10 col-lg-11">
                                <span class="lead" :class="{ 'text-grey' : onboardingStatus == 0, 'text-dark' : onboardingStatus > 0 }">Ready to sell domains</span>
                              </div>
                            </div>

                            <div class="row mt-3">
                              <div class="col-2 col-lg-1 my-auto">
                                <font-awesome-icon :icon="['fas', 'fa-circle-check']" class="lead me-2 text-grey" v-if="onboardingStatus < 2"/>
                                <font-awesome-icon :icon="['fas', 'fa-circle-check']" class="lead fw-bold text-success me-2" v-else/>
                              </div>
                              <div class="col-10 col-lg-11">
                                <span class="lead" :class="{ 'text-grey' : onboardingStatus < 2, 'text-dark' : onboardingStatus >= 2 }">Ready to receive pay-outs</span>
                              </div>
                            </div>

                            <div class="row mt-3">
                              <div class="col-2 col-lg-1 my-auto">
                                <font-awesome-icon :icon="['fas', 'fa-circle-check']" class="lead me-2 text-grey" v-if="mangopayOnboardingBankAccountItem.active !== true"/>
                                <font-awesome-icon :icon="['fas', 'fa-circle-check']" class="lead fw-bold text-success me-2" v-else/>
                              </div>
                              <div class="col-10 col-lg-11">
                                <span class="lead" :class="{ 'text-grey' : mangopayOnboardingBankAccountItem.active !== true, 'text-dark' : mangopayOnboardingBankAccountItem.active === true }">Bank account linked</span>
                              </div>
                            </div>

                          </div>

                          <router-link :to="{ name: 'publicGetStarted' }" class="btn btn-lg btn-success mt-3 w-100" v-if="onboardingStatus == 0 || onboardingStatus == 1"><font-awesome-icon :icon="['fas', 'chevron-right']" class="fw-bold lead m-0 me-2"/>
                            <span v-if="onboardingStatus == 0" class="text-white">Complete seller onboarding</span>
                            <span v-if="onboardingStatus == 1" class="text-white">Complete financial onboarding</span>
                          </router-link>

                          <router-link :to="{ name: 'publicSettings' }" class="btn btn-lg btn-success mt-3 w-100" v-else-if="onboardingStatus == 2 && mangopayOnboardingBankAccountItem.active !== true" ><font-awesome-icon :icon="['fas', 'chevron-right']" class="fw-bold lead m-0 me-2"/>
                            <span class="text-white">Link bank account</span>
                          </router-link>

                        </div>
                      </div>
                    </div>

                  </div>

                  <hr class="d-none">

                  <div class="row mt-4 pb-4 d-none">

                    <div class="col-12 col-lg-4">

                      <!-- Revenue stats -->
                      <div class="card bg-light">
                        <div class="card-body">
                          <h3 class="fw-bold h3 mb-3">Revenue</h3>
                          <div class="row mb-2">
                            <div class="col-6">
                              <span class="fw-bold mb-0">Overall</span>
                            </div>
                            <div class="col-6 text-end">
                              <span>{{ parseCurrency('EUR', 0) }}</span>
                            </div>
                          </div>
                          <div class="row mb-2">
                            <div class="col-6">
                              <span class="fw-bold mb-0">This month</span>
                            </div>
                            <div class="col-6 text-end">
                              <span>{{ parseCurrency('EUR', 0) }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-lg-4">

                      <!-- Transaction stats -->
                      <div class="card bg-light">
                        <div class="card-body">
                          <h3 class="fw-bold h3 mb-3">New transactions</h3>
                          <div class="row mb-2">
                            <div class="col-6">
                              <span class="fw-bold mb-0">This month</span>
                            </div>
                            <div class="col-6 text-end">
                              <span>0</span>
                            </div>
                          </div>
                          <div class="row mb-2">
                            <div class="col-6">
                              <span class="fw-bold mb-0">This month</span>
                            </div>
                            <div class="col-6 text-end">
                              <span>{{ parseCurrency('EUR', 0) }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>

                  <hr>

                  <div class="row mt-4 pb-4">

                    <div class="col-12 col-md-6 col-lg-4 pb-3 pb-lg-0">
                      <div class="card border-0 bg-white h-100">
                        <div class="w-100 text-center">
                          <img src="@/assets/blog/1.jpg" class="card-img-top blog-img w-100" alt="">
                        </div>
                        <div class="card-body border border-top-0 rounded-bottom">
                          <h5 class="card-title fw-bold text-dark">Buying domains made easy</h5>
                          <p class="card-text pb-3">This guide outlines the simple and secure process of purchasing domain names from Efty.com.</p>
                          <a href="https://efty.com/knowledge/buying-domains-made-easy/" target="blank" class="position-absolute bottom-0 pb-3">Read more</a>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 pb-3 pb-lg-0">
                      <div class="card border-0 bg-white h-100">
                        <div class="w-100 text-center">
                          <img src="@/assets/blog/2.jpg" class="card-img-top blog-img w-100" alt="">
                        </div>
                        <div class="card-body border border-top-0 rounded-bottom">
                          <h5 class="card-title fw-bold text-dark">The Power of the Perfect Domain Name</h5>
                          <p class="card-text pb-3">Explore why your business needs the perfect web domain name.</p>
                          <a href="https://efty.com/knowledge/the-power-of-the-perfect-domain-name/" target="blank" class="position-absolute bottom-0 pb-3">Read more</a>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 pb-3 pb-lg-0">
                      <div class="card border-0 bg-white h-100">
                        <div class="w-100 text-center">
                          <img src="@/assets/blog/3.jpg" class="card-img-top blog-img w-100" alt="">
                        </div>
                        <div class="card-body border border-top-0 rounded-bottom">
                          <h5 class="card-title fw-bold text-dark">Topnotch support when you need it</h5>
                          <p class="card-text pb-3">Our support team is available to help you. You can count on our team of experts.</p>
                          <a href="https://efty.com/support/" target="blank" class="position-absolute bottom-0 pb-3 pt">Get in touch</a>
                        </div>
                      </div>

                    </div>

                  </div>

                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
      <FooterSection />

      </div>

  </main>

</template>

<script>
import FooterSection from '@/components/public/Footer.vue'
import NavigationSection from "@/components/public/Navigation.vue";
import { getTransactionList, transactionList, transactionError } from "@/utils/transactions.public";
import { getOnboardingStatus, onboardingStatus, onboardingStatusError} from "@/utils/onboarding";
import { parseCurrency } from "@/utils/support";
import { eftyIntegratorId } from "@/utils/config";
import ErrorMessage from "@/components/public/ErrorMessage.vue";
import { isLoading } from "@/utils/support";
import {getOtpUserProfile, otpUserProfile, otpUserId} from "@/utils/magiclink";
import {ref, watch} from "vue";
import LoaderSection from "@/components/public/LoadingSpinner.vue";
import {getRegistrarStatus, getTransactionDeepStatusMapping} from "@/utils/transactions.common";
import {getDeepInteractionWidgetComponent} from "@/utils/interactionWidget";
import {
  getMangopayOnboardingBankAccount,
  mangopayOnboardingBankAccountItem
} from "@/utils/mangopay.onboarding.public";

export default {

  name: 'PublicHomeView',

  setup() {

    const componentInteractionMap = ref({});

    watch(otpUserProfile, context => {

      if (context) {
        getOnboardingStatus( context )
      }

      getMangopayOnboardingBankAccount(otpUserId.value, eftyIntegratorId)

    });

    // Function used to map transactionItems in a list and check if there are interaction components attached to it
    const fetchComponentForRecord = async (transactionItem) => {

      // Check if EP has a holding account with the registrar
      const registrarStatus = getRegistrarStatus(transactionItem);

      // Get an interaction widget, if any
      const response = await getDeepInteractionWidgetComponent(transactionItem.status, transactionItem.seller.id == otpUserId.value ? 'seller' : 'buyer', registrarStatus, transactionItem.metaData)

      // Set it into the array with transaction IDs
      if (response) {
        componentInteractionMap.value[transactionItem.id] = response.interactionComponent;
      }
    };

    // Load transactions
    getTransactionList(eftyIntegratorId, 3).then( () => {

      // For each transaction, check if there are interactive components
      transactionList.value.forEach(record => {
        fetchComponentForRecord(record);
      });

    })

    // Get profile for OTP logged in user
    getOtpUserProfile()

    // expose to template and other options API hooks
    return {
      transactionList,
      parseCurrency,
      onboardingStatus,
      onboardingStatusError,
      transactionError,
      otpUserProfile,
      isLoading,
      otpUserId,
      getTransactionDeepStatusMapping,
      componentInteractionMap,
      mangopayOnboardingBankAccountItem
    }

  },
  components: { ErrorMessage,  FooterSection, NavigationSection, LoaderSection }
}
</script>
