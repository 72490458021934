<template>
  <LoaderSection v-show="isLoading" />
  <NavigationSection />
  <main>
    <div class="main-content-wrapper w-100">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2 bg-white rounded" id="mainContent">

            <div class="main-content p-3 pt-4">

              <div class="w-100 mb-4 border-bottom pb-3">
                <h1 class="fw-bold h3 text-dark"><font-awesome-icon :icon="['fas', 'chart-line']" />Dashboard</h1>
              </div>

              <div>
                <div class="container">



                  <h4 class="fw-bold h4 text-dark mt-4 mb-4"><font-awesome-icon :icon="['far', 'fa-compass']" class="text-dark me-2"/>Newest transactions</h4>

                  <!-- Error with transactions -->
                  <div v-if="transactionError" class="pb-3">
                    <ErrorMessage />
                  </div>

                  <!-- No Transactions found -->
                  <div v-else-if="!transactionList.length" class="pb-3">
                    <img src="@/assets/204.png" class="img-fluid">
                    <h4 class="fw-bold">No transactions found..</h4>
                  </div>

                  <!-- Transactions found -->
                  <div class="row pb-4" v-else-if="transactionList.length">
                    <div class="col-12 col-xl-6 col-xxl-4" v-for="transactionItem in transactionList" :key="transactionItem.id">
                      <div class="card bg-light border-0 mb-2">
                        <div class="card-body">
                          <div class="clearfix my-auto mb-2">
                            <h5 class="card-title text-dark fw-bold float-start m-0">{{transactionItem.digitalAsset.domain.domainName}}</h5><span class="mt-1 float-start ms-2 badge display-4 bg-success">{{ getTransactionDeepStatusMapping(transactionItem.status, 'admin').title }}</span>
                          </div>
                          <p class="card-subtitle mt-0 mb-2 text-muted">{{ parseCurrency( transactionItem.currency , transactionItem.assetAmountExcVat/100) }}</p>
                          <router-link :to="{ name: 'adminTransaction', params: { transactionId: transactionItem.id } }" class="btn btn-outline-dark">View transaction</router-link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr>

                  <div class="row mt-5">

                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="card border-0">
                        <div class="w-100 text-center bg-sand">
                          <img src="@/assets/blog/1.jpg" class="card-img-top blog-img w-100" alt="">
                        </div>
                        <div class="card-body border border-top-0 rounded-bottom">
                          <h5 class="card-title fw-bold text-dark">Buying domains made easy</h5>
                          <p class="card-text">This guide outlines the simple and secure process of purchasing domain names from Efty.com.</p>
                          <a href="https://efty.com/knowledge/buying-domains-made-easy/" target="blank" class="">Read more</a>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="card border-0">
                        <div class="w-100 text-center">
                          <img src="@/assets/blog/2.jpg" class="card-img-top blog-img w-100" alt="">
                        </div>
                        <div class="card-body border border-top-0 rounded-bottom">
                          <h5 class="card-title fw-bold text-dark">The Power of the Perfect Domain Name</h5>
                          <p class="card-text">Explore why your business needs the perfect web domain name.</p>
                          <a href="https://efty.com/knowledge/buying-domains-made-easy/" target="blank" class="">Read more</a>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="card border-0">
                        <div class="w-100 text-center bg-eftypay-success">
                          <img src="@/assets/blog/3.jpg" class="card-img-top blog-img w-100" alt="">
                        </div>
                        <div class="card-body border border-top-0 rounded-bottom">
                          <h5 class="card-title fw-bold text-dark">Topnotch support when you need it</h5>
                          <p class="card-text">Our support team is available to help you. You can count on our team of experts.</p>
                          <a href="https://efty.com/knowledge/buying-domains-made-easy/" target="blank" class="">Get in touch</a>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
      <FooterSection />

    </div>

  </main>

</template>

<script>
import FooterSection from '../../components/admin/Footer.vue'
import NavigationSection from "@/components/admin/Navigation.vue";
import LoaderSection from "@/components/admin/LoadingSpinner.vue";
import ErrorMessage from '@/components/admin/ErrorMessage.vue'
import {
  getTransactionList,
  transactionList,
  transactionError
} from "@/utils/transactions.admin";
import {
  getTransactionDeepStatusMapping
} from "@/utils/transactions.common";
import {
  parseCurrency, isLoading
} from "@/utils/support";
import {
  eftyIntegratorId
} from "@/utils/config";

export default {

  name: 'AdminHomeView',
  setup() {

    // Load transactions
    getTransactionList( eftyIntegratorId, 0, 6 )

    // expose to template and other options API hooks
    return {
      transactionList,
      parseCurrency,
      transactionError,
      isLoading,
      getTransactionDeepStatusMapping
    }

  },
  components: { LoaderSection, FooterSection, NavigationSection, ErrorMessage }
}
</script>
