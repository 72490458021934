import axios from 'axios'
import { apiEndpoint } from "@/utils/config";
import {ref} from "vue";
import { useRouter } from 'vue-router'
import {router} from "@/router";
import { useOtpStore } from '@/stores/otp'
import {useUserStore} from "@/stores/user";
import {useIntegratorStore} from "@/stores/integrator";
import { isLoading } from "@/utils/support"

const magicLinkIsVerified = ref(0)
const otpIsVerified = ref(0)
const otpUserId = ref(0)
const otpUserProfile = ref(0)

const otpSignOut = async () => {

    // Remove OTP token from local storage
    const otpStore = useOtpStore()
    otpStore.update('')


}

const magicLinkGuard = () => {

    const otpStore = useOtpStore()
//console.log(otpStore.otpToken)
    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${otpStore.otpToken}`
    }

    if (otpStore.otpToken != '' && otpStore.otpToken != null) {

        axios.get(apiEndpoint.value + 'users/profile', {
                headers: axiosHeaders
            }
        ).then(response => {

            if (response){
                // Keep this
            }
            return true

        }).catch(error => {

            if (error) {
                // Do something
            }

            // Redirect to public lander page
            router.push({ name: 'publicLander', replace: true })

            return false

        });

    } else {

        // Redirect to 401 page
        router.push({ name: 'publicLander', replace: true })

    }

}

const verifyMagicLink = async (encryptedData,iv,keyId) => {

    const otpStore = useOtpStore()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${otpStore.otpToken}`
    }

    if (encryptedData && iv && keyId) {

        // Reset
        magicLinkIsVerified.value = false

        //const router = useRouter()

        const data = {      'encryptedData': encryptedData,
                            'iv': iv,
                            'keyId': keyId };

        axios
            .post(apiEndpoint.value + 'transactions/public/magiclink/verify', data,{

                    headers: axiosHeaders

                }
            ).then(response => {


                if (response) {
                    // Do something
                }
                magicLinkIsVerified.value = true

            }).catch(error => {

                if (error) {
                    // Do something
                }
                magicLinkIsVerified.value = false

                // Redirect
                router.push({ name: 'publicMagicLinkInvalid' })

            })

    }

}

const verifyOtp = async (encryptedData,iv,keyId,otp) => {

    const otpStore = useOtpStore()
    const userStore = useUserStore()
    const integratorStore = useIntegratorStore()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${otpStore.otpToken}`
    }

    if (encryptedData && iv && keyId && otp) {

        // Reset
        otpIsVerified.value = false

        const router = useRouter()

        const data = {
            'encryptedData': encryptedData,
            'iv': iv,
            'keyId': keyId,
            'otp': otp};

        axios
            .post(apiEndpoint.value + 'transactions/public/magiclink/otp/authenticate', data,{
                    headers: axiosHeaders
                }
            ).then(response => {

            // Set token in local storage
            otpStore.update(response.data.value)

            // Set token in local storage
            integratorStore.update(iv)

            // Get user ID
            axios.get(apiEndpoint.value + 'users/profile', { headers: axiosHeaders }
            ).then(response => {

                // Set token in local storage
                userStore.update(response.data.id)

            }).catch(error => {

                if (error) {
                    // Do something
                }

            });

            // Redirect to homepage
            router.push({ name: 'publicHome' })

        }).catch(error => {


            if (error) {
                // Do something
            }
            otpIsVerified.value = false

            // @Todo: Remove existing token in local storage
            //localStorage.setItem('otpToken', response.data.value)

            // Redirect to 403 page
            router.push({ name: 'publicOtpInvalid' })

        })

    }

}

// Get content for a specific transaction
const getOtpUserProfile = async (  ) => {

    // Activate loading spinner
    isLoading.value = true

    const otpStore = useOtpStore()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${otpStore.otpToken}`
    }

    otpUserProfile.value = false

    axios.get(apiEndpoint.value + 'users/profile', {
            headers: axiosHeaders
        }
    ).then(response => {

        isLoading.value = false
        otpUserProfile.value = response.data
    }).catch(error => {


        isLoading.value = false
        if (error.response.status == 500 || error.response.status == 403) { // @Todo change to 403 only after API fix

            if (error) {
                // Do something
            }
            // Redirect to 403 page (work around vue router bc this function is called from within the router)
            //window.location.href = '/#/public/403/';

        }

    });

}

// Get content for a specific transaction
const getOtpUserId = (  ) => {

    // Activate loading spinner
    isLoading.value = true

    const otpStore = useOtpStore()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${otpStore.otpToken}`
    }

    otpUserId.value = false

    axios.get(apiEndpoint.value + 'users/profile', {
            headers: axiosHeaders
        }
    ).then(response => {

        isLoading.value = false
        otpUserId.value = response.data.id
    }).catch(error => {

        isLoading.value = false

        if (error.response.status == 500 || error.response.status == 403) { // @Todo change to 403 only after API fix

            if (error) {
                // Do something
            }
            // Redirect to 403 page (work around vue router bc this function is called from within the router)
            //window.location.href = '/#/public/403/';

        }

    });

}
export {
    getOtpUserProfile,
    getOtpUserId,
    magicLinkGuard,
    verifyMagicLink,
    verifyOtp,
    otpSignOut,
    magicLinkIsVerified,
    otpIsVerified,
    otpUserId,
    otpUserProfile
}