import { defineStore } from 'pinia'

export const useAuth0Store = defineStore('auth0Token', {

    state: () => {
        return { auth0Token: localStorage.getItem('auth0Token') }
    },
    actions: {
        update(auth0Token) {
            this.auth0Token = auth0Token
            localStorage.setItem('auth0Token', this.auth0Token)
        },
    },
})