<template>
  <footer class="fixed-xl-bottom py-4 w-100 bg-header h-auto">

      <div class="container-fluid">

        <div class="row mt-2 text-center">

          <div class="col-12">

            <p class="text-white mb-0">Copyright © 2023 - &copy; {{new Date().getFullYear()}} Efty Pay B.V. Groningen, The Netherlands. All rights reserved.</p>

          </div>

        </div>

      </div>
  </footer>
</template>
<script>
export default {
  name: 'FooterLightSection',
};
</script>