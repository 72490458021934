<template>
  <LoaderSection v-show="isLoading" />
  <NavigationSection />
  <main ref="root">
    <div class="main-content-wrapper w-100">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2 bg-white rounded" id="mainContent">
            <div class="main-content p-3 pt-4">
              <div class="clearfix my-auto">
                <div class="row">
                  <div class="col-4 my-auto">
                    <h1 class="fw-bold h3 text-dark float-start"><font-awesome-icon :icon="['far', 'fa-compass']" />Transactions</h1>
                  </div>
                  <div class="col-8 my-auto">
                    <form class="float-end" method="get" action="" id="integratorForm">
                      <div class="form-floating">
                        <select class="form-select" id="integratorId" name="integratorId" required>
                          <option value="1WTManOJBP7EKsy2TElo7I" selected="selected">Efty Investor</option>
                        </select>
                        <label for="environment">Integrator</label>
                      </div>
                    </form>
                    <form class="float-end me-3" method="get" action="" id="integratorForm" @change="processDomainSearch()">
                      <div class="form-floating">
                        <select class="form-select" id="transactionFilters" name="transactionFilters" ref="transactionFilters" v-model="filterBox">
                          <optgroup label="General filters">
                            <option value="all" selected="selected">All transactions</option>
                            <option value="withBuyer">Transactions with buyer</option>
                          </optgroup>
                          <optgroup label="Admin actions">
                            <option value="actionAdminConfirmPaymentToEftyPay">Confirm payment to Efty Pay</option>
                            <option value="actionAdminRequestTransferToEftyPay">Request transfer to Efty Pay</option>
                            <option value="actionAdminApproveTransferToEftyPay">Approve transfer to Efty Pay</option>
                            <option value="actionAdminInitiateTransferToBuyer">Initiate transfer to buyer</option>
                            <option value="actionAdminSendAuthCodeToBuyer">Send auth code to buyer</option>
                            <option value="actionAdminApproveTransferInFromBuyer">Approve transfer-in from buyer</option>
                            <option value="actionAdminInitiatePayoutToSeller">Initiate payout to seller</option>
                            <option value="actionAdminConfirmPayoutToSeller">Confirm payout to seller</option>
                            <option value="actionAdminApprovePayoutToSeller">Approve payout to seller</option>
                            <option value="actionAdminCompleteTransaction">Complete transaction</option>
                          </optgroup>
                        </select>
                        <label for="transactionFilters">Filters</label>
                      </div>
                    </form>
                    <form class="float-end me-3" id="domainSearchForm" @submit.prevent="processDomainSearch">
                      <div class="form-floating">
                        <input type="text" class="form-control" name="searchDomain" id="searchDomain" ref="searchDomain">
                        <label for="searchDomain">Search domain name</label>
                      </div>
                    </form>

                  </div>
                </div>
              </div>
              <div class="bg-white border p-3 mt-4">

                <div class="table-responsive">
                  <table class="table table-responsive table-borderless bg-white">
                    <thead class="">
                    <tr>
                      <th scope="col" width="20%" class="text-muted fw-bold lead bg-white">Domain</th>
                      <th scope="col" width="10%" class="text-muted fw-bold lead bg-white">Price</th>
                      <th scope="col" width="5%" class="text-muted fw-bold lead bg-white">Buyer</th>
                      <th scope="col" width="5%" class="text-muted fw-bold lead bg-white">Seller</th>
                      <th scope="col" width="5%" class="text-muted fw-bold lead bg-white">Date</th>
                      <th scope="col" width="55%" class="text-muted fw-bold lead bg-white">Status</th>
                    </tr>
                    <tr>
                      <td colspan="6" class=" bg-white"></td>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-if="!transactionList && !transactionError">
                          <td colspan="6" class=" bg-white"><ContentPlaceholder /></td>
                        </tr>
                        <tr v-else-if="transactionError">
                          <td colspan="6" class=" bg-white">
                            <ErrorMessage />
                          </td>
                        </tr>
                        <tr v-else-if="!transactionList.length">
                          <td colspan="6" class="text-center bg-white">
                            <img src="@/assets/notfound.png" class="img-fluid ">
                            <div class="w-100 text-center">
                              <font-awesome-icon :icon="['fas', 'xmark']" class="me-2" />No transactions found
                            </div>
                          </td>

                        </tr>
                        <tr v-for="transactionItem in transactionList" :key="transactionItem.id">
                          <td class=" my-auto bg-white">
                            <router-link :to="{ name: 'adminTransaction', params: { transactionId: transactionItem.id } }" title="View transaction">

                              <!-- Display icon if admin needs to perform an action -->
                              <component :is="componentInteractionMap[transactionItem.id]" v-if="componentInteractionMap[transactionItem.id]" class="me-2"><font-awesome-icon :icon="['fas', 'circle-exclamation']" class="text-danger h5 m-0"/></component>

                              <span class="text-success fw-bold lead p-o m-0 w-100">{{transactionItem.digitalAsset.domain.domainName}}</span><br>
                              <span class="" v-if="transactionItem.utmParameters"><font-awesome-icon :icon="['fas', 'asterisk']" class="me-2" />{{ transactionItem.utmParameters.utmSource }}</span>
                            </router-link>
                          </td>
                          <td class=" lead my-auto bg-white">
                            <router-link :to="{ name: 'adminTransaction', params: { transactionId: transactionItem.id } }" title="View transaction">
                              {{ parseCurrency( transactionItem.currency , transactionItem.assetAmountExcVat/100) }}
                            </router-link>
                          </td>
                          <td class=" my-auto text-center bg-white">
                            <router-link :to="{ name: 'adminTransaction', params: { transactionId: transactionItem.id } }" title="View transaction">
                              <span class="bg-buyer text-white rounded px-2 py-1 lead" v-if="transactionItem.buyer">{{showInitials(transactionItem.buyer.firstName, transactionItem.buyer.lastName).toUpperCase()}}</span>
                            </router-link>
                          </td>
                          <td class=" my-auto text-center bg-white">
                            <router-link :to="{ name: 'adminTransaction', params: { transactionId: transactionItem.id } }" title="View transaction">
                              <span class="bg-seller text-white rounded px-2 py-1 lead" v-if="transactionItem.seller">{{showInitials(transactionItem.seller.firstName, transactionItem.seller.lastName).toUpperCase()}}</span>
                            </router-link>
                          </td>
                          <td class="lead my-auto bg-white">
                            <router-link :to="{ name: 'adminTransaction', params: { transactionId: transactionItem.id } }" title="View transaction">
                              {{dayjs(transactionItem.created).format('ddd MMMM D, YYYY')}}
                            </router-link>
                          </td>
                          <td class="lead my-auto bg-white no-max-width">
                            <router-link :to="{ name: 'adminTransaction', params: { transactionId: transactionItem.id } }" title="View transaction">

                              <!-- Different status for buyer and seller, show both -->

                              <span class="d-block">
                                <font-awesome-icon :icon="['fas', 'square']" class="me-2 text-buyer" /><span class="">{{getTransactionDeepStatusMapping(transactionItem.status, 'buyer').title}}</span>
                              </span>
                              <span class="d-block">
                                <font-awesome-icon :icon="['fas', 'square']" class="me-2 text-seller" /><span class="">{{getTransactionDeepStatusMapping(transactionItem.status, 'seller').title}}</span>
                              </span>


                            </router-link>
                          </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
                <hr>
                <nav aria-label="Paging" class="">
                  <ul class="pagination justify-content-end">
                    <li class="page-item"><a class="page-link bg-grey" :class="{ 'disabled bg-grey': pageNumber <= 1, 'bg-white': pageNumber >1 }" @click.prevent="previousPage()" href="#"><font-awesome-icon :icon="['fas', 'angle-left']" /></a></li>
                    <li class="page-item"><a class="page-link disabled bg-white" href="#">Page {{ pageNumber }}</a></li>
                    <li class="page-item"><a class="page-link bg-white" @click.prevent="nextPage()" href="#"><font-awesome-icon :icon="['fas', 'angle-right']" /></a></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterSection />
    </div>
  </main>
</template>

<script>
import FooterSection from '@/components/admin/Footer.vue'
import LoaderSection from '@/components/admin/LoadingSpinner.vue'
import NavigationSection from "@/components/admin/Navigation.vue";
import ContentPlaceholder from '@/components/admin/ContentPlaceholder.vue'
import ErrorMessage from '@/components/admin/ErrorMessage.vue'
import {
  getTransactionList,
  filterTransactions,
  transactionList,
  transactionItem,
  activeFilter,
  transactionStatusList,
  transactionError
} from "@/utils/transactions.admin";
import {
  getTransactionDeepStatusMapping, getRegistrarStatus
} from "@/utils/transactions.common";
import {
  showInitials, parseCurrency, isLoading
} from "@/utils/support";
import {
  eftyIntegratorId
} from "@/utils/config";
import dayjs from 'dayjs';
import { ref } from 'vue'
import {getDeepInteractionWidgetComponent} from "@/utils/interactionWidget";

export default {

  setup() {

    const componentInteractionMap = ref({});
    const searchDomain = ref('');
    const transactionFilters = ref('');
    const pageNumber = ref(1);
    const lastTransactionId = ref(null);
    const firstTransactionIds = ref([]); // Array to store first transaction IDs

    const loadTransactions = (searchValue = false, filterType = false, startAfter = false) => {

      // Load transactions
      getTransactionList(eftyIntegratorId, searchValue, 10, startAfter, filterType).then(() => {

        // Check if there are transactions in the list
        if (transactionList.value) {

          // Process each transaction (e.g., fetch related components)
          transactionList.value.forEach(record => {
            fetchComponentForRecord(record);
          });

          // If loading the first page, store the first ID
          if (pageNumber.value === 1 && transactionList.value.length) {
            firstTransactionIds.value[pageNumber.value - 1] = transactionList.value[0].id;
          }

          // Update lastTransactionId with the last item in the list
          setLastItemInList();
        }
      });
    };

    const setLastItemInList = () => {
      // Set the last transaction ID
      lastTransactionId.value = transactionList.value.length ? transactionList.value[transactionList.value.length - 1].id : null;
    };

    const processDomainSearch = () => {
      // Reset to page 1
      pageNumber.value = 1;
      firstTransactionIds.value = []; // Clear the stored IDs when starting a new search

      // Load transactions for the first page
      loadTransactions(searchDomain.value.value, transactionFilters.value.value, false);
    };

    const nextPage = () => {
      if (lastTransactionId.value) {
        // Store the last transaction ID of the current page before moving forward
        firstTransactionIds.value[pageNumber.value - 1] = lastTransactionId.value;

        // Increment the page number
        pageNumber.value++;

        // Load the next page with the last ID of the current page as startAfter
        loadTransactions(searchDomain.value.value, transactionFilters.value.value, lastTransactionId.value);
      }
    };

    const previousPage = () => {
      if (pageNumber.value > 1) {
        // Decrement the page number
        pageNumber.value--;

        // Use the saved last ID of the previous page
        const startAfterId = firstTransactionIds.value[pageNumber.value - 2] || null;

        // Load the previous page using the saved startAfter ID
        loadTransactions(searchDomain.value.value, transactionFilters.value.value, startAfterId);
      }
    };

    // Function used to map transactionItems in a list and check if there are interaction components attached to it
    const fetchComponentForRecord = async (transactionItem) => {

      // Check if EP has a holding account with the registrar
      const registrarStatus = getRegistrarStatus(transactionItem);

      // Get an interaction widget, if any
      const response = await getDeepInteractionWidgetComponent(transactionItem.status, 'admin', registrarStatus)

      // Set it into the array with transaction IDs
      if (response) {
        componentInteractionMap.value[transactionItem.id] = response.interactionComponent;
      }
    };

    // Initial load
    loadTransactions();

    // expose to template and other options API hooks
    return {
      transactionList,
      transactionItem,
      filterTransactions,
      activeFilter,
      transactionStatusList,
      transactionError,
      showInitials,
      dayjs,
      parseCurrency,
      isLoading,
      getTransactionDeepStatusMapping,
      componentInteractionMap,
      processDomainSearch,
      searchDomain,
      transactionFilters,
      nextPage,
      previousPage,
      pageNumber
    }

  },
  name: 'AdminTransactionListView',
  components: { ErrorMessage, LoaderSection, FooterSection, NavigationSection, ContentPlaceholder }
}
</script>
