<template>
  <LoaderSection v-show="!userList && !userError" />
  <NavigationSection />
  <main ref="root">
    <div class="main-content-wrapper w-100">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2 bg-white rounded" id="mainContent">
            <div class="main-content p-3 pt-4">
              <div class="clearfix my-auto">
                <h1 class="fw-bold h3 text-dark float-start"><font-awesome-icon :icon="['far', 'fa-address-book']" />Users</h1>
                <form class="float-end" method="get" action="" id="integratorForm">
                  <div class="form-floating">
                    <select class="form-select bg-light" id="integratorId" name="integratorId" required>
                      <option value="1WTManOJBP7EKsy2TElo7I" selected="selected">Efty Investor</option>
                    </select>
                    <label for="environment">Integrator</label>
                  </div>
                </form>
                <form class="float-end me-3" id="userSearchForm" @submit.prevent="processUserSearch">
                  <div class="form-floating">
                    <input type="text" class="form-control" name="searchUser" id="searchUser" ref="searchUser">
                    <label for="searchUser">Search user (e-mail)</label>
                  </div>
                </form>
              </div>
              <div class="bg-white border p-3 mt-4">
                <div class="table-responsive">
                  <table class="table table-responsive table-borderless bg-white largecells">
                    <thead>
                    <tr>
                      <th scope="col" width="20%" class="text-muted fw-bold lead bg-white">Name</th>
                      <th scope="col" width="20%" class="text-muted fw-bold lead bg-white">EP ID</th>
                      <th scope="col" width="15%" class="text-muted fw-bold lead bg-white">Type</th>
                      <th scope="col" width="15%" class="text-muted fw-bold lead bg-white">MP User</th>
                      <th scope="col" width="15%" class="text-muted fw-bold lead bg-white">MP Verified</th>
                    </tr>
                    <tr>
                      <td colspan="4" class=" bg-white"></td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="!userList && !userError">
                      <td colspan="4" class=" bg-white"><ContentPlaceholder /></td>
                    </tr>
                    <tr v-else-if="userError">
                      <td colspan="4" class=" bg-white">
                        <ErrorMessage />
                      </td>
                    </tr>
                    <tr v-else-if="!userList.length">
                      <td colspan="4" class="text-center bg-white">
                        <img src="@/assets/notfound.png" class="img-fluid ">
                        <div class="w-100 text-center">
                          <font-awesome-icon :icon="['fas', 'xmark']" class="me-2" />No users found
                        </div>
                      </td>

                    </tr>
                    <tr v-for="userItem in userList" :key="userItem.id">
                      <td class=" my-auto bg-white align-middle">
                        <router-link :to="{ name: 'adminUser', params: { userId: userItem.id } }" title="View user">
                          <span class="bg-dark text-white rounded px-2 py-1 lead me-2">{{showInitials(userItem.firstName, userItem.lastName).toUpperCase()}}</span>
                          <span class=" text-dark fw-bold">{{ userItem.firstName }} {{ userItem.lastName }}</span>
                          <span v-if="userItem.companyAddress && userItem.companyAddress.country != 'COUNTRY_NONE'" class="ms-2"><img :src="'https://flagsapi.com/'+userItem.companyAddress.country+'/shiny/16.png'" :title="userItem.companyAddress.country"></span>
                        </router-link>
                      </td>
                      <td class=" my-auto bg-white align-middle">
                        <router-link :to="{ name: 'adminUser', params: { userId: userItem.id } }" title="View User">
                          <span>{{userItem.id }}</span>
                        </router-link>
                      </td>
                      <td class=" my-auto bg-white align-middle">
                        <router-link :to="{ name: 'adminUser', params: { userId: userItem.id } }" title="View User">
                          <span v-if="userItem.representsCompany">Business</span>
                          <span v-else>Personal</span>
                        </router-link>
                      </td>
                      <td class=" my-auto bg-white align-middle">
                        <router-link :to="{ name: 'adminUser', params: { userId: userItem.id } }" title="View User">
                          <span class="text-success" v-if="userItem.paymentDetails && userItem.paymentDetails.mangopayDetails.kycLevel"><font-awesome-icon :icon="['fas', 'fa-check']" /></span>
                          <span v-else><font-awesome-icon :icon="['fas', 'fa-xmark']" /></span>
                        </router-link>
                      </td>
                      <td class=" my-auto bg-white align-middle">
                        <router-link :to="{ name: 'adminUser', params: { userId: userItem.id } }" title="View User">
                          <span class="text-success" v-if="userItem.paymentDetails && userItem.paymentDetails.mangopayDetails.kycLevel == 'REGULAR'"><font-awesome-icon :icon="['fas', 'fa-check']" /></span>
                          <span v-else><font-awesome-icon :icon="['fas', 'fa-xmark']" /></span>
                        </router-link>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <hr>
                <nav aria-label="Paging" class="">
                  <ul class="pagination justify-content-end">
                    <li class="page-item"><a class="page-link bg-grey" :class="{ 'disabled bg-grey': pageNumber <= 1, 'bg-white': pageNumber > 1 }" @click.prevent="previousPage()" href="#"><font-awesome-icon :icon="['fas', 'angle-left']" /></a></li>
                    <li class="page-item"><a class="page-link disabled bg-white" href="#">Page {{ pageNumber }}</a></li>
                    <li class="page-item"><a class="page-link bg-white" @click.prevent="nextPage()" href="#"><font-awesome-icon :icon="['fas', 'angle-right']" /></a></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterSection />
    </div>
  </main>
</template>

<script>
import FooterSection from '@/components/admin/Footer.vue'
import LoaderSection from '@/components/admin/LoadingSpinner.vue'
import NavigationSection from "@/components/admin/Navigation.vue";
import ContentPlaceholder from '@/components/admin/ContentPlaceholder.vue'
import ErrorMessage from '@/components/admin/ErrorMessage.vue'
import {
  userList,
  userError,
  getUserList
} from "@/utils/users.admin";
import { ref } from 'vue';
import { eftyIntegratorId } from "@/utils/config";
import {
  showInitials
} from "@/utils/support";

export default {

  setup() {

    const pageNumber = ref(1);
    const lastUserId = ref(null);
    const firstUserIds = ref([]);
    const searchUser = ref('');

    const loadUsers = (searchValue = false, startAfter = false) => {
      getUserList(eftyIntegratorId, searchValue,10, startAfter).then(() => {
        if (userList.value) {
          if (pageNumber.value === 1 && userList.value.length) {
            firstUserIds.value[pageNumber.value - 1] = userList.value[0].id;
          }
          setLastItemInList();
        }
      });
    };

    const processUserSearch = () => {

      // Reset to page 1
      pageNumber.value = 1;
      firstUserIds.value = []; // Clear the stored IDs when starting a new search

      // Load transactions for the first page
      loadUsers(searchUser.value.value, false);
    };

    const setLastItemInList = () => {
      lastUserId.value = userList.value.length ? userList.value[userList.value.length - 1].id : null;
    };

    const nextPage = () => {
      if (lastUserId.value) {
        firstUserIds.value[pageNumber.value - 1] = lastUserId.value;
        pageNumber.value++;
        loadUsers(searchUser.value.value,lastUserId.value);
      }
    };

    const previousPage = () => {
      if (pageNumber.value > 1) {
        pageNumber.value--;
        const startAfterId = firstUserIds.value[pageNumber.value - 2] || null;
        loadUsers(searchUser.value.value,startAfterId);
      }
    };

    loadUsers();

    return {
      userList,
      userError,
      pageNumber,
      nextPage,
      previousPage,
      showInitials,
      searchUser,
      processUserSearch
    }
  },
  name: 'AdminUserListView',
  components: { ErrorMessage, LoaderSection, FooterSection, NavigationSection, ContentPlaceholder }
}
</script>