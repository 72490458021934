<template>
  <LoaderSection v-show="HomeView" />
  <NavigationSection />
  <main>
    <div class="main-content-wrapper w-100">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2 bg-white rounded" id="mainContent">
            <div class="main-content p-3 pt-4">
              <h1 class="fw-bold h3 text-dark"><font-awesome-icon :icon="['far', 'life-ring']" />Efty Pay Support Center</h1>
              <p>Our support team is available to help you. You can count on our team of experts via chat and email.</p>
              <div class="text-center">
                <img src="@/assets/support.png" class="img-fluid ">
              </div>
              <div class="row mt-5">

                <div class="col-12 col-md-6 col-lg-4 pb-3 pb-lg-0">
                  <div class="card border-0 bg-white">
                    <div class="card-body border rounded-bottom">
                      <h5 class="card-title fw-bold text-dark">E-mail with our support team</h5>
                      <p class="card-text">You can reach out via e-mail and we will get back with you as soon as possible</p>
                      <a href="mailto:ask@efty.com" class="btn btn-primary">Send an e-mail</a>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-6 col-lg-4 pb-3 pb-lg-0">
                  <div class="card border-0 bg-white">
                    <div class="card-body border rounded-bottom">
                      <h5 class="card-title fw-bold text-dark">Search our Knowledge Base</h5>
                      <p class="card-text">Find answers to common questions using our Knowledge Base.</p>
                      <a href="https://support.efty.com/collection/1-efty-pay" class="btn btn-primary" target="_blank">Knowledge Base</a>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-6 col-lg-4 pb-3 pb-lg-0">
                  <div class="card border-0 bg-white">
                    <div class="card-body border rounded-bottom">
                      <h5 class="card-title fw-bold text-dark">Efty Website</h5>
                      <p class="card-text">Our website offers a lot of information about our product suite.</p>
                      <a href="https://efty.com/" class="btn btn-primary" target="blank">Go to Efty.com</a>
                    </div>
                  </div>

                </div>

              </div>


            </div>
          </div>
        </div>
      </div>
      <FooterSection />

      </div>

  </main>

</template>

<script>
import FooterSection from '@/components/public/Footer.vue'
import NavigationSection from "@/components/public/Navigation.vue";
import LoaderSection from "@/components/public/LoadingSpinner.vue";

export default {

  name: 'PublicMagicLink',
  setup() {
  },
  components: { LoaderSection, FooterSection, NavigationSection }
}
</script>
