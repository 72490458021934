// Config the API endpoint
import {ref} from "vue";

const apiEndpoint = ref('https://api.eftypay.com/')

const eftyIntegratorId = '4DPszNkr7DDwtR3MXgFz50'

const eftyPayAdminId = '0AwMSGuYXntyTNJhwCLFkR'

// Auth0 variables
const auth0Domain = 'eftypay-prod.eu.auth0.com'
const auth0ClientId = '5G0OXGkvVw1fze09cogcm8zaloC8DjTY'
const auth0RedirectUri = 'https://pay.efty.com/#/admin/callback'
const auth0Audience = 'https://api.eftypay.com'

// Mangopay variables
const mangopayEnvironment = 'PRODUCTION'
const mangopayClientId = 'eftypayprod'

export {
    apiEndpoint,
    eftyIntegratorId,
    eftyPayAdminId,
    auth0Audience,
    auth0ClientId,
    auth0Domain,
    auth0RedirectUri,
    mangopayEnvironment,
    mangopayClientId
}