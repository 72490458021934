import axios from 'axios';
import { ref, shallowRef } from 'vue';
import { apiEndpoint, eftyIntegratorId } from "@/utils/config";
import { useOtpStore } from '@/stores/otp';
import { isLoading } from "@/utils/support";

// Reactive references to store invoice data and error messages
const invoiceList = ref(0);
const invoiceItem = shallowRef(0);
const invoiceError = ref(0);

// Function to generate axios headers with authorization token
const useAxiosHeaders = () => {
    const otpStore = useOtpStore();
    return {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${otpStore.otpToken}`,
    };
};

// Function to handle successful API responses
const handleApiResponse = (response, dataRef) => {
    isLoading.value = false; // Deactivate loading spinner
    dataRef.value = response.data; // Update the data reference with response data
};

// Function to handle API errors
const handleApiError = (error, errorRef) => {
    if (error) {
        isLoading.value = false; // Deactivate loading spinner
        errorRef.value = "There is an error"; // @Todo: the error reference with an error message
    }
};

// Function to fetch invoice list for a specific user and transaction
const getInvoiceList = async (userId, transactionId) => {
    const axiosHeaders = useAxiosHeaders(); // Get axios headers with authorization token

    // Activate loading spinner
    isLoading.value = true;

    // Reset invoice list and error states
    invoiceList.value = false;
    invoiceError.value = false;

    // Make API request to fetch invoice list
    await axios.get(`${apiEndpoint.value}invoices/users/${userId}/${transactionId}/?integratorId=${eftyIntegratorId}`, {
        headers: axiosHeaders,
    })
        .then(response => handleApiResponse(response, invoiceList)) // Handle successful response
        .catch(error => handleApiError(error, invoiceError)); // Handle error response
};

// Function to fetch details for a specific invoice by its ID
const getInvoiceItem = async (userId, transactionId, invoiceId) => {
    const axiosHeaders = useAxiosHeaders(); // Get axios headers with authorization token

    // Activate loading spinner
    isLoading.value = true;

    // Reset invoice item and error states
    invoiceItem.value = false;
    invoiceError.value = false;

    // Make API request to fetch invoice details
    await axios.get(`${apiEndpoint.value}invoices/id/${invoiceId}?userId=${userId}&transactionId=${transactionId}&integratorId=${eftyIntegratorId}`, {
        headers: axiosHeaders,
    })
        .then(response => handleApiResponse(response, invoiceItem)) // Handle successful response
        .catch(error => handleApiError(error, invoiceError)); // Handle error response
};

// Export functions and reactive references for use in other parts of the application
export {
    getInvoiceItem,
    getInvoiceList,
    invoiceList,
    invoiceItem,
    invoiceError,
};