<template>
  <nav class="navbar navbar-dark position-absolute top-0 w-100 p-0 m-0 pt-3">
    <div class="container-fluid">
      <div class="row w-100 align-items-center">
        <div class="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2">
          <img src="@/assets/logo-white.png" class="navbar-brand logo img-fluid p-0">
          <span class="text-white fw-bold float-end"><font-awesome-icon :icon="['fas', 'lock']" class="me-2"/>Secure checkout</span>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>


export default {
  name: 'NavigationLightCheckoutSection',

  setup() {

    return {

    }
  }

};
</script>