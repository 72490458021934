import axios from 'axios'
import { ref, shallowRef } from 'vue'
import {apiEndpoint } from "@/utils/config";
import { isLoading } from "@/utils/support"
const bankWirePayInItem = shallowRef(0)
const bankWirePayInError = ref(0)

// Get bankwire Pay In content for a specific transaction
const getBankWirePayInItemWithEncryptedData = ( encryptedData, iv, keyId ) => {

    // Activate loading spinner
    isLoading.value = true

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost'
    }

    bankWirePayInItem.value = false
    bankWirePayInError.value = false

    axios.get(apiEndpoint.value + 'payments/public/mangopay/checkout/bankWirePayIn?encryptedData=' + encryptedData + '&iv=' + iv + '&keyId=' + keyId, {
            headers: axiosHeaders
        }
    ).then(response => {

        isLoading.value = false
        bankWirePayInItem.value = response.data

    }).catch(error => {

        isLoading.value = false
        //bankWirePayInError.value = "There is an error"

        if (error.response.status == 500 || error.response.status == 403) { // @Todo change to 403 only after API fix

            //console.log(error)
            // Redirect to 403 page (work around vue router bc this function is called from within the router)
            //window.location.href = '/#/public/403/';

        }

    });

}

// Create bankwire Pay In for a specific transaction
const createBankWirePayInItemWithEncryptedData = async ( encryptedData, iv, keyId ) => {

    // Activate loading spinner
    isLoading.value = true

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost'
    }

    bankWirePayInItem.value = false
    bankWirePayInError.value = false

    await axios.post(apiEndpoint.value + 'payments/public/mangopay/checkout/bankWirePayIn', { encryptedData: encryptedData, iv: iv, keyId: keyId}, {
            headers: axiosHeaders
        }
    ).then(response => {

        isLoading.value = false
        bankWirePayInItem.value = response.data

    }).catch(error => {

        isLoading.value = false
        bankWirePayInError.value = error.response.data.message ? error.response.data.message : "There is an error"

        if (error.response.status == 500 || error.response.status == 403) { // @Todo change to 403 only after API fix

            //console.log(error)
            // Redirect to 403 page (work around vue router bc this function is called from within the router)
            //window.location.href = '/#/public/403/';

        }

    });

}

export {
    bankWirePayInItem,
    bankWirePayInError,
    getBankWirePayInItemWithEncryptedData,
    createBankWirePayInItemWithEncryptedData
}