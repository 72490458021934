<script setup>
</script>

<script>

</script>

<template>
  <div class="container-fluid px-0">
    <div class="bg-header w-100">
      <div class="container-fluid">
      </div>
    </div>
  </div>
  <router-view/>
</template>

<style>

:root {
  --eftypay-primary: #2076BB;
  --eftypay-secondary: #20BB65;
  --eftypay-bright: #009ef7;
  --eftypay-dark: #009ef7;
  --eftypay-sand:#EED1A4;
  --eftypay-success:#B2EA8D;
  --dark-grey: #bbb;
  --light-grey: #f7f7f7;
  --medium-grey: #eee;
  --text-default: #777;
  --bs-body-bg: #E4DFDA!important;
}
.card.paymentmethod.selected {
  background: linear-gradient(93deg, rgba(9,55,92,1) 0%, rgba(22,94,152,1) 63%, rgba(32,118,187,1) 100%)!important;
  border:none!important;
}
.card.paymentmethod.selected * {
  color:#fff!important;
}
.card.paymentmethod.selected {
  color:#fff!important;
}
.card.paymentmethod.selected img.payment-img {
  opacity: 1!important;
}
.card.paymentmethod:not(.selected) {
  border-color:#ccc!important;
}
.card.paymentmethod.disabled {
  border-color:#ccc!important;
}
.card.paymentmethod:not(.disabled) {
  cursor:pointer!important;
}
.card.paymentmethod img.payment-img {
  padding: 5px 15px;
  background: #fff;
  border-radius: 10px;
}
.card.paymentmethod.disabled img.payment-img {
  filter: grayscale(1)!important;
  opacity: 0.25!important;
}
.card.paymentmethod.disabled div {
  color:#bbb!important;
}
input:not([disabled]), select:not([disabled]) {
  background-color:#fff;
}
label::after {
  background-color:transparent!important;
}
.card.highlight {
  background-color:#ddd!important;
  animation: blinker 0.5s linear infinite;
}

@keyframes blinker {
  50% { opacity: 0.6; }
}
.card .card-header {
  background-color:transparent!important;
}
.text-extra-muted {
  color: #abb1b7 !important;
}
img.blog-img {
  width: 250px;
}
#preloader {
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color:#f3f3f3d9;
  z-index:1030;
}
#preloader #status {
  width:64px;
  height:64px;
  position:absolute;
  left:50%;
  top:50%;
  margin:-16px 0 0 -16px
}
#preloader #status .spinner {
  width:64px;
  height:64px;
}
#preloader .spinner-wrapper {
  position:relative;
  width:64px;
  height:64px;
  border-radius:0;
}
#preloader .spinner-wrapper img {
  width:auto;
  height:64px;
}
.img-trustpilot.stars {
  max-width:125px;
}
.img-trustpilot.logo {
  max-width:75px;
}
.nav-link.transaction-nav:hover {
  color:var(--text-default);
}
.nav-link.transaction-nav:not(.active) {
  border-bottom: 1px solid #dee2e6 !important;
}
.nav-link.transaction-nav.active {
  border-bottom: 1px solid #fff !important;
}
body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  background:var(--light-grey);
}

html {
  height: -webkit-fill-available;
}

main {
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  height: -webkit-fill-available;
  max-height: 65vh;
  overflow-x: auto;
  overflow-y: hidden;
}
table a,table a:hover {
  text-decoration:none;
  color:var(--text-default);
  display: block;
}
.text-decoration-none {
  text-decoration:none!important;;
}
footer a {
  text-decoration: none;
}
body,p,span,*:not(svg path, svg) {
  color:var(--text-default);
  font-family: Inter, Helvetica, "sans-serif";
}
h1 svg path {
  color:rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}
h1 svg  {
  margin-right:10px;
}
.list-status{
  margin-right:8px;
}
.text-seller {
  color:#ff00ba!important;
}
.text-buyer {
  color:#ffc107 !important;
}
.text-grey {
  color:#bbb!important;
}
#audit-trail-container {
  max-height: 600px;
  overflow-y: scroll;
}
#audit-trail-container::-webkit-scrollbar {
  width: 17px;
}
#audit-trail-container::-webkit-scrollbar-track {
  background-color: transparent;
}
#audit-trail-container::-webkit-scrollbar-thumb {
   background-color: #d6dee1;
   border-radius: 20px;
   border: 6px solid transparent;
   background-clip: content-box;
}
.audit-card:not(.audit-card-current) {
  margin-top:-23px;
}
.audit-arrow, .audit-arrow * {
  z-index:9;
}
.bg-seller {
  background:#ff00ba!important;
}
.bg-buyer {
  background:#ffc107 !important;
}
.payment-img {
  max-width: 200px;
}
.bg-medium {
  background:var(--medium-grey)!important;
}
.bg-message-other {
  position: relative;
  background: linear-gradient(93deg, rgb(94, 92, 92) 0%, rgb(106, 106, 106) 63%, rgb(134, 134, 134) 100%) !important;
}
input#messageInputBuyer:focus, input#messageInputSeller:focus, input#messageInput:focus {
  box-shadow:none!important;
  border-color: var(--bs-border-color) !important;
}
textarea#messageInputBuyer, textarea#messageInputSeller, textarea#messageInput {
  resize:none;
}
.form-control:focus, .form-select:focus {
  box-shadow:none!important;
  background:#fff!important;
}
.bg-message-other *, .bg-message-self * {
  color:#fff;
}
.bg-message-other p, .bg-message-self p {
  margin:0 0 10px 0;
  padding:0;
}
.nav-tabs-container {
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.nav-tabs-container::-webkit-scrollbar {
  display: none;
  overflow-x: auto;
  white-space: nowrap;
}
.bg-message-other:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-width: 0 18px 18px 0;
  border-color: transparent rgb(94, 92, 92) transparent transparent;
  top: 12px;
  left: -18px;
  margin-top: -12px;
}
.bg-interaction-widget {
  background:#efffee!important;
}
.border-interaction-widget {
  border:2px solid #bcdfba!important;
}
.badge-you {
  opacity: 0.85;
}
.border-light-grey {
  border-color:#f0f0f0!important;
}
img.error-img {
  max-width:250px;
}
.bg-message-buyer {
  background: #ffc107!important;
}
.bg-message-seller {
  background: #ff00ba!important;
}
.bg-message-self {
  position: relative;
  background: linear-gradient(93deg, rgb(40, 145, 86) 0%, rgb(37, 183, 102) 63%, rgb(32, 187, 101) 100%)!important;
}
.bg-message-self:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-width: 18px 18px 0 0;
  border-color: rgb(32, 187, 101) transparent transparent transparent;
  top: 12px;
  right: -18px;
  margin-top: -12px;
 }
.list-status.complete svg path{
  color:var(--bs-success);
}
.dropdown-item svg  {
  width: 30px;
}
button svg {
  margin-right: 5px;
}
img.logo {
  max-width:120px;
}
img.logo.transaction-center {
  max-width:180px;
}
img.logo-footer {
  max-width:160px;
}
table td {
  overflow:hidden;
}
table th {
  font-size:90%;
}
table td a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  font-size:90%;
}
table td.no-max-width a  {
  max-width:100%!important;
}
table.largecells td a {
  max-width: 350px;
}
.bg-header {
  background: rgb(9,55,92);
  background: linear-gradient(93deg, rgba(9,55,92,1) 0%, rgba(22,94,152,1) 63%, rgba(32,118,187,1) 100%);
  height:35vh;
}
img.communication-avatar-eftypay {
  width:60px;
}
img.communication-avatar-eftypay-xs {
  width:20px;
}
.main-content-wrapper {
  position:absolute;
  top:15vh;
}
.main-content {
  min-height:65vh;
}
li.page-item.active .page-link {
  background-color:var(--eftypay-primary);
}
li.page-item:not(.active) .page-link {
  color:var(--text-default);
}
.bg-light-grey {
  background-color:var(--light-grey)
}
.bg-dark-grey {
  background-color:var(--dark-grey)
}
.bg-medium-grey {
  background-color:var(--medium-grey)
}
.bg-eftypay-primary {
  background-color:var(--eftypay-primary);
}
.bg-eftypay-bright {
  background-color:var(--eftypay-bright);
}
.bg-sand {
  background-color:var(--eftypay-sand);
}
.bg-eftypay-success{
  background-color:var(--eftypay-success)!important;
}
.text-primary {
  color:var(--eftypay-bright)!important;
}
a.nav-link{
  border-radius:10px;
}
a.nav-link:not(.active):not(.nav-link-right):hover {
  background-color:#cccccc1a;
}
.btn-success {
  background-color:var(--eftypay-secondary)!important;
  border-color:var(--eftypay-secondary)!important;
}
.btn-info {
  background-color:var(--light-grey)!important;
  border-color:var(--light-grey)!important;
}
.btn-primary {
  background-color:var(--eftypay-primary)!important;
  border-color:var(--eftypay-primary)!important;
}
.nav-link-icon {
  width:30px;
  text-align:center;
  display: block;
  float: left;
}
.nav-link-icon svg path {
  color:#fff;
  margin-right:5px;
}
#notifications-navbar svg  {
  font-size:150%;
}
.btn-primary svg, .btn-secondary svg, .btn-dark svg  {
  margin-right:5px;
}
.btn-primary svg path, .btn-secondary svg path, .btn-danger svg path {
  color:#fff;
}
#notifications-navbar svg path {
  color:#fff;
}
.communication-centre-avatar svg path {
  color:#fff;
}
.communication-centre-avatar svg {
  font-size: 2rem;
}
.history-avatar svg path {
  color:#fff;
}
.nav-link-text {
  padding-right:10px;
}
.nav-item a {
  font-size:90%;
}
ul.dropdown-menu i {
  width:25px;
}
a.dropdown-toggle.no-chevron:after {
  display:none;
}
.dropstart .dropdown-menu {
  margin: 0px -25px 0 0 !important;
}
.dropdown-item:active {
  background:transparent!important;
}

.modal-header h5 svg path {
  color:#fff;
}
.modal-header h5 svg {
  margin-right:10px;
}
.modal-header {
  background: var(--eftypay-primary);
}
.notifier {
  width:10px;
  height:10px;
  top:5px!important;
  right:-5px!important;
}
.nav-tabs .nav-link {
  color:var(--text-default);
  margin-right:10px;
}
.nav-tabs .nav-link.active {
  font-weight:bold;
}
.cap-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.history-avatar {
  width: 40px;
}
.communication-centre-avatar {;
}
.transaction-steps-wrapper {
  gap: 5px;
  text-align: center;
  font-size: .9em;
}
.transaction-steps-wrapper .step {
  position: relative;
  flex: 1;
  padding-bottom: 45px;
  font-size:110%;
}
.transaction-steps-wrapper .step.active {
  font-weight: 600;
}
.transaction-steps-wrapper .step.finish {
  font-weight: 600;
  color: var(--bs-success);
}
.transaction-steps-wrapper .step.finish i {
  color: var(--bs-success);
  font-size:110%;
}
.transaction-steps-wrapper .step::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 9;
  width: 20px;
  height: 20px;
  background-color: var(--light-grey);
  border-radius: 50%;
}
.transaction-steps-wrapper .step.active::before {
  background-color: var(--bs-success);
  border: 3px solid #8bcaad;
  width: 40px;
  height: 40px;
  bottom: -10px;
}
.transaction-steps-wrapper .step.finish::before {
  background-color: var(--bs-success);
  border: 3px solid #8bcaad;
}
.transaction-steps-wrapper .step::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 8px;
  width: 100%;
  height: 3px;
  background-color: var(--light-grey);
}
.transaction-steps-wrapper .step.finish::after {
  background-color: var(--bs-success);
}
.transaction-steps-wrapper .step.finish.active::after {
  background-color: var(--light-grey);
}
.transaction-steps-wrapper .step:last-child:after {
  display: none;
}
.transaction-centre-msg::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f0d9";
  position: absolute;
  top: 0;
  left: -22px;
  color:var(--light-grey);
  font-size:4rem;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.tooltip-inner {
  max-width: 600px !important;
  text-align:left!important;
  text-size:120%;
}
.tooltip-inner li {
  color:#fff;
}
.smaller {
  font-size:0.75rem
}
.text-list-wrapper {
  max-height:50vh;
  overflow-y:scroll;
  flex-direction: column-reverse;
  display:flex;
}
.navbar-toggler:focus {
  box-shadow: none!important;
}
#mainMenu .nav-item .router-link-active {
  background:#ffffff22!important;
}
/* Mobile view based on BS Large breakpoint */
@media (max-width: 992px) {
  img.communication-avatar-eftypay {
    width:40px;
  }
  #mainContent {
    border-radius:0!important;
  }
  .navbar-brand .logo {
    position: relative!important;
    padding-left:20px;
  }
  #mainMenu.show .navbar-brand .logo {
    z-index:9999!important;
  }
  .main-content-wrapper {
    top: 55px;
  }
  .nav-tabs .nav-item {
    font-size:70%;
  }
  .transaction-steps-wrapper .step {
    font-size: 0.80rem!important;
  }
  #mainMenu.show {
    position: fixed;
    z-index: 999;
    background: var(--eftypay-primary);
    height: 100vh;
    width: 100vw;
    padding:0 0 0 0!important;
    top:0;
  }
  #mainMenu.show ul:first-of-type.navbar-nav {
    padding-top:20px!important;
  }
  .text-list-wrapper {
    max-height:90vh;
  }
  .bg-message-other, .bg-message-other *, .bg-message-self, .bg-message-self * {
    font-size:0.8rem;
  }
  .mob-closer {
    position: absolute;
    top: 0px;
    right: 10px;
    font-size: 2rem !important;
    background: var(--eftypay-primary)!important;
  }
  .navbar-nav .nav-item.active {
    background:#ffffff33;
    width:90%;
  }

}

/* Desktop view based on BS > Large breakpoint */
@media (min-width: 992px) {
.w-lg-75 {
  width:75%!important}
}
</style>