<template>
  <LoaderSection />
  <main>
    <div class="main-content-wrapper w-100">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2 bg-white rounded" id="mainContent">
            <div class="main-content p-3 pt-4">
              <h1 class="fw-bold h3 text-dark">Loading..</h1>
              <p>We will redirect you in a moment!</p>
            </div>
          </div>
        </div>
      </div>
      <FooterSection />

    </div>

  </main>

</template>

<script>
import { useAuth0 } from '@auth0/auth0-vue';
import { router } from '@/router'
import { userRoleList } from '@/utils/config'
import { useAuth0Store } from '@/stores/auth0'

export default {

  setup () {

    // Get user info from auth0
    const { user, logout, getAccessTokenSilently } = useAuth0();
    const auth0Store = useAuth0Store()

    // Check if the user is logged in (already taken care of on router level but better safe then sorry)
    if (!user) {

      logout()

    } else {

      const token = getAccessTokenSilently();

      token.then(response => {

        // Set token in local storage
        auth0Store.update(response)

      });

      // Get user role from auth0
      const userRole = user.value["user_roles/roles"][0]

      userRoleList.value.forEach((value) => {

        if (value.name == userRole) {

          // Set hashed value in local storage
          localStorage.setItem('role', value.code)

          // Redirect to homepage
          router.push({ path: '/admin', replace: true })

        }

      });

    }
  },
  name: "AdminCallbackView",
};
</script>