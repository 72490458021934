import axios from 'axios'
import { ref } from 'vue'
import { transactionStatusList, transactionSubStatusList, apiEndpoint } from "@/utils/config";

const transactionList = ref(0)
const transactionItem = ref(0)
const transactionMessageList = ref(0)
const activeFilter = ref('all')
const transactionError = ref(0)
const transactionMessageError = ref(0)
const transactionStatusDescriptionError = ref(0)
const messagesError = ref(0)
const messageList = ref(0)
const transactionStatusDescriptionList = ref(0)
const authToken = ref(0)
const transactionDocumentList = ref(0)
const transactionDocumentError = ref(0)
const otpUserProfile = ref(0)
const otpUserId = ref(0)

const auth0token = localStorage.getItem('auth0token')
const otpToken = localStorage.getItem('otpToken')

// Determine which token to use (auth0 for admin routes, otp for public routes)
if (auth0token) {
    authToken.value = localStorage.getItem('auth0token')
} else if (otpToken) {
    authToken.value = localStorage.getItem('otpToken')
}

// Set headers for Axios requests at top level
const axiosHeaders = {
    'Access-Control-Request-Method': 'POST',
    Origin: 'localhost',
    Authorization: `${authToken.value}`
}

// Returns wether Efty Pay has a holding account with a registrar, based on a transaction object
const getRegistrarStatus = (transactionItem) => {

    if (transactionItem.digitalAsset && transactionItem.digitalAsset.domain && transactionItem.digitalAsset.domain.whoisLookups) {
        return transactionItem.digitalAsset.domain.whoisLookups.slice(-1)[0].eftyPayHasAccountWithRegistrar ? 'eftyPayHasAccountWithRegistrar' : 'eftyPayHasNoAccountWithRegistrar';
    }

}

// Translate the transaction status map to useable bitmaps
const getTransactionDeepStatusMapping = ( statusMap, context = 'admin') => {

    if (statusMap) {

        if (context == 'buyer') {

            // Checkout not completed
            if (statusMap.INITIATE_TRANSACTION !== 'APPROVED') {
                return {
                    identifier: 'CHECKOUT_INCOMPLETE',
                    title: 'Checkout is not complete',
                    desc: 'The checkout process has been started, but not yet completed by the buyer',
                    order: 1
                };
            }

            // Pay-in not completed
            else if (statusMap.PAYMENT_TO_EFTY_PAY == 'INITIATED_OR_REQUESTED') {
                return {
                    identifier: 'PAY_IN_INCOMPLETE',
                    title: 'Awaiting payment',
                    desc: 'The checkout is complete, but the payment from the buyer has not yet been received',
                    order: 2
                };
            }

            // Pay-in completed but not approved
            else if (statusMap.PAYMENT_TO_EFTY_PAY == 'CONFIRMED') {
                return {
                    identifier: 'PAY_IN_CONFIRMED',
                    title: 'Payment received, pending validation',
                    desc: 'The payment is done by the buyer and is awaiting approval from Efty Pay',
                    order: 3
                };
            }

            // Awaiting transfer in from seller
            else if (statusMap.PAYMENT_TO_EFTY_PAY == 'APPROVED' &&
                (statusMap.TRANSFER_ASSET_TO_EFTY_PAY == 'INITIATED_OR_REQUESTED' ||
                    statusMap.TRANSFER_ASSET_TO_EFTY_PAY == 'SUB_STATUS_NONE')) {
                return {
                    identifier: 'TRANSFER_IN_INCOMPLETE',
                    title: 'Awaiting transfer from seller',
                    desc: 'Efty Pay is awaiting the transfer of the domain from the seller',
                    order: 4
                };
            }

            // Transfer in confirmed
            else if (statusMap.PAYMENT_TO_EFTY_PAY == 'APPROVED' &&
                statusMap.TRANSFER_ASSET_TO_EFTY_PAY == 'CONFIRMED') {
                return {
                    identifier: 'TRANSFER_IN_INCOMPLETE',
                    title: 'Domain received, pending validation',
                    desc: 'Efty Pay received the domain from the seller and is currently approving it',
                    order: 5
                };
            }

            // Transfer out initiated
            else if (statusMap.TRANSFER_ASSET_TO_EFTY_PAY == 'APPROVED' &&
                (statusMap.TRANSFER_ASSET_TO_BUYER == 'INITIATED_OR_REQUESTED' ||
                    statusMap.TRANSFER_ASSET_TO_BUYER == 'SUB_STATUS_NONE')) {
                return {
                    identifier: 'TRANSFER_OUT_INCOMPLETE',
                    title: 'Preparing transfer to buyer',
                    desc: 'Efty Pay approved the domain from the seller and is currently initiating the transfer to the buyer',
                    order: 6
                };
            }

            // Transfer out initiated
            else if (statusMap.TRANSFER_ASSET_TO_EFTY_PAY == 'APPROVED' &&
                statusMap.TRANSFER_ASSET_TO_BUYER == 'CONFIRMED') {
                return {
                    identifier: 'TRANSFER_OUT_INCOMPLETE',
                    title: 'Awaiting transfer approval',
                    desc: 'The domain transfer has been initiated and is currently awaiting approval',
                    order: 7
                };
            }

            // Transfer out completed
            else if (statusMap.TRANSFER_ASSET_TO_BUYER == 'APPROVED') {
                return {
                    identifier: 'TRANSFER_OUT_COMPLETE',
                    title: 'Transfer complete',
                    desc: 'The transfer has been completed successfully',
                    order: 8
                };
            }

        } else if (context == 'seller') {

            // Checkout not completed
            if (statusMap.INITIATE_TRANSACTION !== 'APPROVED') {
                return {
                    identifier: 'CHECKOUT_INCOMPLETE',
                    title: 'Checkout is not complete',
                    desc: 'The checkout process has been started, but not yet completed by the buyer',
                    order: 1
                };
            }

            // Pay-in not completed
            else if (statusMap.PAYMENT_TO_EFTY_PAY == 'INITIATED_OR_REQUESTED') {
                return {
                    identifier: 'PAY_IN_INCOMPLETE',
                    title: 'Awaiting payment',
                    desc: 'The checkout is complete, but the payment from the buyer has not yet been received',
                    order: 2
                };
            }

            // Pay-in completed but not approved
            else if (statusMap.PAYMENT_TO_EFTY_PAY == 'CONFIRMED') {
                return {
                    identifier: 'PAY_IN_CONFIRMED',
                    title: 'Payment received, pending validation',
                    desc: 'The payment is done by the buyer and is awaiting approval from Efty Pay',
                    order: 3
                };
            }

            // Awaiting transfer in from seller
            else if (statusMap.PAYMENT_TO_EFTY_PAY == 'APPROVED' &&
                (statusMap.TRANSFER_ASSET_TO_EFTY_PAY == 'INITIATED_OR_REQUESTED' ||
                    statusMap.TRANSFER_ASSET_TO_EFTY_PAY == 'SUB_STATUS_NONE')) {
                return {
                    identifier: 'TRANSFER_IN_INCOMPLETE',
                    title: 'Awaiting transfer from seller',
                    desc: 'Efty Pay is awaiting the transfer of the domain from the seller',
                    order: 4
                };
            }

            // Transfer in confirmed
            else if (statusMap.PAYMENT_TO_EFTY_PAY == 'APPROVED' &&
                statusMap.TRANSFER_ASSET_TO_EFTY_PAY == 'CONFIRMED') {
                return {
                    identifier: 'TRANSFER_IN_INCOMPLETE',
                    title: 'Domain received, pending validation',
                    desc: 'Efty Pay received the domain from the seller and is currently approving it',
                    order: 5
                };
            }

            // Transfer out initiated
            else if (statusMap.TRANSFER_ASSET_TO_EFTY_PAY == 'APPROVED' &&
                (statusMap.PAYOUT_TO_SELLER == 'INITIATED_OR_REQUESTED' ||
                    statusMap.PAYOUT_TO_SELLER == 'SUB_STATUS_NONE' ||
                    statusMap.PAYOUT_TO_SELLER == 'CONFIRMED')) {
                return {
                    identifier: 'PAY_OUT_INCOMPLETE',
                    title: 'Preparing pay-out to seller',
                    desc: 'Efty Pay approved the domain transfer and is currently preparing the pay-out to the seller',
                    order: 6
                };
            }

            // Transfer out completed
            else if (statusMap.PAYOUT_TO_SELLER == 'APPROVED') {
                return {
                    identifier: 'PAY_OUT_COMPLETE',
                    title: 'Transfer complete',
                    desc: 'The transfer has been completed successfully',
                    order: 8
                };
            }

        } else if (context == 'admin') {

            // Checkout not completed
            if (statusMap.INITIATE_TRANSACTION !== 'APPROVED') {
                return {
                    identifier: 'CHECKOUT_INCOMPLETE',
                    title: 'Checkout is not complete',
                    desc: 'The checkout process has been started, but not yet completed by the buyer',
                    order: 1
                };
            }

            // Pay-in not completed
            else if (statusMap.PAYMENT_TO_EFTY_PAY == 'INITIATED_OR_REQUESTED') {
                return {
                    identifier: 'PAY_IN_INCOMPLETE',
                    title: 'Awaiting payment',
                    desc: 'The checkout is complete, but the payment from the buyer has not yet been received',
                    order: 2
                };
            }

            // Pay-in completed but not approved
            else if (statusMap.PAYMENT_TO_EFTY_PAY == 'CONFIRMED') {
                return {
                    identifier: 'PAY_IN_CONFIRMED',
                    title: 'Payment pending validation',
                    desc: 'The payment is done by the buyer and is awaiting approval from Efty Pay',
                    order: 3
                };
            }

            // Awaiting transfer in from seller
            else if (statusMap.PAYMENT_TO_EFTY_PAY == 'APPROVED' &&
                (statusMap.TRANSFER_ASSET_TO_EFTY_PAY == 'INITIATED_OR_REQUESTED' ||
                    statusMap.TRANSFER_ASSET_TO_EFTY_PAY == 'SUB_STATUS_NONE')) {
                return {
                    identifier: 'TRANSFER_IN_INCOMPLETE',
                    title: 'Awaiting transfer from seller',
                    desc: 'Efty Pay is awaiting the transfer of the domain from the seller',
                    order: 4
                };
            }

            // Transfer in confirmed
            else if (statusMap.PAYMENT_TO_EFTY_PAY == 'APPROVED' &&
                statusMap.TRANSFER_ASSET_TO_EFTY_PAY == 'CONFIRMED') {
                return {
                    identifier: 'TRANSFER_IN_INCOMPLETE',
                    title: 'Domain received, pending validation',
                    desc: 'Efty Pay received the domain from the seller and is currently approving it',
                    order: 5
                };
            }

            // Transfer out initiated
            else if (statusMap.TRANSFER_ASSET_TO_EFTY_PAY == 'APPROVED' &&
                (statusMap.PAYOUT_TO_SELLER == 'INITIATED_OR_REQUESTED' ||
                    statusMap.PAYOUT_TO_SELLER == 'SUB_STATUS_NONE')) {
                return {
                    identifier: 'PAY_OUT_INCOMPLETE',
                    title: 'Awaiting pay-out to seller',
                    desc: 'Efty Pay approved the domain transfer and is currently initiating the pay-out to the seller',
                    order: 6
                };
            }

            // Transfer out initiated
            else if (statusMap.TRANSFER_ASSET_TO_EFTY_PAY == 'APPROVED' &&
                statusMap.PAYOUT_TO_SELLER == 'CONFIRMED') {
                return {
                    identifier: 'PAY_OUT_INCOMPLETE',
                    title: 'Awaiting pay-out to seller',
                    desc: 'Efty Pay sent the pay-out to the seller and is awaiting the seller\'s confirmation',
                    order: 7
                };
            }

            // Completed for buyer and seller
            else if (statusMap.PAYOUT_TO_SELLER == 'APPROVED' &&
                statusMap.TRANSFER_ASSET_TO_BUYER == 'APPROVED') {
                return {
                    identifier: 'COMPLETE',
                    title: 'Transfer complete',
                    desc: 'The transfer has been completed successfully',
                    order: 8
                };
            }
        }
    }

    return {
        identifier: '',
        title: '',
        desc: '',
        order: null }

}

// Get list with transactions
const getTransactionList = ( integratorId ) => {

    // Reset
    transactionList.value = false
    transactionError.value = false

    const listRequestObject = { integratorId: integratorId }

    axios
        .post(apiEndpoint.value + 'transactions/list', { returnSellerData: true, returnBuyerData: true, listRequest: listRequestObject  },{
                headers: axiosHeaders
            }
        ).then(response => {
        transactionList.value = response.data
    }).catch(error => {
        transactionError.value = "There is an error"

        if (error.response.status == 500 || error.response.status == 403) { // @Todo change to 403 only after API fix

            // Redirect to 403 page (work around vue router bc this function is called from within the router)
            //window.location.href = '/#/public/403/';

        }

    })

}

// Get content for a specific transaction
const getTransactionItem = ( transactionId, integratorId ) => {

    transactionItem.value = false

    if ( transactionId ) {

        transactionError.value = false

        axios.get(apiEndpoint.value + 'transactions/id/' + transactionId + '/?integratorId=' + integratorId, {
                    headers: axiosHeaders
                }
            ).then(response => {
                transactionItem.value = response.data
            }).catch(error => {
                transactionError.value = "There is an error"

                if (error.response.status == 500 || error.response.status == 403) { // @Todo change to 403 only after API fix

                    if (error) {
                        // Todo
                    }
                    // Redirect to 403 page (work around vue router bc this function is called from within the router)
                    //window.location.href = '/#/public/403/';

                }

            });
    }

}

// Get list with message for a transaction
const getTransactionMessageList = ( transactionId ) => {

    // Reset
    transactionMessageList.value = false
    transactionMessageError.value = false

    axios
        .post(apiEndpoint.value + 'messages/list', { transactionId: transactionId },{
                headers: axiosHeaders
            }
        ).then(response => {
        transactionMessageList.value = response.data
        //console.log(transactionId)
        //console.log(transactionMessageList.value)
    }).catch(error => {
        transactionMessageError.value = "There is an error"

        if (error.response.status == 500 || error.response.status == 403) { // @Todo change to 403 only after API fix

            // Redirect to 403 page (work around vue router bc this function is called from within the router)
            //window.location.href = '/#/public/403/';

        }

    })

}

// Get list with message for a transaction
const getTransactionDocumentList = ( transactionId ) => {

    // Reset
    transactionDocumentList.value = false
    transactionDocumentError.value = false

    axios
        .post(apiEndpoint.value + 'messages/list', { transactionId: transactionId },{
                headers: axiosHeaders
            }
        ).then(response => {
        transactionDocumentList.value = response.data
    }).catch(error => {
        transactionDocumentError.value = "There is an error"

        if (error.response.status == 500 || error.response.status == 403) { // @Todo change to 403 only after API fix

            // Redirect to 403 page (work around vue router bc this function is called from within the router)
            //window.location.href = '/#/public/403/';

        }

    })

}



// Get list with message for a transaction
const addTransactionMessageItem = ( transactionId, authorId, receiverId, message, integratorId = '1WTManOJBP7EKsy2TElo7I' ) => {

    axios
        .post(apiEndpoint.value + 'messages', { transactionId: transactionId, authorId: authorId, receiverId: receiverId, message: message, integratorId: integratorId  },{
                headers: axiosHeaders
            }
        ).then(response => {
        transactionMessageList.value = response.data
        getTransactionItem(transactionId, integratorId)
    }).catch(error => {
        transactionMessageError.value = "There is an error"
        if (error) {
            // Todo
        }

    })

}

// Get content for a specific transaction, to be loaded into the modal
const getTransactionMessages = ( transactionId ) => {

    messageList.value = false

    if ( transactionId ) {

        messagesError.value = false

        setTimeout(() => axios
            .post(apiEndpoint.value + 'messages/list', {
                    headers: axiosHeaders,
                    data: transactionId
                }
            ).then(response => {
                messageList.value = response.data
            }).catch(error => {
                messagesError.value = "There is an error"
                if (error) {
                    // Todo
                }
            }), 500);
    }

}



// Get list with all statusses and human readable descriptions
const getTransactionStatusDescriptionList = ( ) => {

    transactionStatusDescriptionList.value = false

    transactionStatusDescriptionError.value = false

    setTimeout(() => axios
        .get(apiEndpoint.value + 'transactions/status/configuration', {
                headers: axiosHeaders
            }
        ).then(response => {
            transactionStatusDescriptionList.value = response.data
        }).catch(error => {
            transactionStatusDescriptionError.value = "There is an error"
            if (error) {
                // Todo
            }
        }), 500);


}

// Return the transaction status to a human readable format
const getHumanReadableTransactionStatus = (transactionStatus) => {

    if (transactionStatus) {
        if ( transactionStatusList.value.find((item) => item.name === transactionStatus) ) {
            return transactionStatusList.value.find((item) => item.name === transactionStatus).title
        }
    }

    return transactionStatus

}

const getHumanReadableTransactionSubStatus = (transactionSubStatus) => {

    if (transactionSubStatus) {
        if ( transactionSubStatusList.value.find((item) => item.name === transactionSubStatus ) ) {
            return transactionSubStatusList.value.find((item) => item.name === transactionSubStatus).title
        }
    }

    return transactionSubStatus

}

const getNextTransactionStatus = (transactionStatus, transactionSubStatus) => {

    if (transactionStatus && transactionSubStatus) {
        return 'Test'
    }

}

export {
    getTransactionItem,
    getTransactionList,
    getTransactionMessages,
    getTransactionMessageList,
    addTransactionMessageItem,
    getTransactionDocumentList,
    getHumanReadableTransactionStatus,
    getHumanReadableTransactionSubStatus,
    getNextTransactionStatus,
    getTransactionStatusDescriptionList,
    otpUserProfile,
    transactionList,
    transactionItem,
    activeFilter,
    transactionError,
    transactionStatusList,
    transactionStatusDescriptionList,
    transactionDocumentList,
    otpUserId,
    getTransactionDeepStatusMapping,
    getRegistrarStatus
}