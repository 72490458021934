<template>
  <LoaderSection v-show="HomeView" />
  <NavigationSection />
  <main>
    <div class="main-content-wrapper w-100">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2 bg-white rounded" id="mainContent">
            <div class="main-content p-3 pt-4">
              <h1 class="fw-bold h3 text-dark">You are being signed out..</h1>
              <p>We will redirect you in a moment!</p>
              </div>
            </div>
          </div>
        </div>
      <FooterSection />

      </div>

  </main>

</template>

<script>
import FooterSection from '../../components/admin/Footer.vue'
import NavigationSection from "@/components/admin/Navigation.vue";
import LoaderSection from "@/components/admin/LoadingSpinner.vue";

export default {

  name: 'SignOutView',
  setup() {

  },
  components: { LoaderSection, FooterSection, NavigationSection }
}
</script>
