<template>
  <nav class="navbar navbar-dark pt-0 pt-lg-4 position-absolute top-0 w-100 mt-2">
    <div class="container-fluid">
      <div class="row w-100">
        <div class="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2 my-auto">
          <router-link :to="{ name: 'publicHome' }" class="navbar-brand"><img src="@/assets/logo-white.png" class="logo img-fluid pt-1"></router-link>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>


export default {
  name: 'NavigationLightSection',

  setup() {

    return {

    }
  }

};
</script>