<template>
  <NavigationLightSection />
  <main>
    <div class="main-content-wrapper w-100">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2 bg-white rounded" id="mainContent">

            <div class="main-content p-3 pt-4 text-center">
              <h1 class="fw-bold h3 text-dark">Processing your OTP (One-Time-Password)</h1>
              <p class="lead">We're processing your OTP, hold on!</p>
            </div>

          </div>
        </div>
      </div>
    <FooterSection />
    </div>


  </main>

</template>

<script>
import FooterSection from '@/components/public/Footer.vue'
import NavigationLightSection from "@/components/public/NavigationLight.vue";
import { useRoute } from 'vue-router'
import {verifyOtp} from '@/utils/magiclink'

export default {

  inheritAttrs: true,

  mounted() {

    const route = useRoute()

    // Get params from query
    const encryptedData = route.query.d
    const iv = route.query.i
    const keyId = route.query.k
    const otp = route.query.otp

    // Verify and send magic link OTP
    verifyOtp(encryptedData,iv,keyId,otp)

  },

  name: 'PublicOtpView',

  setup() {

  },
  components: { FooterSection, NavigationLightSection }
}
</script>
