<template>
  <main ref="root">
  <NavigationLightSection />
    <div class="main-content-wrapper w-100">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2 bg-white rounded" id="mainContent">

            <!-- URL Has valid OTP parameters and the OTP code has been sent via email -->
            <div class="main-content p-3 pt-4 text-center" v-if="magicLinkIsVerified">
              <h1 class="fw-bold h3 text-dark"><font-awesome-icon :icon="['far', 'envelope']" class="me-2"/>Check your e-mail</h1>
              <p class="lead">We just sent you an e-mail containing an OTP (One-Time-Password) you can use to log into Efty Pay.</p>
              <div class="container">
                <div class="row">
                  <div class="col-12 col-lg-6 offset-lg-3">

                    <div class="card p-2 text-center bg-light w-100 mt-3">
                      <form method="post" action="" id="otpForm">
                        <h6 class="mt-2 fw-bold">Please enter your OTP (One-Time-Password)</h6>
                        <div id="otp" class="inputs d-flex flex-row justify-content-center mt-2">
                          <input class="m-2 text-center form-control rounded w-50 bg-white" type="text" id="otpInput" maxlength="8" required autofocus v-focus>
                        </div>

                        <div class="mt-2">
                          <button class="btn btn-success px-4 validate" @click="finishOtpSteps()">Validate</button>
                        </div>
                      </form>

                    </div>

                  </div>
                </div>
              </div>

              <img src="@/assets/otp.png" class="img-fluid ">
            </div>

            <div class="main-content p-3 pt-4 text-center my-auto" v-if="!magicLinkIsVerified">
              <h1 class="fw-bold h3 text-dark"><font-awesome-icon :icon="['fas', 'hourglass']" class="me-2"/>We're processing your request, please hold on</h1>
              <img src="@/assets/loader.gif" class="img-fluid mt-5">
            </div>

          </div>
        </div>
      </div>
      <FooterSection />
    </div>

  </main>

</template>

<script>
import FooterSection from '@/components/public/Footer.vue'
import NavigationLightSection from "@/components/public/NavigationLight.vue";
import { useRoute } from 'vue-router'
import {verifyMagicLink, magicLinkIsVerified} from '@/utils/magiclink'
import { ref } from 'vue'

export default {

  name: 'PublicMagicLinkView',

  setup() {

    const route = useRoute()
    const root = ref(null)

    // Get params from query
    const encryptedData = route.query.d
    const iv = route.query.i
    const keyId = route.query.k

    // Verify and send magic link OTP
    verifyMagicLink(encryptedData,iv,keyId)

    const finishOtpSteps = (  ) => {

      // Do HTML validation
      if (! root.value.querySelector('form#otpForm')[0].checkValidity()) {

        root.value.querySelector('form#otpForm')[0].reportValidity()

      }
      // Forward to OTP validation page
      else {

        window.location.href = '/#/public/otp/?d=' + route.query.d + '&i=' + route.query.i + '&k=' + route.query.k + '&otp=' + root.value.querySelector('#otpInput').value

      }

    }

    return {
      magicLinkIsVerified,
      finishOtpSteps,
      root
    }

  },
  components: { FooterSection, NavigationLightSection }
}
</script>
