import axios from 'axios'
import { ref } from 'vue'
import { apiEndpoint } from "@/utils/config";
import { useOtpStore } from '@/stores/otp'
import { isLoading } from "@/utils/support"

const mangopayOnboardingError = ref(0)
const mangopayOnboardingItem = ref(0)
const mangopayOnboardingBankAccountItem = ref(0)
const mangopayRequiredDocuments = ref(0)
const mangopayDocumentId = ref(0)

const createMangopayOnboardingNaturalPerson = async ( sellerId, integratorId, firstName, lastName, email, dateOfBirth, nationality, countryOfResidence ) => {

    // Activate loading spinner
    isLoading.value = true

    mangopayOnboardingError.value = ''
    const otpStore = useOtpStore()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${otpStore.otpToken}`
    }

    await axios
        .post(apiEndpoint.value + 'payments/mangopay/onboard', {
            sellerUserId: { id: sellerId, integratorId: integratorId },
            onboardingType: 'NATURAL_USER',
            naturalUser: { firstName: firstName, lastName: lastName, email: email, dateOfBirth: dateOfBirth+'T00:00:00Z', nationality: nationality, countryOfResidence: countryOfResidence }},{ headers: axiosHeaders }
        ).then(response => {

            isLoading.value = false

            mangopayOnboardingItem.value = response.data

            getMangopayOnboardingStatus(sellerId, integratorId)

    }).catch(error => {

        isLoading.value = false

        mangopayOnboardingError.value = error.response.data.message

    })

}

const updateMangopayOnboardingNaturalPerson = async ( sellerId, integratorId, mangopayOnboardingItem, firstName, lastName, email, dateOfBirth, nationality, countryOfResidence ) => {

    // Activate loading spinner
    isLoading.value = true

    mangopayOnboardingError.value = ''

    const otpStore = useOtpStore()
    const naturalUser = ref(0)

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${otpStore.otpToken}`
    }

    // Pick up existing mangopay item as base
    naturalUser.value = mangopayOnboardingItem.value

    // Add the items that need to be patched
    naturalUser.value.firstName = firstName
    naturalUser.value.lastName = lastName
    naturalUser.value.email = email
    naturalUser.value.dateOfBirth = dateOfBirth+'T00:00:00Z'
    naturalUser.value.nationality = nationality
    naturalUser.value.countryOfResidence = countryOfResidence

    await axios
        .put(apiEndpoint.value + 'payments/mangopay/onboard', {
            sellerUserId: { id: sellerId, integratorId: integratorId },
            onboardingType: 'NATURAL_USER',
            naturalUser: naturalUser.value},{ headers: axiosHeaders }
        ).then(response => {

        isLoading.value = false
        mangopayOnboardingItem.value = response.data

    }).catch(error => {

        isLoading.value = false

        mangopayOnboardingError.value = error.response.data.message

    })

}

const createMangopayOnboardingLegalPerson = async( sellerId, integratorId, legalUserType, registeredName, email, companyNumber, companyVatNumber, addressLine1, postalCode, city, regionOrCounty, country, legalRepresentativeFirstName, legalRepresentativeLastName, legalRepresentativeEmail, legalRepresentativeDateOfBirth, legalRepresentativeNationality, legalRepresentativeCountryOfResidence ) => {

    // Activate loading spinner
    isLoading.value = true

    mangopayOnboardingError.value = ''

    const otpStore = useOtpStore()
    const registeredAddress = {addressLine1: addressLine1,postalCode:postalCode,city:city,regionOrCounty:regionOrCounty,country:country }
    const legalRepresentative = { firstName: legalRepresentativeFirstName, lastName: legalRepresentativeLastName, email: legalRepresentativeEmail, dateOfBirth: legalRepresentativeDateOfBirth+'T00:00:00Z', nationality: legalRepresentativeNationality, countryOfResidence: legalRepresentativeCountryOfResidence }

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${otpStore.otpToken}`
    }

    await axios
        .post(apiEndpoint.value + 'payments/mangopay/onboard', {
            sellerUserId: { id: sellerId, integratorId: integratorId },
            onboardingType: 'LEGAL_USER',
            legalUser: { legalUserType: legalUserType, registeredName: registeredName, companyNumber: companyNumber, vatNumber: companyVatNumber, email: email, registeredAddress: registeredAddress, legalRepresentative:legalRepresentative}},{ headers: axiosHeaders }
        ).then(response => {

        isLoading.value = false

        mangopayOnboardingItem.value = response.data
        getMangopayOnboardingStatus(sellerId, integratorId)

    }).catch(error => {

        isLoading.value = false

        mangopayOnboardingError.value = error.response.data.message

    })

}

const updateMangopayOnboardingLegalPerson = async ( sellerId, integratorId, mangopayOnboardingItem, beneficialOwnersArray, legalUserType, registeredName, email, companyNumber, companyVatNumber, addressLine1, postalCode, city, regionOrCounty, country, legalRepresentativeFirstName, legalRepresentativeLastName, legalRepresentativeEmail, legalRepresentativeDateOfBirth, legalRepresentativeNationality, legalRepresentativeCountryOfResidence  ) => {

    // Activate loading spinner
    isLoading.value = true

    mangopayOnboardingError.value = ''
    const otpStore = useOtpStore()
    const legalUser = ref(0)
    const registeredAddress = {addressLine1: addressLine1,postalCode:postalCode,city:city,regionOrCounty:regionOrCounty,country:country }
    const legalRepresentative = { firstName: legalRepresentativeFirstName, lastName: legalRepresentativeLastName, email: legalRepresentativeEmail, dateOfBirth: legalRepresentativeDateOfBirth+'T00:00:00Z', nationality: legalRepresentativeNationality, countryOfResidence: legalRepresentativeCountryOfResidence }

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${otpStore.otpToken}`
    }

    // Pick up existing mangopay item as base
    legalUser.value = mangopayOnboardingItem.value

    // Add the items that need to be legalUserType
    legalUser.value.legalUserType = legalUserType
    legalUser.value.registeredName = registeredName
    legalUser.value.companyNumber = companyNumber
    legalUser.value.vatNumber = companyVatNumber
    legalUser.value.email = email
    legalUser.value.registeredAddress = registeredAddress
    legalUser.value.legalRepresentative = legalRepresentative

    // Only pass beneficial owners if they have been added
    if (typeof beneficialOwnersArray !== 'undefined') {
        if (beneficialOwnersArray.length > 0) {

            // Needs work -> Additional check to filter out empty UBO's from front end
            Object.keys(beneficialOwnersArray).forEach(key => {

                if (typeof beneficialOwnersArray[key].id == 'undefined') {

                    beneficialOwnersArray.splice(key,1)
                }
            })

            if (typeof legalUser.value.uboDeclaration == 'undefined') {
                legalUser.value.uboDeclaration = {}
            }
            legalUser.value.uboDeclaration.beneficialOwners = beneficialOwnersArray
        }
    }

    await axios
        .put(apiEndpoint.value + 'payments/mangopay/onboard', {
            sellerUserId: { id: sellerId, integratorId: integratorId },
            onboardingType: 'LEGAL_USER',
            legalUser: legalUser.value},{ headers: axiosHeaders }
        ).then(response => {

            isLoading.value = false
            mangopayOnboardingItem.value = response.data
            getMangopayOnboardingStatus(sellerId, integratorId)

    }).catch(error => {

        isLoading.value = false

        mangopayOnboardingError.value = error.response.data.message

    })

}

const getMangopayOnboardingStatus = async ( sellerId, integratorId ) => {

    // Activate loading spinner
    isLoading.value = true

    const otpStore = useOtpStore()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'GET',
        Origin: 'localhost',
        Authorization: `${otpStore.otpToken}`
    }

    mangopayOnboardingItem.value = false

    if ( sellerId ) {

        await axios.get(apiEndpoint.value + 'payments/mangopay/onboard/' + sellerId + '/?integratorId=' + integratorId, {
                headers: axiosHeaders
            }
        ).then(response => {
            isLoading.value = false
            mangopayOnboardingItem.value = response.data
        }).catch(error => {
            isLoading.value = false
            //mangopayOnboardingError.value = error.response.data.message

            if (error.response.status == 500 || error.response.status == 403) {
                // test
            }

        });

    }

}

const addMangopayOnboardingBankAccount = async ( sellerId, integratorId, bankAccount ) => {

    // Activate loading spinner
    isLoading.value = true

    mangopayOnboardingError.value = ''
    const otpStore = useOtpStore()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${otpStore.otpToken}`
    }

    await axios.put(apiEndpoint.value + 'payments/mangopay/payouts/bankAccount', {
            id: sellerId, integratorId: integratorId},{ headers: axiosHeaders }
        ).then(response => {

            if (response) {
                // Do something
            }

            axios
                .post(apiEndpoint.value + 'payments/mangopay/payouts/bankAccount', {
                    sellerUserId: { id: sellerId, integratorId: integratorId },
                    bankAccount: bankAccount},{ headers: axiosHeaders }
                ).then(response => {

                mangopayOnboardingBankAccountItem.value = response.data

            }).catch(error => {

                mangopayOnboardingError.value = error.response.data.message
                return false

            })

            isLoading.value = false
        }).catch(error => {

            if (error) {
                // Do something
            }

            axios
                .post(apiEndpoint.value + 'payments/mangopay/payouts/bankAccount', {
                    sellerUserId: { id: sellerId, integratorId: integratorId },
                    bankAccount: bankAccount},{ headers: axiosHeaders }
                ).then(response => {

                mangopayOnboardingBankAccountItem.value = response.data

            }).catch(error => {

                mangopayOnboardingError.value = error.response.data.message
                return false

            })
        isLoading.value = false

        })

}

const addMangopayOnboardingDocument = async ( sellerId, integratorId, fileType, fileContents, documentType, mangopayDocumentIdToAddPageTo ) => {

    // Activate loading spinner
    isLoading.value = true

    mangopayOnboardingError.value = ''
    mangopayDocumentId.value = ''

    const otpStore = useOtpStore()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${otpStore.otpToken}`
    }

    await axios
        .post(apiEndpoint.value + 'payments/mangopay/onboard/document', {
            sellerUserId: { id: sellerId, integratorId: integratorId },
            fileType: fileType,fileContents: fileContents,documentType: documentType, mangopayDocumentId: mangopayDocumentIdToAddPageTo},{ headers: axiosHeaders }
        ).then(response => {

        isLoading.value = false

        mangopayDocumentId.value = response.data.id
        getMangopayOnboardingStatus(sellerId, integratorId)
        return true

    }).catch(error => {

        isLoading.value = false
        mangopayOnboardingError.value = error.response.data.message
        return false

    })

}

const submitKycDocument = ( sellerId, integratorId, mangopayKycDocumentId ) => {

    // Activate loading spinner
    isLoading.value = true

    mangopayOnboardingError.value = ''
    const otpStore = useOtpStore()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${otpStore.otpToken}`
    }

    axios
        .put(apiEndpoint.value + 'payments/mangopay/onboard/document/submit', {
            sellerUserId: { id: sellerId, integratorId: integratorId },
            mangopayKycDocumentId: mangopayKycDocumentId},{ headers: axiosHeaders }
        ).then(response => {

        isLoading.value = false

        if (response) {
            // Do something
        }

    }).catch(error => {

        isLoading.value = false

        mangopayOnboardingError.value = error.response.data.message

    })

}

const getMangopayOnboardingBankAccount = async ( sellerId, integratorId ) => {

    // Activate loading spinner
    isLoading.value = true

    if (sellerId) {
        const otpStore = useOtpStore()

        const axiosHeaders = {
            'Access-Control-Request-Method': 'GET',
            Origin: 'localhost',
            Authorization: `${otpStore.otpToken}`
        }

        await axios.get(apiEndpoint.value + 'payments/mangopay/payouts/bankAccount/' + sellerId + '?integratorId=' + integratorId, {
                headers: axiosHeaders
            }
        ).then(response => {
            isLoading.value = false
            mangopayOnboardingBankAccountItem.value = response.data
            //console.log(mangopayOnboardingBankAccountItem.value)
        }).catch(error => {
            isLoading.value = false

            if (error) {
                // Do something
            }

        });
    }

}

const getMangopayRequiredDocuments = async (  ) => {

    const otpStore = useOtpStore()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'GET',
        Origin: 'localhost',
        Authorization: `${otpStore.otpToken}`
    }

    await axios.get(apiEndpoint.value + 'payments/mangopay/onboard/requiredDocuments', {
            headers: axiosHeaders
        }
    ).then(response => {
        mangopayRequiredDocuments.value = response.data
        //console.log(mangopayOnboardingBankAccountItem.value)
    }).catch(error => {

        if (error) {
            // Do something
        }

    });

}

const createMangoPayUbo = async ( sellerId, integratorId, uboObject ) => {

    // Activate loading spinner
    isLoading.value = true

    const otpStore = useOtpStore()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${otpStore.otpToken}`
    }

    await axios.post(apiEndpoint.value + 'payments/mangopay/onboard/ubo', {
        sellerUserId: { id: sellerId, integratorId: integratorId },
        ubo: uboObject },{ headers: axiosHeaders }
    ).then(response => {

        isLoading.value = false
        getMangopayOnboardingStatus( sellerId, integratorId )

        if (response) {
            // Do something
        }

    }).catch(error => {

        isLoading.value = false
        mangopayOnboardingError.value = error.response.data.message

    })

}

const updateMangoPayUbo = async ( sellerId, integratorId, uboObject ) => {

    // Activate loading spinner
    isLoading.value = true

    const otpStore = useOtpStore()
    mangopayOnboardingError.value = ''
    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${otpStore.otpToken}`
    }

    await axios.put(apiEndpoint.value + 'payments/mangopay/onboard/ubo', {
        sellerUserId: { id: sellerId, integratorId: integratorId },
        ubo: uboObject },{ headers: axiosHeaders }
    ).then(response => {

        isLoading.value = false
        getMangopayOnboardingStatus( sellerId, integratorId )

        if (response) {
            // Do something
        }

    }).catch(error => {

        isLoading.value = false
        mangopayOnboardingError.value = error.response.data.message

    })

}

const submitMangoPayUbo = async ( sellerId, integratorId ) => {

    // Activate loading spinner
    isLoading.value = true

    const otpStore = useOtpStore()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${otpStore.otpToken}`
    }

    await axios.put(apiEndpoint.value + 'payments/mangopay/onboard/' + sellerId + '/uboDeclaration/submit?integratorId='+integratorId, {
        },{ headers: axiosHeaders }
    ).then(response => {

        isLoading.value = false
        getMangopayOnboardingStatus( sellerId, integratorId )

        if (response) {
            // Do something
        }

    }).catch(error => {

        isLoading.value = false
        mangopayOnboardingError.value = error.response.data.message

    })

}

export {
    getMangopayRequiredDocuments,
    createMangopayOnboardingNaturalPerson,
    createMangopayOnboardingLegalPerson,
    getMangopayOnboardingStatus,
    addMangopayOnboardingBankAccount,
    getMangopayOnboardingBankAccount,
    addMangopayOnboardingDocument,
    updateMangopayOnboardingNaturalPerson,
    submitKycDocument,
    updateMangopayOnboardingLegalPerson,
    createMangoPayUbo,
    updateMangoPayUbo,
    submitMangoPayUbo,
    mangopayOnboardingError,
    mangopayOnboardingItem,
    mangopayOnboardingBankAccountItem,
    mangopayRequiredDocuments,
    mangopayDocumentId
}