import { defineStore } from 'pinia'

export const useOtpStore = defineStore('otpToken', {

    state: () => {
        return { otpToken: localStorage.getItem('otpToken') }
    },
    actions: {
        update(otpToken) {
            this.otpToken = otpToken
            localStorage.setItem('otpToken', this.otpToken)
        },
    },
})