import axios from 'axios'
import { ref, shallowRef } from 'vue'
import {apiEndpoint } from "@/utils/config";
const vatItem = shallowRef(0)
const vatError = ref(0)
const expressFeeVatItem= shallowRef(0)
const expressFeeVatError = ref(0)

// Get content for a specific transaction
const getVatCalculation = async ( itemPriceWithoutVat, supplierChargesVat, supplierCountry, supplierRepresentsCompany, buyerCountry, buyerRepresentsCompany) => {

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost'
    }

    vatItem.value = false
    vatError.value = false

    await axios.post(apiEndpoint.value + 'finances/public/vat', {itemPriceWithoutVat:itemPriceWithoutVat, supplierChargesVat:true, supplierCountry:supplierCountry, supplierRepresentsCompany:supplierRepresentsCompany, buyerCountry:buyerCountry, buyerRepresentsCompany:buyerRepresentsCompany},{
            headers: axiosHeaders
        }
    ).then(response => {

        vatItem.value = response.data

    }).catch(error => {

        vatError.value = "There is an error"

        if (error.response.status == 500 || error.response.status == 403) { // @Todo change to 403 only after API fix

            //console.log(error)
            // Redirect to 403 page (work around vue router bc this function is called from within the router)
            //window.location.href = '/#/public/403/';

        }

    });

}

// Get content for a specific transaction's express fee
const getExpressFeeVatCalculation = async ( expressFeeWithoutVat, buyerCountry, buyerRepresentsCompany) => {

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost'
    }

    expressFeeVatItem.value = false
    expressFeeVatError.value = false

    await axios.post(apiEndpoint.value + 'finances/public/vat', {itemPriceWithoutVat:expressFeeWithoutVat, supplierChargesVat:true, supplierCountry:'NL', supplierRepresentsCompany:true, buyerCountry:buyerCountry, buyerRepresentsCompany:buyerRepresentsCompany},{
            headers: axiosHeaders
        }
    ).then(response => {

        expressFeeVatItem.value = response.data

    }).catch(error => {

        expressFeeVatError.value = "There is an error"

        if (error.response.status == 500 || error.response.status == 403) { // @Todo change to 403 only after API fix

            //console.log(error)
            // Redirect to 403 page (work around vue router bc this function is called from within the router)
            //window.location.href = '/#/public/403/';

        }

    });

}

export {
    vatItem,
    vatError,
    expressFeeVatError,
    expressFeeVatItem,
    getVatCalculation,
    getExpressFeeVatCalculation
}