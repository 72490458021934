<template>
  <NavigationSection />
  <LoaderSection v-show="isLoading" />
  <main ref="root">
    <div class="main-content-wrapper w-100">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2 bg-white rounded" id="mainContent">
            <div class="main-content pt-4 px-0 px-lg-3" v-if="transactionItem">

              <h1 class="fw-bold h3 text-dark"><font-awesome-icon :icon="['far', 'fa-compass']" />
                {{ transactionItem.digitalAsset.domain.domainName }}
                <span class="ms-2" role="button" @click="reloadTransaction()" title="Refresh transaction"><font-awesome-icon :icon="['fas', 'fa-rotate']" /></span>
              </h1>

              <nav aria-label="breadcrumb">
                <ol class="breadcrumb mt-3">
                  <li class="breadcrumb-item"><router-link :to="{ name: 'publicHome' }" class="text-dark">Efty Pay</router-link></li>
                  <li class="breadcrumb-item"><router-link :to="{ name: 'publicTransactions' }" class="text-dark">Transactions</router-link></li>
                  <li class="breadcrumb-item active" aria-current="page">
                    <span v-if="transactionItem">{{ transactionItem.digitalAsset.domain.domainName }}<span class="ms-2 badge display-4 bg-dark d-none d-lg-inline-block" v-if="currentStatus">{{ currentStatus.title }}</span></span>
                    <span v-else-if="transactionError">Not found</span>
                  </li>
                </ol>
              </nav>

              <!-- Notification for not fully onboarded sellers -->
              <div v-if="transactionItemContext == 'seller' && onboardingStatus < 2" class="mb-4 border-bottom pb-2">
                <div class="alert alert-danger" role="alert">
                  <div class="text-dark my-auto">
                    <font-awesome-icon :icon="['fas', 'circle-exclamation']" class="h3 m-0 me-3"/>
                    <span class="text-dark h5">IMPORTANT: In order to receive your payout when this transaction is complete, you need to complete your <router-link :to="{ name: 'publicGetStarted' }" class="text-dark">financial onboarding</router-link>. Start now to ensure a swift payout once your transaction is complete.</span>
                  </div>
                </div>
              </div>
              <!-- ./Notification for not fully onboarded sellers -->

              <div class="transaction-steps-wrapper d-flex mb-5 mt-5" v-if="currentStatus && transactionItemContext == 'buyer'">
                <span class="step" :class="{ active: currentStatus.order == 1, finish: currentStatus.order > 1  }">Transaction initiated</span>
                <span class="step" :class="{ active: currentStatus.order == 2 || currentStatus.order == 3, finish: currentStatus.order > 3 }">Payment</span>
                <span class="step" :class="{ active: currentStatus.order == 4 || currentStatus.order == 5 || currentStatus.order == 6 || currentStatus.order == 7, finish: currentStatus.order == 8 }">Domain transfer</span>
                <span class="step" :class="{ active: currentStatus.order == 8, finish: currentStatus.order == 8 }">Complete</span>
              </div>

              <div class="transaction-steps-wrapper d-flex mb-5 mt-5" v-else-if="currentStatus && transactionItemContext == 'seller'">
                <span class="step" :class="{ active: currentStatus.order == 1, finish: currentStatus.order > 1  }">Transaction initiated</span>
                <span class="step" :class="{ active: currentStatus.order == 2 || currentStatus.order == 3, finish: currentStatus.order > 3 }">Buyer Payment</span>
                <span class="step" :class="{ active: currentStatus.order == 4 || currentStatus.order == 5, finish: currentStatus.order > 5 }">Domain transfer</span>
                <span class="step" :class="{ active: currentStatus.order == 6 || currentStatus.order == 7 || currentStatus.order == 8, finish: currentStatus.order == 8 }">Payout to seller</span>
              </div>

                <div v-if="!transactionItem && !transactionError">
                  <ContentPlaceholder />
                </div>
                <div v-else-if="transactionError">
                  <ErrorMessage />
                </div>
                <div v-else-if="transactionItem">
                  <div class="nav-tabs-container position-relative" ref="tabContainer">
                    <ul class="nav nav-tabs flex-nowrap mt-4" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button class="nav-link active bg-white transaction-nav" id="message-tab" data-bs-toggle="tab" data-bs-target="#message" type="button" role="tab" aria-controls="message" aria-selected="true"><font-awesome-icon :icon="['far', 'comments']" class="me-2" /><span class="d-inline-block d-lg-none">Messages</span><span class="d-none d-lg-inline-block">Message center</span><span class="ms-3" @click="loadMessages()" title="Refresh messages"><font-awesome-icon :icon="['fas', 'fa-rotate']" /></span></button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link bg-white transaction-nav" id="transaction-tab" data-bs-toggle="tab" data-bs-target="#transaction-details" type="button" role="tab" aria-controls="transaction-details" aria-selected="true"><font-awesome-icon :icon="['far', 'fa-compass']" />Transaction</button>
                      </li>
                      <li class="nav-item" role="presentation" v-if="transactionItemContext == 'seller'">
                        <button class="nav-link bg-white transaction-nav" id="financial-tab" data-bs-toggle="tab" data-bs-target="#financial-details" type="button" role="tab" aria-controls="financial-details" aria-selected="true"><font-awesome-icon :icon="['far', 'fa-credit-card']" />Financial</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link bg-white transaction-nav" id="documents-tab" data-bs-toggle="tab" data-bs-target="#documents" type="button" role="tab" aria-controls="documents" aria-selected="true"><font-awesome-icon :icon="['fas', 'download']" />Documents</button>
                      </li>
                    </ul>
                  </div>

                  <div class="tab-content" id="transaction-lightbox-content">


                    <!-- Communication / message center -->
                    <div class="tab-pane fade show active" id="message" role="tabpanel" aria-labelledby="message-tab">
                      <div class="card border border-top-0 rounded-0 pt-2 bg-white">
                        <div class="card-body bg-white text-list-wrapper">
                          <div class="row">

                            <div class="col-12">


                              <!-- Default start message -->
                              <div class="w-lg-75">
                                <div class="row mt-2">

                                  <div class="col-2 text-center align-top p-0 mb-4">
                                    <img src="@/assets/logo-brand.png" class="img-responsive communication-avatar-eftypay">
                                  </div>

                                  <div class="col-10 p-0 pe-2">
                                    <div class="bg-message-other text-white p-3 rounded-end rounded-bottom">
                                      <p class="fw-bold d-block text-white">Transaction initiated <span v-if="transactionItem.utmParameters && transactionItemContext == 'seller'" class="text-white">through {{transactionItem.utmParameters.utmSource}}</span></p>
                                      <p class="pt-2 d-block text-white">On {{dayjs(transactionItem.created).format('MM-DD-YYYY')}} this transaction has been created<span v-if="transactionItem.utmParameters && transactionItemContext == 'seller'" class="text-white"> through {{transactionItem.utmParameters.utmSource}}</span>.</p>
                                      <p v-if="transactionItem.initiatedBy == 'SELLER'">The seller has agreed to Efty Pay B.V.'s Seller's Mandate, Sale and Purchase Agreement and General Terms & Conditions and Privacy Policy.</p>
                                      <p v-else-if="transactionItem.initiatedBy == 'BUYER'">The seller has agreed to Efty Pay B.V.'s Buyer's Mandate, Sale and Purchase Agreement and General Terms & Conditions and Privacy Policy.</p>
                                    </div>

                                    <div class="col-12 text-end">
                                      <p class="small text-extra-muted mt-2 mb-0">{{dayjs(transactionItem.created).format('MM-DD-YYYY HH:mm')}}</p>
                                    </div>

                                  </div>

                                </div>
                              </div>
                              <!-- ./Default start message -->

                              <img src="@/assets/loader.gif" class="img-fluid mt-5" v-if="!transactionMessageList">

                              <div class="mt-3 w-100 clearfix" v-for="messageItem in transactionMessageList" :key="messageItem.id">

                                <!-- Message where user is the author -->
                                <div class="w-lg-75 float-end" v-if="messageItem.author.id == otpUserProfile.id">
                                  <div class="row">

                                    <div class="col-11 col-lg-10 col-xl-11 p-0 pe-2">
                                      <div class="bg-light-grey p-3 rounded-start rounded-bottom bg-message-self text-white">

                                        <template v-for="(segment, index) in processTextSegments(messageItem.contents)" :key="index">
                                          <span v-if="segment.type === 'text'" class="text-white" v-html="segment.contents"></span>
                                          <span v-else href="#" class="text-white">
                                            {{ segment.contents }} <font-awesome-icon :icon="['fas', 'copy']" @click="copyToClipboard(segment.contents)" role="button" title="Copy to clipboard" />
                                          </span>
                                        </template>

                                      </div>

                                      <div class="col-12 text-end">
                                        <p class="small text-extra-muted mt-2 mb-0">{{dayjs(messageItem.date).format('MM-DD-YYYY HH:mm')}}</p>
                                      </div>

                                    </div>

                                  </div>
                                </div>
                                <!-- ./Message where user is the author -->

                                <!-- Message where Efty Pay is the author -->
                                <div class="w-lg-75 float-start" v-else-if="messageItem.author.id != transactionItem.buyer.id && messageItem.author.id != transactionItem.seller.id">
                                  <div class="row">

                                    <div class="col-2 text-center p-0 mb-4 align-top">
                                      <img src="@/assets/logo-brand.png" class="img-responsive communication-avatar-eftypay">
                                    </div>

                                    <div class="col-10 p-0 pe-2">
                                      <div class="bg-message-other text-white p-3 rounded-end rounded-bottom">
                                        <!--<span v-html="messageItem.contents" class="text-white"></span>-->

                                        <template v-for="(segment, index) in processTextSegments(messageItem.contents)" :key="index">
                                          <span v-if="segment.type === 'text'" class="text-white" v-html="segment.contents" ></span>
                                          <span v-else class="text-white" >
                                            {{ segment.contents }} <font-awesome-icon :icon="['fas', 'copy']" @click="copyToClipboard(segment.contents)" role="button" title="Copy to clipboard" />
                                          </span>
                                        </template>

                                      </div>

                                      <div class="col-12 text-end">
                                        <p class="small text-extra-muted mt-2 mb-0">{{dayjs(messageItem.date).format('MM-DD-YYYY HH:mm')}}</p>
                                      </div>

                                    </div>

                                  </div>

                                </div>
                                <!-- ./Message where Efty Pay is the author -->

                              </div>

                            </div>
                          </div>
                        </div>

                        <div class="card-footer bg-light">

                          <!-- Interaction widget loaded through dynamic component-->
                          <component :is="interactionComponentInTemplate" class="mb-4"/>
                          <!-- ./Interaction widget loaded through dynamic component-->

                          <!-- Create message -->

                          <div class="row w-100">

                            <div class="col-12 my-auto">

                              <form method="get" action="" id="messageForm">

                                <div class="position-relative">
                                  <textarea
                                      ref="messageInput"
                                      v-model="message"
                                      @input="resizeTextarea"
                                      @keydown="handleKeydown"
                                      name="messageInput"
                                      id="messageInput"
                                      class="form-control bg-white overflow-hidden py-3 ps-3"
                                      placeholder="Send a message to Efty Pay"
                                      aria-label=""
                                      required
                                      rows="1"
                                  ></textarea>
                                  <button
                                      class="btn position-absolute bottom-0 end-0 mb-1"
                                      @click="addMessage"
                                      type="button"
                                  >
                                    <font-awesome-icon :icon="['fas', 'circle-chevron-right']" class="text-success fs-2 fw-bold"/>
                                  </button>
                                </div>

                              </form>

                            </div>
                          </div>
                          <!-- ./Create message -->

                        </div>
                      </div>
                    </div>
                    <!-- ./Communication / message center -->


                    <!-- Transaction details -->
                    <div class="tab-pane fade" id="transaction-details" role="tabpanel" aria-labelledby="transaction-details-tab">
                      <div class="card border border-top-0 rounded-0">
                        <div class="card-body bg-white">
                          <div class="row">
                            <div class="col-12 col-lg-8">

                              <div class="row mb-2" v-if="transactionItem.digitalAsset">
                                <div class="col-4 fw-bold text-muted">
                                  Domain
                                </div>
                                <div class="col-8 fw-bold text-success">
                                  {{ transactionItem.digitalAsset.domain.domainName }}
                                </div>
                              </div>
                              <div class="row mb-2" v-if="transactionItem.buyer">
                                <div class="col-4 fw-bold text-muted">
                                  Buyer
                                </div>
                                <div class="col-8">
                                  {{showFullName(transactionItem.buyer.firstName, transactionItem.buyer.lastName)}}
                                  <span class="badge rounded-pill bg-buyer badge-you" v-if="transactionItem.buyer.id == otpUserId">You</span>
                                </div>
                              </div>
                              <div class="row mb-2" v-if="transactionItem.seller">
                                <div class="col-4 fw-bold text-muted">
                                  Seller
                                </div>
                                <div class="col-8">
                                  {{showFullName(transactionItem.seller.firstName, transactionItem.seller.lastName)}}
                                  <span class="badge rounded-pill bg-seller badge-you" v-if="transactionItem.seller.id == otpUserId">You</span>
                                </div>
                              </div>
                              <div class="row mb-2" v-if="currentStatus">
                                <div class="col-4 fw-bold text-muted">
                                  Status
                                </div>
                                <div class="col-8">
                                  {{ currentStatus.title }}
                                  <font-awesome-icon :icon="['fas', 'circle-question']" class="ms-1" data-bs-toggle="tooltip" data-bs-placement="top" v-bind:data-bs-original-title="currentStatus.desc" /><i class="fa-solid fa-circle-question ms-2" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                </div>
                              </div>
                              <div class="row mb-2">
                                <div class="col-4 fw-bold text-muted">
                                  Creation date
                                </div>
                                <div class="col-8 text-muted">
                                  {{dayjs(transactionItem.created).format('ddd MMMM D, YYYY')}}
                                </div>
                              </div>
                              <div class="row mb-2">
                                <div class="col-4 fw-bold text-muted">
                                  Last update
                                </div>
                                <div class="col-8 text-muted">
                                  {{dayjs(transactionItem.updated).format('ddd MMMM D, YYYY')}}
                                </div>
                              </div>
                              <div class="row mb-2" v-if="transactionItemContext == 'seller' && transactionItem.utmParameters">
                                <div class="col-4 fw-bold text-muted">
                                  Source
                                </div>
                                <div class="col-8 text-muted">
                                  <span v-if="transactionItem.utmParameters"><font-awesome-icon :icon="['fas', 'asterisk']" class="me-2" />{{transactionItem.utmParameters.utmSource}} ({{transactionItem.utmParameters.utmContent}})</span>
                                </div>
                              </div>

                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <!-- ./Transaction details -->



                    <!-- Financial details (SELLER ONLY) -->
                    <div class="tab-pane fade" id="financial-details" role="tabpanel" aria-labelledby="financial-details-tab" v-if="transactionItemContext == 'seller'">
                      <div class="card border border-top-0 rounded-0">
                        <div class="card-body bg-white">
                          <div class="row">
                            <div class="col-12 col-lg-8">

                              <!-- Sales price -->
                              <div class="row mb-2">
                                <div class="col-4">
                                  <p class="mb-1 fw-bold">Domain name {{ transactionItem.digitalAsset.domain.domainName }}</p>
                                </div>
                                <div class="col-8 text-muted align-content-end">
                                  {{ parseCurrency( transactionItem.currency , transactionItem.assetAmountExcVat/100) }}
                                </div>
                              </div>
                              <!-- ./Sales price -->

                              <!-- VAT on Sales price -->
                              <div class="row mb-2">
                                <div class="col-4 fw-bold text-muted">

                                  <span class="me-2">VAT</span>

                                  <span v-if="transactionItem.vatDetails && transactionItem.vatDetails.assetAmountChargeVat">
                                    ({{ transactionItem.vatDetails.vatPercentageOnAssetAmount/100 }}%)
                                  </span>

                                  <span v-else-if="transactionItem.vatDetails && transactionItem.vatDetails.assetAmountVatOutOfScope">
                                    Out of scope
                                  </span>

                                  <span v-else-if="transactionItem.vatDetails && transactionItem.vatDetails.assetAmountVatReverseCharge">
                                    Reverse charge
                                  </span>

                                  <span v-else-if="transactionItem.vatDetails && transactionItem.vatDetails.assetAmountVatOneStopShop">
                                    (OSS / One Stop Shop)
                                  </span>

                                </div>
                                <div class="col-8 text-muted">

                                  <span v-if="transactionItem.vatDetails && transactionItem.vatDetails.vatAmountOnAssetAmount > 0">
                                    {{ parseCurrency( transactionItem.currency , transactionItem.vatDetails.vatAmountOnAssetAmount/100) }}
                                  </span>

                                  <span v-else>
                                    -
                                  </span>

                                </div>
                              </div>
                              <!-- ./VAT on Sales price -->

                              <hr>

                              <!-- Success fee -->
                              <div v-if="transactionItem.commissionAmountExcVat > 0">


                                <!-- Success fee amount -->
                                <div class="row mb-2" v-if="transactionItem.commissionAmountExcVat > 0">
                                  <div class="col-4 fw-bold text-muted">
                                    Efty Pay Success fee
                                  </div>
                                  <div class="col-8 text-muted">
                                    {{ parseCurrency( transactionItem.currency , transactionItem.commissionAmountExcVat/100) }}
                                  </div>
                                </div>
                                <!-- ./Success fee amount -->


                                <!-- VAT on Success fee -->
                                <div class="row mb-2">

                                  <div class="col-4 fw-bold text-muted">

                                    <span class="me-2">VAT</span>

                                    <span v-if="transactionItem.vatDetails && transactionItem.vatDetails.commissionAmountChargeVat">
                                      ({{ transactionItem.vatDetails.vatPercentageOnCommissionAmount/100 }}%)
                                    </span>

                                    <span v-else-if="transactionItem.vatDetails && transactionItem.vatDetails.commissionAmountVatOutOfScope">
                                      Out of scope
                                    </span>

                                    <span v-else-if="transactionItem.vatDetails && transactionItem.vatDetails.commissionAmountVatReverseCharge">
                                      Reverse charge
                                    </span>

                                    <span v-else-if="transactionItem.vatDetails && transactionItem.vatDetails.commissionAmountVatOneStopShop">
                                      (OSS / One Stop Shop)
                                    </span>

                                  </div>

                                  <div class="col-8 text-muted">

                                    <span v-if="transactionItem.vatDetails && transactionItem.vatDetails.vatAmountOnCommissionAmount > 0">
                                      {{ parseCurrency( transactionItem.currency , transactionItem.vatDetails.vatAmountOnCommissionAmount/100) }}
                                    </span>

                                    <span v-else>
                                      -
                                    </span>

                                  </div>
                                </div>
                                <!-- ./VAT on Success fee -->

                                <hr>

                              </div>
                              <!-- ./ Success fee -->


                              <!-- Total to be received by seller-->
                              <div class="row mb-2">
                                <div class="col-4 fw-bold text-muted h4">
                                  Total
                                </div>
                                <div class="col-8 text-muted h4 fw-bold">
                                  {{ parseCurrency( transactionItem.currency , transactionItem.totalAmountSellerReceives/100) }}
                                </div>
                              </div>
                              <!-- ./Total to be received by seller -->


                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <!-- ./Financial details (SELLER ONLY) -->



                    <!-- Documents -->
                    <div class="tab-pane fade" id="documents" role="tabpanel" aria-labelledby="documents-tab">
                      <div class="card border border-top-0 rounded-0">
                        <div class="card-body bg-white">
                          <div class="row">
                            <div class="col-12">
                              <div  v-if="invoiceList.length == 0">
                                <div class="alert alert-info" role="alert">
                                  <div class="text-dark my-auto">
                                    <font-awesome-icon :icon="['fas', 'circle-exclamation']" class="h4 m-0 me-2"/>
                                    <span class="">Once the payment for the domain is approved, you will be able to download your invoice here.</span>
                                  </div>
                                </div>
                                <img src="@/assets/404.png" class="img-fluid">
                              </div>
                              <div v-else>
                                <div class="container mt-4">
                                  <div class="table-responsive">
                                    <table class="table table-light border table-hover">
                                      <thead>
                                      <tr>
                                        <th scope="col" class="bg-dark-subtle p-3">Type</th>
                                        <th scope="col" class="bg-dark-subtle p-3">Date</th>
                                        <th scope="col" class="bg-dark-subtle p-3">Supplier</th>
                                        <th scope="col" class="bg-dark-subtle p-3">Recipient</th>
                                        <th scope="col" class="bg-dark-subtle p-3">Total Amount</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr v-for="invoice in invoiceList" :key="invoice.id">
                                        <td class="bg-white align-middle p-3">
                                          <router-link :to="{ name: 'publicInvoice', params: { invoiceId: invoice.id, userId: otpUserId, transactionId: transactionItem.id } }" title="View document" class="d-block">
                                            <span v-if="invoice.type == 'BUYER_TRANSACTION_OVERVIEW' || invoice.type == 'SELLER_TRANSACTION_OVERVIEW'">Transaction overview</span>
                                            <span v-else-if="invoice.type == 'SUCCESS_FEE_INVOICE'">Invoice (Success fee)</span>
                                            <span v-else-if="invoice.type == 'EXPRESS_FEE_INVOICE'">Invoice (Express fee)</span>
                                          </router-link>
                                        </td>
                                        <td class="bg-white align-middle p-3">
                                          <router-link :to="{ name: 'publicInvoice', params: { invoiceId: invoice.id, userId: otpUserId } }" title="View document" class="d-block">
                                            {{ parseLocalDate(invoice.created) }}
                                          </router-link>
                                        </td>
                                        <td class="bg-white align-middle p-3">
                                          <router-link :to="{ name: 'publicInvoice', params: { invoiceId: invoice.id, userId: otpUserId } }" title="View document" class="d-block">
                                            <span v-if="invoice.supplier.id === eftyPayAdminId">Efty Pay B.V.</span>
                                            <span v-else>
                                              {{ showFullName(invoice.supplier.firstName, invoice.supplier.lastName) }}
                                              <span class="badge rounded-pill badge-you bg-dark" v-if="invoice.supplier.id == otpUserId">You</span>
                                            </span>
                                          </router-link>
                                        </td>
                                        <td class="bg-white align-middle p-3">
                                          <router-link :to="{ name: 'publicInvoice', params: { invoiceId: invoice.id, userId: otpUserId } }" title="View document" class="d-block">
                                            {{ showFullName(invoice.recipient.firstName, invoice.recipient.lastName) }}
                                            <span class="badge rounded-pill badge-you bg-dark" v-if="invoice.recipient.id == otpUserId">You</span>
                                          </router-link>
                                        </td>
                                        <td class="bg-white align-middle p-3">
                                          <router-link :to="{ name: 'publicInvoice', params: { invoiceId: invoice.id, userId: otpUserId } }" title="View document" class="d-block">
                                            {{ parseCurrency(invoice.lineItems[0].currency, invoice.totalAmount/100) }}
                                          </router-link>
                                        </td>
                                      </tr>
                                      </tbody>
                                    </table>

                                    <div class="p-3 my-4 bg-light text-center w-75 mx-auto" role="alert">
                                      <p class="m-0 small text-grey">Please be aware that Efty Pay provides invoices and transaction overviews as an additional service for your convenience. It is important to note that these documents are not substitutes for official tax documents. Buyers and sellers are solely responsible for complying with their local tax laws and regulations. This includes the calculation, reporting, and payment of any applicable taxes arising from transactions conducted through Efty Pay. Efty Pay does not provide tax advice and does not accept responsibility for any tax-related obligations.</p>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <!-- ./Documents -->


                  </div>

                  <!-- Legal documents -->
                  <div class="row">
                    <div class="col-12 col-lg-8 offset-lg-2 text-muted pt-4 pb-2 text-center">
                      <p class="text-grey">By using Efty Pay B.V.'s (online) platform and or its services I agree to Efty Pay B.V.'s <a href="https://efty.com/legal/buyers-mandate/" target="_blank" class="text-grey">Buyer's Mandate</a>, <a href="https://efty.com/legal/sellers-mandate/" target="_blank" class="text-grey">Seller's Mandate</a>, <a href="https://efty.com/legal/sale-and-purchase-agreement/" target="_blank" class="text-grey">Sale and Purchase Agreement</a> and <a href="https://efty.com/legal/general-terms-and-privacy-policy/" target="_blank" class="text-grey">General Terms &amp; Conditions and Privacy Policy</a></p>
                    </div>
                  </div>
                  <!-- ./Legal documents -->

                </div>

            </div>
            <div v-else-if="transactionError" class="py-4">
              <ErrorMessage />
            </div>
          </div>
        </div>
      </div>
      <FooterSection />
    </div>
    <StatusChangeModalSection />
  </main>
</template>

<script>
import ContentPlaceholder from '@/components/public/ContentPlaceholder.vue'
import FooterSection from '@/components/public/Footer.vue'
import NavigationSection from '@/components/public/Navigation.vue'
import StatusChangeModalSection from '@/components/public/StatusChangeModalSection.vue'
import ErrorMessage from '@/components/public/ErrorMessage.vue'
import LoaderSection from '@/components/public/LoadingSpinner.vue'
import { isLoading } from "@/utils/support";
import { showInitials, showFullName, parseCurrency, parseLocalDate } from "@/utils/support";
import dayjs from 'dayjs';
import { useRoute } from 'vue-router';
import { ref, watch } from 'vue'
import { getOnboardingStatus, onboardingStatus } from "@/utils/onboarding";
import {
  getTransactionItem,
  addTransactionMessageItem,
  getTransactionMessageList,
  transactionItem,
  transactionError,
  transactionMessageList, transactionItemContext, getTransactionItemContext,
} from "@/utils/transactions.public";
import {
  getInvoiceList,
  invoiceList,
  invoiceError
} from "@/utils/invoices.public";
import {
  getRegistrarStatus,
  getTransactionDeepStatusMapping,
} from "@/utils/transactions.common";
import {
  getOtpUserProfile,
  getOtpUserId,
  otpUserProfile,
  otpUserId
} from "@/utils/magiclink";
import {
  eftyIntegratorId,
  eftyPayAdminId,
  transactionStatusList,
  transactionSubStatusList
} from "@/utils/config";
import {
  getDeepInteractionWidgetComponent,
 interactionComponentInTemplate,
} from "@/utils/interactionWidget";

import { Tooltip } from 'bootstrap'
import Swal from "sweetalert2";

export default {

  /*inheritAttrs: true,*/

  mounted() {

    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })

    // Get ID for OTP logged in user
    getOtpUserId()

    // Get profile for OTP logged in user
    getOtpUserProfile()

  },

  setup() {

    const tabContainer = ref(null)

    const reloadTransaction = () => {

      // Load transaction item (prop transactionId is passed by the router)
      getTransactionItem( route.params.transactionId, eftyIntegratorId, otpUserId).then( () => {

        loadMessages()

      })

    }

    getTransactionItemContext()

    const currentStatus = ref({})

    watch(transactionItem, () => {

      interactionComponentInTemplate.value = false

      // Get status mapping
      currentStatus.value = getTransactionDeepStatusMapping( transactionItem.value.status, transactionItemContext.value )

      const registrarStatus = getRegistrarStatus(transactionItem.value)

      getDeepInteractionWidgetComponent(transactionItem.value.status, transactionItemContext.value, registrarStatus, transactionItem.value.metaData).then( ( interactionComponent ) => {

        if (interactionComponent && interactionComponent.interactionComponent) {
          import(`@/components/public/interaction/${interactionComponent.interactionComponent}`).then(val => {

            // Get interaction widget, if any
            interactionComponentInTemplate.value = val.default

          })
        }

      })

      if (otpUserId.value && transactionItem.value.id) {
        getInvoiceList( otpUserId.value, transactionItem.value.id)
      }

    })

    watch(otpUserProfile, context => {

      if (context) {
        getOnboardingStatus( context )
      }

    })

    watch(tabContainer, () => {

      if (root.value) {

        checkForScroll()

      }

    })

    const checkForScroll = () => {

      if (tabContainer.value) {
        //console.log(tabContainer.value.scrollWidth, tabContainer.value.clientWidth)
      }

    }

    // Use router to access the transaction ID
    const route = useRoute();
    const root = ref(null)
    const message = ref('');
    const messageInput = ref(null);
    const transactionDocumentList = ref('')

    // Get transaction item
    getTransactionItem( route.params.transactionId, eftyIntegratorId, otpUserId )

    const loadMessages = ( transactionId = route.params.transactionId, integratorId = eftyIntegratorId ) => {

      // Load messages
      getTransactionMessageList(transactionId, integratorId)

    }

    const addMessage = (  ) => {

      // Do HTML validation
      if (! root.value.querySelector('form#messageForm')[0].checkValidity()) {

        root.value.querySelector('form#messageForm')[0].reportValidity()

      }
      // Perform API call
      else {
        // Ensure line breaks are preserved
        const formattedMessage = message.value.replace(/\n/g, '<br>');

        // Add message
        addTransactionMessageItem(transactionItem.value.id, otpUserId.value, eftyPayAdminId, formattedMessage, eftyIntegratorId).then( () => {

          // Clear input field
          message.value = ''

          // Reset the height of the textarea
          const textarea = messageInput.value;
          textarea.style.height = 'auto';

        })

      }

    }

    const processTextSegments = ( message ) => {

      // Split the text into segments
      const segments = [];
      const regex = /%copy%(.*?)%copy%/g;
      let lastIndex = 0;
      let match;

      while ((match = regex.exec(message)) !== null) {
        // Add the text before the match
        if (match.index > lastIndex) {
          segments.push({ type: 'text', contents: message.substring(lastIndex, match.index) });
        }
        // Add the matched text
        segments.push({ type: 'copy', contents: match[1] });
        lastIndex = regex.lastIndex;
      }
      // Add any remaining text after the last match
      if (lastIndex < message.length) {
        segments.push({ type: 'text', contents: message.substring(lastIndex) });
      }

      return segments;

    }

    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text).then(() => {
        Swal.fire({
          customClass: {
            closeButton: 'btn btn-lg btn-secondary',
            confirmButton: 'btn btn-lg btn-success order-last',
            denyButton: 'btn btn-lg btn-secondary',
            cancelButton: 'btn btn-lg btn-secondary'
          },
          toast: true,
          position: 'bottom-right',
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 3000,
          icon: 'success',
          text: 'Copied to the clipboard!',
        })
      }).catch(err => {
        console.error('Could not copy text: ', err);
      });
      return false
    };

    const resizeTextarea = () => {
      const textarea = messageInput.value;
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    };

    const handleKeydown = (event) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault(); // Prevents the default behavior of Enter key
        addMessage(); // Calls the function to submit the message
      }
    };

    loadMessages()

    // expose to template and other options API hooks
    return {
      addMessage,
      showInitials,
      showFullName,
      dayjs,
      root,
      transactionItem,
      transactionStatusList,
      transactionSubStatusList,
      transactionError,
      transactionItemContext,
      parseCurrency,
      otpUserProfile,
      otpUserId,
      loadMessages,
      transactionDocumentList,
      transactionMessageList,
      interactionComponentInTemplate,
      isLoading,
      onboardingStatus,
      processTextSegments,
      copyToClipboard,
      currentStatus,
      reloadTransaction,
      tabContainer,
      invoiceList,
      invoiceError,
      parseLocalDate,
      eftyPayAdminId,
      resizeTextarea,
      message,
      messageInput,
      handleKeydown
    }

  },
  name: 'TransactionItemView',
  components: { LoaderSection, ErrorMessage, FooterSection, NavigationSection, ContentPlaceholder, StatusChangeModalSection }
}
</script>