<template>
  <div id="preloader">
    <div id="status">
      <div class="spinner">
        <div class="spinner-wrapper">
         <img src="@/assets/loader.gif" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoaderSection',
};
</script>