<template>
  <div class="modal fade" id="statusChangeModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content border-0">
         <div class="modal-header bg-eftext-white">
          <h5 class="modal-title text-white" id="transaction_title">Change status</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="alert alert-warning d-flex align-items-center" role="alert">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="bi flex-shrink-0 me-4 h2" />
            <div>
              Be careful! A status change has workflow items attached to it.
            </div>
          </div>
          <div class="text-center">
            <div class="w-100" v-for="transactionStatus in transactionStatusList" :key=transactionStatus.name >
              <button class="d-inline-block btn btn-primary mb-2" @click="changeTransactionStatus(transactionItem.id, transactionStatus.name)"><font-awesome-icon v-if="transactionStatus.faGroup && transactionStatus.faIcon" :icon="[transactionStatus.faGroup, transactionStatus.faIcon]" />{{ transactionStatus.title }}</button>
            </div>
          </div>
        </div>

        <div class="modal-footer border-0 bg-medium-grey">

          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><font-awesome-icon :icon="['fas', 'xmark']" />Close</button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { transactionItem, transactionStatusList,changeTransactionStatus } from "@/utils/transactions.admin";

export default {

  setup() {

    return {
      transactionItem,
      transactionStatusList,
      changeTransactionStatus
    }

  },
  name: 'StatusChangeModalSection',
  components: {  }
}
</script>