<template>

  <div class="alert alert-danger d-flex align-items-center" role="alert">
    <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="bi flex-shrink-0 me-4 h2" />
    <div>
      An error occurred while processing your request. Please try again. If the problem persists please contact Efty Pay and we will help you out as soon as possible.
    </div>
    <img src="@/assets/403.png" class="img-fluid error-img">
  </div>
</template>
<script>
export default {
  setup() {
  },
  name: 'ErrorMessage',
};
</script>