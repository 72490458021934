import axios from 'axios'
import { ref } from 'vue'
import { apiEndpoint } from "@/utils/config";
import { isLoading } from "@/utils/support"

const mangopayCheckoutError = ref(0)

const mpBranding= {
    fontFamily: 'Inter',
    fontSize: {
        primary: '16px',
        secondary: '14px',
    },
    borderType: 'round',
    colors: {
        primary: '#000000',
        secondary: '#545A61',
        accent: '#4E40EF',
        accentContrast: '#FFFFFF',
        border: '#E6E9EC',
        borderFocused: '#000000',
        error: '#EC0B43',
    },
    borderRadius: '10px',
    backgroundColor: '#ffffff',
    textColor: '#757474',
    lineHeight: '48px',
    variables: {
        eftypayPrimary: "#2076BB",
        eftypaySuccess: "#198754",
    },
    rules: {
        PayButton: `
        background-color: var(--eftypaySuccess);
        color:#ffffff;
        border-radius:5px;
        border-color:var(--eftypaySuccess);
        font-size:20px;
        &:hover {
         border-color:var(--eftypaySuccess);
        }
        & svg path {
          fill: #ffffff
        }
        `
    },
}

const createCardRegistration = ( cardType, encryptedData, iv, keyId ) => {

    // Activate loading spinner
    isLoading.value = true

    mangopayCheckoutError.value = ''

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost'
    }
    const encryptedLinkRequest = { encryptedData: encryptedData, iv: iv, keyId: keyId  }
    return axios
        .post(apiEndpoint.value + 'payments/public/mangopay/checkout/cardRegistration', {
            encryptedLinkRequest : encryptedLinkRequest, cardType: cardType},{ headers: axiosHeaders }
        ).then(response => {

            isLoading.value = false
            return response.data

        }).catch(error => {

            isLoading.value = false

            if (error.response.data.message.includes('AMEX')) {
                mangopayCheckoutError.value = 'American Express cards are not supported. Please go back and restart the payment wizzard with a different card';
            } else {
                mangopayCheckoutError.value = error.response.data.message;
            }

            if(error.response) {
                // Todo
            }

        })

}

const createPayIn = ( encryptedData, iv, keyId, cardId, browserInfo, ipAddress ) => {

    // Activate loading spinner
    isLoading.value = true

    mangopayCheckoutError.value = ''

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost'
    }
    const encryptedLinkRequest = { encryptedData: encryptedData, iv: iv, keyId: keyId  }

    return axios
        .post(apiEndpoint.value + 'payments/public/mangopay/checkout/directCardPayIn', {
            encryptedLinkRequest : encryptedLinkRequest, cardId: cardId ,browserInfo: browserInfo, ipAddress: ipAddress},{ headers: axiosHeaders }
        ).then(response => {

            isLoading.value = false
            return response.data

        }).catch(error => {

            isLoading.value = false
            mangopayCheckoutError.value = error.response.data.message

        })

}

const createBankWirePayIn = ( transactionId ) => {

    // Activate loading spinner
    isLoading.value = true

    mangopayCheckoutError.value = ''

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
    }

    return axios

        .post(apiEndpoint.value + 'payments/public/mangopay/checkout/bankWirePayIn', {
            transactionId: {id: transactionId} },{ headers: axiosHeaders }
        ).then(response => {

            isLoading.value = false
            return response.data

        }).catch(error => {

            isLoading.value = false
            mangopayCheckoutError.value = error.response.data.message

        })

}

export {
    createCardRegistration,
    createPayIn,
    createBankWirePayIn,
    mangopayCheckoutError,
    mpBranding
}