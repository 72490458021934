import { defineStore } from 'pinia'

export const useIntegratorStore = defineStore('integratorId', {

    state: () => {
        return { integratorId: localStorage.getItem('integratorId') }
    },
    actions: {
        update(integratorId) {
            this.integratorId = integratorId
            localStorage.setItem('integratorId', this.integratorId)
        },
    },
})