import {shallowRef} from 'vue'

const interactionComponentInTemplate = shallowRef(0)

const getDeepInteractionWidgetComponent = async (statusMap, context = 'admin', eftyPayHasAccountWithRegistrar = false, transactionMetaData = false ) => {

    // Check if the map with all statuses is present
    if (statusMap) {

        // All interaction items for the buyer
        if (context == 'buyer') {

            // Checkout not completed
            if (statusMap.INITIATE_TRANSACTION == 'INITIATED_OR_REQUESTED') {
                return { interactionComponent: 'BuyerCompleteCheckout.vue' };
            }

            // Checkout complete, but payment not done
            else if (statusMap.PAYMENT_TO_EFTY_PAY == 'INITIATED_OR_REQUESTED') {

                // Only show widget if this has not been confirmed (set in transaction's metadata)
                if (transactionMetaData.buyerConfirmedPayment != 'true') {
                    return {interactionComponent: 'BuyerConfirmPayment.vue'};
                }

            }

            // Transfer to buyer initiated
            else if (statusMap.TRANSFER_ASSET_TO_BUYER == 'INITIATED_OR_REQUESTED') {

                // Efty Pay has a holding account with the registrar
                if (eftyPayHasAccountWithRegistrar == 'eftyPayHasAccountWithRegistrar') {
                    return { interactionComponent: 'BuyerConfirmAccountDetailsSent.vue' };
                }

            }

            // Transfer to buyer confirmed by Efty Pay
            else if (statusMap.TRANSFER_ASSET_TO_BUYER == 'CONFIRMED') {

                // Efty Pay has a holding account with the registrar
                if (eftyPayHasAccountWithRegistrar != 'eftyPayHasAccountWithRegistrar') {
                    return { interactionComponent: 'BuyerConfirmTransferWithAuthCode.vue' };
                }

            }

        } else if (context == 'seller') {

            // Transfer to seller initiated
            if (statusMap.TRANSFER_ASSET_TO_EFTY_PAY == 'INITIATED_OR_REQUESTED') {

                // Efty Pay has a holding account with the registrar
                if (eftyPayHasAccountWithRegistrar == 'eftyPayHasAccountWithRegistrar') {
                    return { interactionComponent: 'SellerConfirmTransfer.vue' };
                }

                // Efty Pay does NOT have a holding account with the registrar
                else if (eftyPayHasAccountWithRegistrar != 'eftyPayHasAccountWithRegistrar') {
                    return { interactionComponent: 'SellerRequestAuthCode.vue' };
                }

            }

        } else if (context == 'admin') {

            // Transfer to seller initiated
            if (statusMap.PAYMENT_TO_EFTY_PAY == 'CONFIRMED') {
                return { interactionComponent: 'AdminConfirmPayment.vue' };
            }

            // Transfer to Efty Pay initiated
            else if (statusMap.PAYMENT_TO_EFTY_PAY == 'APPROVED' && statusMap.TRANSFER_ASSET_TO_EFTY_PAY == 'SUB_STATUS_NONE') {
                return { interactionComponent: 'AdminRequestTransfer.vue' };
            }

            // Transfer to Efty Pay confirmed, needs approval
            else if (statusMap.PAYMENT_TO_EFTY_PAY == 'APPROVED' && statusMap.TRANSFER_ASSET_TO_EFTY_PAY == 'CONFIRMED') {

                // Efty Pay does NOT have a holding account with the registrar
                if (eftyPayHasAccountWithRegistrar != 'eftyPayHasAccountWithRegistrar') {
                    return { interactionComponent: 'AdminApproveTransferWithAuthCode.vue' };
                } else if (eftyPayHasAccountWithRegistrar == 'eftyPayHasAccountWithRegistrar') {
                    return { interactionComponent: 'AdminApproveTransfer.vue' };
                }

            }

            // Transfer to Efty Pay approved, initiate transfer-out
            else if (statusMap.TRANSFER_ASSET_TO_EFTY_PAY == 'APPROVED' && statusMap.TRANSFER_ASSET_TO_BUYER == 'SUB_STATUS_NONE') {
                return { interactionComponent: 'AdminInitiateTransferToBuyer.vue' };
            }

            // Transfer to buyer initiated, request auth code (if applicable)
            else if (statusMap.TRANSFER_ASSET_TO_EFTY_PAY == 'APPROVED' && statusMap.TRANSFER_ASSET_TO_BUYER == 'INITIATED_OR_REQUESTED') {

                // Efty Pay does NOT have a holding account with the registrar
                if (eftyPayHasAccountWithRegistrar != 'eftyPayHasAccountWithRegistrar') {
                    return { interactionComponent: 'AdminSendAuthCodeToBuyer.vue' };
                }

            }

            // Transfer to Efty Pay approved, confirm transfer-in from buyer
            else if (statusMap.TRANSFER_ASSET_TO_EFTY_PAY == 'APPROVED' && statusMap.TRANSFER_ASSET_TO_BUYER == 'CONFIRMED') {

                // Efty Pay does NOT have a holding account with the registrar
                if (eftyPayHasAccountWithRegistrar == 'eftyPayHasAccountWithRegistrar') {
                    return { interactionComponent: 'AdminApproveTransferOutWithRegistrarDetailsFromBuyer.vue' };
                }

            }

            // Transfer to buyer initiated, initiate payout to seller
            else if (statusMap.TRANSFER_ASSET_TO_EFTY_PAY == 'APPROVED' && statusMap.PAYOUT_TO_SELLER == 'SUB_STATUS_NONE' && statusMap.TRANSFER_ASSET_TO_BUYER != 'SUB_STATUS_NONE') {
                return { interactionComponent: 'AdminInitiatePayoutToSeller.vue' };
            }

            // Transfer to buyer initiated, initiate payout to seller
            else if (statusMap.TRANSFER_ASSET_TO_EFTY_PAY == 'APPROVED' && statusMap.PAYOUT_TO_SELLER == 'INITIATED_OR_REQUESTED') {
                return { interactionComponent: 'AdminConfirmPayoutToSeller.vue' };
            }

            // Transfer to buyer initiated, initiate payout to seller
            else if (statusMap.TRANSFER_ASSET_TO_EFTY_PAY == 'APPROVED' && statusMap.PAYOUT_TO_SELLER == 'CONFIRMED') {
                return { interactionComponent: 'AdminApprovePayoutToSeller.vue' };
            }

            // Last 3 clicks to completely close a transaction
            else if (statusMap.TRANSFER_ASSET_TO_EFTY_PAY == 'APPROVED' && statusMap.PAYOUT_TO_SELLER == 'APPROVED' && statusMap.TRANSACTION_COMPLETE != 'APPROVED') {
                return { interactionComponent: 'AdminCompleteTransaction.vue' };
            }

        }

    }

}

export {
    getDeepInteractionWidgetComponent,
    interactionComponentInTemplate
}