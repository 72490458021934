<template>
  <main>
    <NavigationLightSection />
    <div class="main-content-wrapper w-100">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2 bg-white rounded" id="mainContent">
            <div class="main-content p-3 pt-4 text-center">
              <h1 class="fw-bold h2 text-dark"><font-awesome-icon :icon="['fas', 'xmark']" class="me-2"/>404 - Page not found</h1>
              <p class="lead">The page you requested can't be found. Feel free to navigate to the <router-link :to="{ name: 'publicHome' }" class="text-dark">homepage</router-link> and give it another go!</p>
              <img src="@/assets/404.png" class="img-fluid ">
            </div>
          </div>
        </div>
      </div>
      <FooterSection />

    </div>

  </main>

</template>

<script>
import FooterSection from '@/components/public/Footer.vue'
import NavigationLightSection from "@/components/public/NavigationLight.vue";

export default {
  setup () {
  },
  name: 'PublicNotFoundView',
  components: { FooterSection, NavigationLightSection }
}
</script>
