// Vue basics
import { ref } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

// Auth0
import {authGuard } from "@auth0/auth0-vue";

// Admin views
import AdminTransactionItemView from '@/views/admin/TransactionItemView.vue'
import AdminTransactionListView from '@/views/admin/TransactionListView.vue'
import AdminHomeView from '@/views/admin/HomeView.vue'
import AdminCallbackView from "@/views/admin/CallbackView.vue";
import AdminSignOutView from '@/views/admin/SignOutView.vue'
import AdminNoAccessView from '@/views/admin/NoAccessView.vue'
import AdminUserListView from '@/views/admin/UserListView.vue'
import AdminUserItemView from '@/views/admin/UserItemView.vue'
import AdminInvoiceItemView from "@/views/admin/invoiceItemView.vue";

// Public views
import PublicHomeView from '@/views/public/HomeView.vue'
import PublicTransactionsView from '@/views/public/TransactionsView.vue'
import PublicTransactionItemView from '@/views/public/TransactionItemView.vue'
import PublicSupportView from '@/views/public/SupportView.vue'
import PublicNotFoundView from '@/views/public/NotFoundView.vue'
import PublicMagicLinkView from '@/views/public/MagicLinkView.vue'
import PublicOtpView from '@/views/public/OtpView.vue'
import PublicNoAccessView from '@/views/public/NoAccessView.vue'
import PublicLanderView from '@/views/public/LanderView.vue'
import PublicCheckoutView from "@/views/public/CheckoutView.vue";
import OtpInvalidView from "@/views/public/OtpInvalidView.vue";
import MagicLinkInvalidView from "@/views/public/MagicLinkInvalidView.vue";
import OtpExpiredView from "@/views/public/OtpExpiredView.vue";
import publicSignOutView from "@/views/public/SignOutView.vue";
import GetStartedView from "@/views/public/GetStartedView.vue";
import PublicSettingsView from "@/views/public/SettingsView.vue";
import PublicInvoiceItemView from "@/views/public/invoiceItemView.vue";

// Config and helpers
import { userRoleList } from '@/utils/config'
import {magicLinkGuard} from "@/utils/magiclink";

// Default HTML title
const meta_default_title = 'Efty Pay';
const meta_default_postfix = ' | Efty Pay';

const routes = [

  /* General routes */
  {
    path: "/:catchAllPublic(.*)",
    name: "publicNotFound",
    component: PublicNotFoundView,
    meta: {
      title: 'Page not found'
    },
  },

  /* Public routes */
  {
    path: "/public/403",
    name: "publicNoAccess",
    component: PublicNoAccessView,
    meta: {
      title: 'No access'
    },
  },
  {
    path: "/public/401",
    name: "publicOtpExpired",
    component: OtpExpiredView,
    meta: {
      title: 'Access not allowed'
    },
  },
  {
    path: '/public/getstarted',
    name: 'publicGetStarted',
    component: GetStartedView,
    meta: {
      otpRequired: true,
      title: 'Get Started with Efty Pay'
    },
  },
  {
    path: '/public/checkout',
    name: 'PublicCheckout',
    component: PublicCheckoutView,
    meta: {
      otpRequired: false,
      title: 'Checkout'
    },
    props: true,
  },
  {
    path: "/public/",
    name: "publicLander",
    component: PublicLanderView,
    meta: {
      title: 'Welcome to Efty Pay'
    },
  },
  {
    path: '/',
    name: 'publicHome',
    component: PublicHomeView,
    meta: {
      otpRequired: true,
      title: 'Dashboard'
    },
  },
  {
    path: '/public/settings/:tabId?',
    name: 'publicSettings',
    component: PublicSettingsView,
    meta: {
      otpRequired: true,
      title: 'Settings'
    },
    props: true,
  },
  {
    path: '/public/sign-out',
    name: 'publicSignOut',
    component: publicSignOutView,
    meta: {
      title: 'Sign out'
    },
  },
  {
    path: '/public/magic-link',
    name: 'publicMagicLink',
    component: PublicMagicLinkView,
    meta: {
      title: 'OTP'
    },
  },
  {
    path: '/public/magic-link-invalid',
    name: 'publicMagicLinkInvalid',
    component: MagicLinkInvalidView,
    meta: {
      title: 'Invalid magic link'
    },
  },
  {
    path: '/public/otp',
    name: 'publicOtp',
    component: PublicOtpView,
    meta: {
      title: 'OTP'
    },
  },
  {
    path: '/public/otp-invalid',
    name: 'publicOtpInvalid',
    component: OtpInvalidView,
    meta: {
      title: 'Invalid OTP'
    },
  },
  {
    path: '/public/transactions',
    name: 'publicTransactions',
    component: PublicTransactionsView,
    meta: {
      otpRequired: true,
      title: 'Transactions'
    },
  },
  {
    path: '/public/transaction/:transactionId',
    name: 'publicTransaction',
    component: PublicTransactionItemView,
    meta: {
      otpRequired: true,
      title: 'Transaction'
    },
    props: true,
  },
  {
    path: '/public/invoice/:userId/:transactionId/:invoiceId',
    name: 'publicInvoice',
    component: PublicInvoiceItemView,
    meta: {
      otpRequired: true,
      title: 'Invoice'
    },
    props: true,
  },
  {
    path: '/public/support',
    name: 'publicSupport',
    component: PublicSupportView,
    meta: {
      otpRequired: true,
      title: 'Support'
    },
  },

  /* Admin routes */
  {
    path: '/admin',
    name: 'adminHome',
    component: AdminHomeView,
    beforeEnter: authGuard
  },
  {
    path: "/admin/callback",
    name: "adminCallback",
    component: AdminCallbackView,
    beforeEnter: authGuard
  },
  {
    path: '/admin/transaction/:transactionId',
    name: 'adminTransaction',
    component: AdminTransactionItemView,
    meta: {
      title: 'Transaction'
    },
    props: true,
    beforeEnter: authGuard
  },
  {
    path: '/admin/invoice/:userId/:transactionId/:invoiceId',
    name: 'adminInvoice',
    component: AdminInvoiceItemView,
    meta: {
      title: 'Invoice'
    },
    props: true,
    beforeEnter: authGuard
  },
  {
    path: '/admin/no-access',
    name: 'adminNoAccess',
    component: AdminNoAccessView,
    meta: {
      title: 'No Access'
    }
  },
  {
    path: '/admin/transactions',
    name: 'adminTransactions',
    component: AdminTransactionListView,
    meta: {
      title: 'Transactions'
    },
    beforeEnter: authGuard
  },
  {
    path: '/admin/users',
    name: 'adminUsers',
    component: AdminUserListView,
    meta: {
      title: 'Users'
    },
    beforeEnter: authGuard
  },
  {
    path: '/admin/user/:userId',
    name: 'adminUser',
    component: AdminUserItemView,
    meta: {
      title: 'User'
    },
    props: true,
    beforeEnter: authGuard
  },
  {
    path: '/admin/signout',
    name: 'adminSignout',
    component: AdminSignOutView,
    meta: {
      title: 'Sign out'
    },
    beforeEnter: authGuard
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to) => {

  // Check if this page is only accessible for users with admin role assigned
  if (to.meta.is_admin == true) {

    // Get user role from local storage
    const userRole = localStorage.getItem('role')
    const userRoleDecoded = ref('')

    // Loop through decoded user roles and match the user role in local storage
    userRoleList.value.forEach((value) => {

      if (value.code == userRole) {

        userRoleDecoded.value = value.name

      }

    });

    if (userRoleDecoded.value != 'admin') {
      router.push({ path: '/no-access', replace: true })
    } else {
      //const auth0token = localStorage.getItem('auth0token')
      //console.log(auth0token)
    }

  }

  else if (to.meta.otpRequired == true) {

    magicLinkGuard()

  }

  document.title = to.meta.title ? to.meta.title + meta_default_postfix : meta_default_title;

})

export { router }