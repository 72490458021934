<template>
  <main>
    <NavigationLightSection />
    <div class="main-content-wrapper w-100">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2 bg-white rounded" id="mainContent">
            <div class="main-content p-3 pt-4 text-center">
              <div class="row mt-2">

                <div class="col-12 col-lg-8 offset-lg-2 mb-3">
                  <h1 class="fw-bold h2 text-dark">Welcome to Efty Pay</h1>
                  <p class="lead">You need to sign in to your Efty Pay Account to access your dashboard.</p>
                  <p class="lead">Please <span class="fw-bold">check your e-mail</span> and re-open the Magic Link sent to you. You can also access Efty Pay from your <a href="https://app.efty.com/efty-pay/transactions/">Efty Investor account</a>.</p>
                  <div class="position-relative border border-secondary-subtle p-3 mt-5">
                    <span class="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-secondary-subtle text-muted px-3 py-2">Please check your e-mail for your Magic Link</span>
                    <img src="@/assets/magiclink.png" class="img-fluid mt-3">
                  </div>

                </div>
              </div>

              <div class="row mt-5">

                <div class="col-12 col-md-6 col-lg-4 mb-3">
                  <div class="card border-0 bg-transparent">
                    <div class="w-100 text-center">
                      <img src="@/assets/blog/1.jpg" class="card-img-top blog-img w-100" alt="">
                    </div>
                    <div class="card-body border border-top-0 rounded-bottom">
                      <h5 class="card-title fw-bold text-dark">Buying domains made easy</h5>
                      <p class="card-text">This guide outlines the simple and secure process of purchasing domain names from Efty.com.</p>
                      <a href="https://efty.com/knowledge/buying-domains-made-easy/" target="blank" class="">Read more</a>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-6 col-lg-4 mb-3">
                  <div class="card border-0 bg-transparent">
                    <div class="w-100 text-center">
                      <img src="@/assets/blog/2.jpg" class="card-img-top blog-img w-100" alt="">
                    </div>
                    <div class="card-body border border-top-0 rounded-bottom">
                      <h5 class="card-title fw-bold text-dark">The Power of the Perfect Domain Name</h5>
                      <p class="card-text">Explore why your business needs the perfect web domain name.</p>
                      <a href="https://efty.com/knowledge/the-power-of-the-perfect-domain-name/" target="blank" class="">Read more</a>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-6 col-lg-4 mb-3">
                  <div class="card border-0 bg-transparent">
                    <div class="w-100 text-center">
                      <img src="@/assets/blog/3.jpg" class="card-img-top blog-img w-100" alt="">
                    </div>
                    <div class="card-body border border-top-0 rounded-bottom">
                      <h5 class="card-title fw-bold text-dark">Topnotch support when you need it</h5>
                      <p class="card-text">Our support team is available to help you. You can count on our team of experts.</p>
                      <a href="https://efty.com/support/" target="blank" class="">Get in touch</a>
                    </div>
                  </div>

                </div>
              </div>


            </div>
          </div>
        </div>

      </div>
      <FooterSection />

    </div>

  </main>

</template>

<script>
import FooterSection from '@/components/public/Footer.vue'
import NavigationLightSection from "@/components/public/NavigationLight.vue";

export default {
  setup () {
  },
  name: 'OtpExpiredView',
  components: { FooterSection, NavigationLightSection }
}
</script>
