<template>
  <NavigationSection />
  <LoaderSection v-show="isLoading" />
  <main>
    <div class="main-content-wrapper w-100">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2 bg-white rounded" id="mainContent">
            <div class="main-content px-0 px-lg-3 pt-4">
              <h1 class="fw-bold h3 text-dark"><font-awesome-icon :icon="['far', 'fa-compass']" />
                Transactions
                <span class="ms-2" role="button" @click="loadTransactions()" title="Refresh transactions"><font-awesome-icon :icon="['fas', 'fa-rotate']" /></span>
              </h1>

              <nav aria-label="breadcrumb">
                <ol class="breadcrumb mt-3">
                  <li class="breadcrumb-item"><router-link :to="{ name: 'publicHome' }" class="text-dark">Efty Pay</router-link></li>
                  <li class="breadcrumb-item">Transactions</li>
                </ol>
              </nav>

              <div class="bg-white border p-3 mt-4">

                <!-- Transaction list -->
                <div class="table-responsive bg-white">
                  <table class="table table-responsive table-borderless bg-white">
                    <thead class="">
                    <tr>
                      <th scope="col" width="20%" class="text-muted fw-bold lead bg-white">Domain</th>
                      <th scope="col" width="10%" class="text-muted fw-bold lead bg-white">Price</th>
                      <th scope="col" width="5%" class="text-muted fw-bold lead bg-white">Buyer</th>
                      <th scope="col" width="5%" class="text-muted fw-bold lead bg-white">Seller</th>
                      <th scope="col" width="10%" class="text-muted fw-bold lead bg-white">Date</th>
                      <th scope="col" width="25%" class="text-muted fw-bold lead bg-white">Status</th>
                    </tr>
                    <tr>
                      <td colspan="7" class="bg-white"></td>
                    </tr>
                    </thead>
                    <tbody>
                      <tr v-if="!transactionList && !transactionError">
                        <td colspan="7" class="bg-white"><ContentPlaceholder /></td>
                      </tr>
                      <tr v-else-if="transactionError">
                        <td colspan="7" class="bg-white">
                          <ErrorMessage />
                        </td>
                      </tr>
                      <tr v-else-if="!transactionList.length">
                        <td colspan="7" class="text-center bg-white">
                          <img src="@/assets/notfound.png" class="img-fluid ">
                          <div class="w-100 text-center">
                            <font-awesome-icon :icon="['fas', 'xmark']" class="me-2" />No transactions found
                          </div>
                        </td>
                      </tr>
                      <tr v-for="transactionItem in transactionList" :key="transactionItem.id">
                        <template v-if="transactionItem.buyer">
                          <td class=" my-auto bg-white">
                            <router-link :to="{ name: 'publicTransaction', params: { transactionId: transactionItem.id } }" title="View transaction">
                              <span class="text-success fw-bold lead p-o m-0 w-100">{{transactionItem.digitalAsset.domain.domainName}}</span><br>
                              <!-- Only show UTM details to seller -->
                              <span class="" v-if="transactionItem.utmParameters && transactionItem.seller.id == otpUserId"><font-awesome-icon :icon="['fas', 'asterisk']" class="me-2" />{{transactionItem.utmParameters.utmSource}}</span>
                            </router-link>
                          </td>
                          <td class=" lead my-auto bg-white">
                            <router-link :to="{ name: 'publicTransaction', params: { transactionId: transactionItem.id } }" title="View transaction">
                              {{ parseCurrency( transactionItem.currency , transactionItem.assetAmountExcVat/100) }}
                            </router-link>
                          </td>
                          <td class=" my-auto text-center bg-white">
                            <router-link :to="{ name: 'publicTransaction', params: { transactionId: transactionItem.id } }" title="View transaction">
                              <div class="position-relative" v-if="transactionItem.buyer">
                                <span class="bg-dark-grey text-white rounded px-2 py-1 lead">{{showInitials(transactionItem.buyer.firstName, transactionItem.buyer.lastName).toUpperCase()}}</span>
                                <span class="position-absolute bottom-0 start-0 badge rounded-pill bg-buyer badge-you" v-if="transactionItem.buyer.id == otpUserId">You</span>
                              </div>
                            </router-link>
                          </td>
                          <td class=" my-auto text-center bg-white" v-if="transactionItem.seller">
                            <router-link :to="{ name: 'publicTransaction', params: { transactionId: transactionItem.id } }" title="View transaction">
                              <div class="position-relative">
                                <span class="bg-dark-grey text-white rounded px-2 py-1 lead">{{showInitials(transactionItem.seller.firstName, transactionItem.seller.lastName).toUpperCase()}}</span>
                                <span class="position-absolute bottom-0 start-0 badge rounded-pill bg-seller badge-you" v-if="transactionItem.seller.id == otpUserId">You</span>
                              </div>
                            </router-link>
                          </td>
                          <td class="lead my-auto bg-white">
                            <router-link :to="{ name: 'publicTransaction', params: { transactionId: transactionItem.id } }" title="View transaction">
                              {{dayjs(transactionItem.created).format('ddd MMMM D, YYYY')}}
                            </router-link>
                          </td>
                          <td class="lead my-auto bg-white no-max-width">
                            <router-link :to="{ name: 'publicTransaction', params: { transactionId: transactionItem.id } }" title="View transaction">
                              <span class="float-start">{{ getTransactionDeepStatusMapping(transactionItem.status, transactionItem.seller.id == otpUserId ? 'seller' : 'buyer').title }}</span>

                              <!-- Display icon if admin needs to perform an action -->
                              <component :is="componentInteractionMap[transactionItem.id]" v-if="componentInteractionMap[transactionItem.id]" class="position-absolute"><font-awesome-icon :icon="['fas', 'circle-exclamation']" class="text-danger h5 m-0 ms-2"/></component>

                            </router-link>
                          </td>
                        </template>
                      </tr>
                    </tbody>
                  </table>

                </div>
                <!-- / Transaction list -->

              </div>


              </div>
            </div>
          </div>
        </div>
      <FooterSection />

      </div>

  </main>

</template>

<script>
import FooterSection from '@/components/public/Footer.vue'
import NavigationSection from "@/components/public/Navigation.vue";
import LoaderSection from "@/components/public/LoadingSpinner.vue";
import ErrorMessage from "@/components/public/ErrorMessage.vue";
import ContentPlaceholder from "@/components/public/ContentPlaceholder.vue";
import { isLoading } from "@/utils/support";
import dayjs from 'dayjs';
import {
  getTransactionList,
  transactionList,
  transactionStatusList,
  transactionError
} from "@/utils/transactions.public";

import { parseCurrency, showInitials } from "@/utils/support";
import {getOtpUserId, otpUserId } from "@/utils/magiclink";
import {getRegistrarStatus, getTransactionDeepStatusMapping} from "@/utils/transactions.common";
import {ref} from "vue";
import {eftyIntegratorId} from "@/utils/environment.config";
import {getDeepInteractionWidgetComponent} from "@/utils/interactionWidget";

export default {

  name: 'PublicTransactionsView',

  setup() {

    const componentInteractionMap = ref({});

    // Get ID for OTP logged in user
    getOtpUserId()

    // Load transactions
    const loadTransactions = () => {
      getTransactionList(eftyIntegratorId).then(() => {

        // For each transaction, check if there are interactive components
        if (transactionList.value) {
          transactionList.value.forEach(record => {
            fetchComponentForRecord(record);
          });
        }

      })
    }

    loadTransactions()

    // Function used to map transactionItems in a list and check if there are interaction components attached to it
    const fetchComponentForRecord = async (transactionItem) => {

      // Check if EP has a holding account with the registrar
      const registrarStatus = getRegistrarStatus(transactionItem);

      // Get an interaction widget, if any
      const response = await getDeepInteractionWidgetComponent(transactionItem.status, transactionItem.seller.id == otpUserId.value ? 'seller' : 'buyer', registrarStatus,transactionItem.metaData)

      // Set it into the array with transaction IDs
      if (response) {
        componentInteractionMap.value[transactionItem.id] = response.interactionComponent;
      }
    };

    // expose to template and other options API hooks
    return {
      parseCurrency,
      showInitials,
      otpUserId,
      transactionList,
      transactionStatusList,
      transactionError,
      dayjs,
      isLoading,
      getTransactionDeepStatusMapping,
      componentInteractionMap,
      loadTransactions
    }
  },
  components: { ContentPlaceholder, ErrorMessage, LoaderSection, FooterSection, NavigationSection }
}
</script>
